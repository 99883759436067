import { FIELD_PERMISSION } from 'configs/permissions/fieldPermissions';
import { MODEL_PERMISSION } from 'configs/permissions/modelPermissions';
import { COMMENT_MAX_LIVE_MS } from 'configs/vars';
import { RoutingServiceDashboard } from 'services/routing/RoutingService';
import { CONTRACT_STATUS } from 'typings/models/contract.enum';
import {
  ORDER_OPERATOR_TYPE,
  ORDER_SERVICE_PROVIDER_ASSIGN_ALGORITHM,
  ORDER_SERVICE_PROVIDER_ASSIGN_TYPE,
  ORDER_SERVICE_PROVIDER_TYPE,
  ORDER_STATUS_NAME,
  ORDER_TECHNICIANS_ASSIGN_STATUS,
} from 'typings/models/order/order.enum';
import { TASK_STATE } from 'typings/models/task.enum';
import { TENANT_TYPE } from 'typings/subEntities/tenant.enum';
import OrderUtils from 'utils/models/OrderUtils';
import ServiceOrderedUtils from 'utils/models/ServiceOrderedUtils';
import IPermissionService from './IPermissionService';
import { Invoice } from 'typings/models/invoice';
import { INVOICE_STATUS } from 'typings/models/invoice.enum';
import { USER_POSITION, USER_ROLE } from 'typings/models/user.enum';
import { FUNDS_TRANSACTION_COUNTERPARTY_TYPE, FUNDS_TRANSACTION_GROUP } from 'typings/models/fundsTransaction.enum';

export default class PermissionServiceImpl implements IPermissionService {
  private permissions: Set<IPermissionService.Permission>;
  private currentUser: User | null;
  /** Конфиги пермишенов текущего пользователя */
  private configs: IPermissionService.PermissionConfigs;

  constructor(
    currentUser: User | null,
    permissionsRaw: IPermissionService.Permission[],
    configs: IPermissionService.PermissionConfigs = {}
  ) {
    this.currentUser = currentUser;
    this.configs = configs;

    // // TEMP включает суперадмина;
    // this.permissions = new Set([...Object.values(MODEL_PERMISSION), ...Object.values(FIELD_PERMISSION)]);
    // this.permissions.delete(MODEL_PERMISSION.FAKE_FORBIDDEN);
    // this.permissions.delete(MODEL_PERMISSION.CAN_READ_ENTERPRISE_LIST);
    // //

    // Реальные пермишены с бека
    this.permissions = new Set(permissionsRaw);
    this.permissions.add(MODEL_PERMISSION.FAKE_ALLOWED);
  }

  public can = (permission: IPermissionService.Permission): boolean => {
    const isAble = this.permissions.has(permission);
    console.debug('[Permission check] ' + permission + ': ' + isAble);
    return isAble;
  };

  public orderReviewBusinessPermissionCheck = (
    permission: IPermissionService.Permission,
    _review: OrderReview | null,
    order: Order
  ): boolean => {
    switch (permission) {
      case MODEL_PERMISSION.CAN_UPDATE_ORDER_REVIEW:
      case MODEL_PERMISSION.CAN_CREATE_ORDER_REVIEW: {
        return (
          this.can(permission) &&
          order.active &&
          (order.status.technicalName === ORDER_STATUS_NAME.completed || order.status.technicalName === ORDER_STATUS_NAME.workFinished)
        );
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public fundsTransactionBusinessPermissionCheck = (
    permission: IPermissionService.Permission,
    fundsTransaction: FundsTransaction | null
  ) => {
    switch (permission) {
      case MODEL_PERMISSION.CAN_UPDATE_TRANSACTION_TYPE:
      case MODEL_PERMISSION.CAN_DELETE_TRANSACTION_TYPE: {
        return this.can(permission) && fundsTransaction !== null && fundsTransaction.group !== FUNDS_TRANSACTION_GROUP.system;
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public orderBusinessPermissionCheck = (
    permission: IPermissionService.Permission,
    order: Order | null,
    customField?: Order.CustomField
  ): boolean => {
    switch (permission) {
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_BRAND: {
        const userPosition = this.currentUser?.position.technicalName.toString();
        if (!userPosition) return false;
        return (
          (userPosition === 'DESIGNER' || userPosition === 'ADMIN') &&
          this.can(FIELD_PERMISSION.CAN_UPDATE_ORDER_BRAND) &&
          order !== null &&
          order?.active === true
        );
      }
      case FIELD_PERMISSION.CAN_ARCHIVE_ORDER: {
        switch (order?.status.technicalName) {
          case ORDER_STATUS_NAME.calculation:
          case ORDER_STATUS_NAME.contractNotSigned:
          case ORDER_STATUS_NAME.cancelled:
          case ORDER_STATUS_NAME.completed:
            return this.can(permission) && order.active;
          default:
            return false;
        }
      }
      case FIELD_PERMISSION.CAN_UNARCHIVE_ORDER: {
        return this.can(MODEL_PERMISSION.CAN_ARCHIVE_ORDER) && order?.active === false;
      }
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_SERVICE_PROVIDER: {
        if (!order) return false;
        else return this.can(permission) && OrderUtils.isActive(order) && !OrderUtils.isArchived(order);
      }
      case FIELD_PERMISSION.CAN_SET_ORDER_SERVICE_PROVIDER_ON_JOB: {
        return (
          order?.status.technicalName === ORDER_STATUS_NAME.inProgress &&
          !order.status.isWorkStarted &&
          order.serviceProviderInfo.technicianAssignStatus === ORDER_TECHNICIANS_ASSIGN_STATUS.full
        );
      }
      case FIELD_PERMISSION.CAN_UNSET_ORDER_SERVICE_PROVIDER_ON_JOB: {
        return (
          order?.status.technicalName === ORDER_STATUS_NAME.inProgress &&
          order.status.isWorkStarted &&
          order.serviceProviderInfo.technicianAssignStatus === ORDER_TECHNICIANS_ASSIGN_STATUS.full
        );
      }
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_NUMBER_OF_TECHNICIANS: {
        return (
          order !== null &&
          this.can(permission) &&
          OrderUtils.isActive(order) &&
          order.active &&
          order.serviceProviderInfo.assignType === ORDER_SERVICE_PROVIDER_ASSIGN_TYPE.none &&
          order.serviceProviderInfo.assignAlgorithm === ORDER_SERVICE_PROVIDER_ASSIGN_ALGORITHM.none
        );
      }
      case FIELD_PERMISSION.CAN_READ_ORDER_SERVICE_PROVIDER_REWARD: {
        return this.can(permission) && Boolean(order) && order!.serviceProviderInfo.providerType !== ORDER_SERVICE_PROVIDER_TYPE.none;
      }
      case FIELD_PERMISSION.CAN_READ_ORDER_TECHNICIANS_REWARD_SETTINGS: {
        return (
          this.can(permission) &&
          order !== null &&
          order.technicians.length !== 1 &&
          order.serviceProviderInfo.providerType === ORDER_SERVICE_PROVIDER_TYPE.internalTechnician
        );
      }
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_TECHNICIANS_REWARD_SETTINGS: {
        return (
          this.can(permission) &&
          order !== null &&
          order.active &&
          order.serviceProviderInfo.providerType === ORDER_SERVICE_PROVIDER_TYPE.internalTechnician &&
          order.serviceProviderInfo.technicianAssignStatus === ORDER_TECHNICIANS_ASSIGN_STATUS.full &&
          order.technicians.length > 1
        );
      }
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_CUSTOM_FIELD: {
        return (
          this.can(permission) &&
          customField?.tenant?.id === this.currentUser?.tenant?.id &&
          order !== null &&
          !OrderUtils.isArchived(order)
        );
      }
      case FIELD_PERMISSION.CAN_READ_ORDER_CUSTOM_FIELD:
      case FIELD_PERMISSION.CAN_READ_ORDER_CUSTOM_FIELD_LIST: {
        return (
          this.can(permission) &&
          (this.currentUser?.type === TENANT_TYPE.enterprise || this.currentUser?.type === TENANT_TYPE.platformOperator)
        );
      }
      case FIELD_PERMISSION.CAN_DEDUCT_ORDER_CUSTOMER_BALANCE: {
        const clientBalance = order?.customer?.balance || 0;
        return this.can(permission) && clientBalance > 0;
      }
      case MODEL_PERMISSION.CAN_UPDATE_ORDER:
      case FIELD_PERMISSION.CAN_UPDATE_NOT_YOURS_ORDER_COMMENT:
      case FIELD_PERMISSION.CAN_UPDATE_MY_ORDER_COMMENT:
      case FIELD_PERMISSION.CAN_UPDATE_CREATED_BY_ME_ORDER_TASK:
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_SP_TECHNICIANS:
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_SERVICE_FROM_CATALOG:
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_SERVICE_CUSTOM:
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_SERVICE_DISCOUNT:
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_ENTERPRISE_DEPARTMENT:
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_DELIVERY_INTERVALS:
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_CUSTOMER_PERSONAL_DATA: {
        return this.can(permission) && order !== null && !OrderUtils.isArchived(order);
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public orderProviderTransactionsDataBusinessPermissionCheck = (
    permission: IPermissionService.Permission,
    _orderProviderTransactionsData: OrderProviderTransactionsData | null,
    orderProviderTransaction: OrderProviderTransactionsData.Transaction | null,
    order: Order
  ): boolean => {
    switch (permission) {
      case MODEL_PERMISSION.CAN_CREATE_ORDER_PROVIDER_TRANSACTION: {
        return this.can(permission) && order.active && order.serviceProviderInfo.providerType !== ORDER_SERVICE_PROVIDER_TYPE.none;
      }
      case MODEL_PERMISSION.CAN_DELETE_ORDER_PO_TRANSACTION:
      case MODEL_PERMISSION.CAN_DELETE_ORDER_PROVIDER_TRANSACTION: {
        return this.can(permission) && order.active && orderProviderTransaction !== null && orderProviderTransaction.settings.allowDelete;
      }
      case MODEL_PERMISSION.CAN_UPDATE_ORDER_PO_TRANSACTION:
      case MODEL_PERMISSION.CAN_UPDATE_ORDER_PROVIDER_TRANSACTION: {
        return this.can(permission) && order.active && orderProviderTransaction !== null && orderProviderTransaction.settings.allowEdit;
      }
      case MODEL_PERMISSION.CAN_CREATE_ORDER_PO_TRANSACTION: {
        return (
          this.can(permission) &&
          order.active &&
          order.serviceProviderInfo.providerType !== ORDER_SERVICE_PROVIDER_TYPE.none &&
          order.serviceProviderInfo.operatorType === ORDER_OPERATOR_TYPE.platformOperator
        );
      }

      default: {
        return this.can(permission);
      }
    }
  };

  public userEnterpriseBusinessPermissionCheck = (permission: IPermissionService.Permission, user: User | null) => {
    switch (permission) {
      case FIELD_PERMISSION.CAN_CHANGE_ENTERPRISE_USER_ROLE: {
        // TEMP при создании можно
        if (!user) {
          return this.can(MODEL_PERMISSION.CAN_CREATE_ENTERPRISE_USER);
        } else {
          // Самому себе нельзя
          return this.currentUser?.id !== user?.id && this.can(permission);
        }
      }
      case FIELD_PERMISSION.CAN_CHANGE_OWN_CREDENTIALS: {
        return this.userBusinessPermissionCheck(FIELD_PERMISSION.CAN_CHANGE_OWN_CREDENTIALS, user);
      }
      case FIELD_PERMISSION.CAN_READ_ENTERPRISE_TECHNICIAN_REVIEW_LIST: {
        return user?.id === this.currentUser?.id || this.can(permission);
      }
      case FIELD_PERMISSION.CAN_READ_ENTERPRISE_USER_NOTIFICATION_SETTINGS:
      case FIELD_PERMISSION.CAN_CHANGE_ENTERPRISE_USER_NOTIFICATION_SETTINGS:
      case FIELD_PERMISSION.CAN_DEACTIVATE_ENTERPRISE_TECHNICIAN_USER:
      case FIELD_PERMISSION.CAN_DEACTIVATE_ENTERPRISE_USER:
      case MODEL_PERMISSION.CAN_DELETE_ENTERPRISE_TECHNICIAN:
      case MODEL_PERMISSION.CAN_DELETE_ENTERPRISE_USER: {
        return this.userBusinessPermissionCheck(permission, user);
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public userSPBusinessPermissionCheck = (permission: IPermissionService.Permission, user: User | null) => {
    switch (permission) {
      case FIELD_PERMISSION.CAN_CHANGE_SP_USER_ROLE: {
        // TEMP при создании можно
        if (!user) {
          return this.can(MODEL_PERMISSION.CAN_CREATE_SP_USER);
        } else {
          // Самому себе нельзя
          return this.currentUser?.id !== user?.id && this.can(permission);
        }
      }
      case FIELD_PERMISSION.CAN_CHANGE_OWN_CREDENTIALS: {
        return this.userBusinessPermissionCheck(FIELD_PERMISSION.CAN_CHANGE_OWN_CREDENTIALS, user);
      }
      case FIELD_PERMISSION.CAN_READ_SP_USER_NOTIFICATION_SETTINGS:
      case FIELD_PERMISSION.CAN_CHANGE_SP_USER_NOTIFICATION_SETTINGS:
      case FIELD_PERMISSION.CAN_DEACTIVATE_SP_USER:
      case MODEL_PERMISSION.CAN_DELETE_SP_USER: {
        return this.userBusinessPermissionCheck(permission, user);
      }
      default: {
        return this.can(permission);
      }
    }
  };

  // Общие права для всех юзеров, либо те операции, что для всех совпадают
  public userBusinessPermissionCheck = (permission: IPermissionService.Permission, user: User | null) => {
    switch (permission) {
      // Только на своей странице себе
      case FIELD_PERMISSION.CAN_CHANGE_OWN_CREDENTIALS: {
        if (!user) {
          return false;
        } else {
          const isMePage = RoutingServiceDashboard.me.isMePageSection();
          return isMePage && user.id === this.currentUser?.id;
        }
      }
      case MODEL_PERMISSION.CAN_DELETE_ENTERPRISE_TECHNICIAN:
      case MODEL_PERMISSION.CAN_DELETE_ENTERPRISE_USER:
      case MODEL_PERMISSION.CAN_DELETE_SP_USER: {
        return Boolean(user) && this.can(permission) && !user!.isConfirmed;
      }
      case FIELD_PERMISSION.CAN_DEACTIVATE_ENTERPRISE_TECHNICIAN_USER:
      case FIELD_PERMISSION.CAN_DEACTIVATE_ENTERPRISE_USER:
      case FIELD_PERMISSION.CAN_DEACTIVATE_SP_USER: {
        // Самого себя нельзя
        return this.can(permission) && user?.id !== this.currentUser?.id;
      }
      case FIELD_PERMISSION.CAN_READ_SP_USER_NOTIFICATION_SETTINGS:
      case FIELD_PERMISSION.CAN_READ_ENTERPRISE_USER_NOTIFICATION_SETTINGS:
      case FIELD_PERMISSION.CAN_CHANGE_SP_USER_NOTIFICATION_SETTINGS:
      case FIELD_PERMISSION.CAN_CHANGE_ENTERPRISE_USER_NOTIFICATION_SETTINGS: {
        const isMePage = RoutingServiceDashboard.me.isMePageSection() && user?.id === this.currentUser?.id;
        // Пермишен или сам себе
        return this.can(permission) || isMePage;
      }
      case FIELD_PERMISSION.CAN_UPDATE_OWN_PROFILE: {
        return this.can(permission);
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public orderCommentBusinessPermissionCheck = (permission: IPermissionService.Permission, comment: Commentary | null, order: Order) => {
    switch (permission) {
      case MODEL_PERMISSION.CAN_DELETE_ORDER_REVIEW_COMMENT:
      case MODEL_PERMISSION.CAN_UPDATE_ORDER_REVIEW_COMMENT: {
        // Все могут редактировать только свои в течении COMMENT_MAX_LIVE_MS с момента создания
        if (!comment) {
          return false;
        } else {
          const pastLessThenMaxLiveTime = Date.now() - comment.createdAt.getTime() < COMMENT_MAX_LIVE_MS;
          return this.can(permission) && comment.createdBy?.id === this.currentUser?.id && pastLessThenMaxLiveTime;
        }
      }
      case MODEL_PERMISSION.CAN_DELETE_ORDER_COMMENT: {
        // Админы могут удалять в течении COMMENT_MAX_LIVE_MS с момента создания
        // Все могут редактировать только свои в течении COMMENT_MAX_LIVE_MS с момента создания
        if (!comment) {
          return false;
        } else {
          const canDeleteCreatedByMe =
            comment.createdBy?.id === this.currentUser?.id && this.can(FIELD_PERMISSION.CAN_DELETE_MY_ORDER_COMMENT);
          const pastLessThenMaxLiveTime = Date.now() - comment.createdAt.getTime() < COMMENT_MAX_LIVE_MS;
          const canDeleteNotMine = this.can(FIELD_PERMISSION.CAN_DELETE_NOT_YOURS_ORDER_COMMENT);

          return this.can(permission) && pastLessThenMaxLiveTime && (canDeleteNotMine || canDeleteCreatedByMe);
        }
      }
      case MODEL_PERMISSION.CAN_UPDATE_ORDER_COMMENT: {
        // Админы могут редактировать в течении COMMENT_MAX_LIVE_MS с момента создания
        // Все могут редактировать только свои в течении COMMENT_MAX_LIVE_MS с момента создания
        if (!comment) {
          return false;
        } else {
          const canUpdateCreatedByMe =
            comment.createdBy?.id === this.currentUser?.id && this.can(FIELD_PERMISSION.CAN_UPDATE_MY_ORDER_COMMENT);
          const pastLessThenMaxLiveTime = Date.now() - comment.createdAt.getTime() < COMMENT_MAX_LIVE_MS;
          const canUpdateNotMine = this.can(FIELD_PERMISSION.CAN_UPDATE_NOT_YOURS_ORDER_COMMENT);

          return this.can(permission) && pastLessThenMaxLiveTime && (canUpdateNotMine || canUpdateCreatedByMe);
        }
      }
      case MODEL_PERMISSION.CAN_CREATE_ORDER_REVIEW_COMMENT: {
        if (!order) {
          return false;
        }
        // Отвечать можно только на то ревью, где нет ответов от текущего пользователя
        return (
          this.can(permission) &&
          Boolean(order.review) &&
          Boolean(this.currentUser) &&
          !order.review!.comments.find((c) => c.createdBy?.id === this.currentUser!.id)
        );
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public orderTaskBusinessPermissionCheck = (permission: IPermissionService.Permission, task: Task | null, order: Order) => {
    const isAssignPO = order.serviceProviderInfo.operatorType === ORDER_OPERATOR_TYPE.platformOperator;
    const isCurrentUserOrderOwner = order.owner.id === this.currentUser?.tenant.id;

    switch (permission) {
      case MODEL_PERMISSION.CAN_UPDATE_ORDER_TASK: {
        if (!task || OrderUtils.isArchived(order)) {
          return false;
        }
        let userIsRecipient: boolean = false;
        if (this.can(FIELD_PERMISSION.CAN_UPDATE_ASSIGNED_TO_ME_ORDER_TASK)) {
          let refersToGroupRecipients = false;
          if (this.currentUser?.isManager) {
            task.groupRecipients.forEach((group) => {
              refersToGroupRecipients =
                refersToGroupRecipients || group.roles.some((role) => role === USER_ROLE.orderManager || role === USER_ROLE.orderSpManager);
            });
          }
          if (this.currentUser?.isTechnician) {
            task.groupRecipients.forEach((group) => {
              refersToGroupRecipients = refersToGroupRecipients || group.roles.some((role) => role === USER_ROLE.orderTechnician);
            });
          }

          const userIsSoloRecipient: boolean = task.userRecipients.some((user) => user.id === this.currentUser?.id);
          userIsRecipient = userIsSoloRecipient || refersToGroupRecipients;
        }
        const isTaskNotClosed = task.state !== TASK_STATE.closed;
        const userIsCreatorAndCanUpdate =
          task.createdBy?.id === this.currentUser?.id && this.can(FIELD_PERMISSION.CAN_UPDATE_CREATED_BY_ME_ORDER_TASK);
        const userIsAdmin = this.currentUser?.position.technicalName === USER_POSITION.admin;
        return this.can(permission) && isTaskNotClosed && (userIsRecipient || userIsCreatorAndCanUpdate || userIsAdmin);
      }
      case MODEL_PERMISSION.CAN_DELETE_ORDER_TASK: {
        if (!task || OrderUtils.isArchived(order)) {
          return false;
        } else {
          return task.createdBy?.id === this.currentUser?.id && task.state !== TASK_STATE.closed;
        }
      }
      case FIELD_PERMISSION.CAN_CREATE_ENT_BENEFICIARY_GROUP_TASKS_IF_PO_ASSIGNED: {
        if (isAssignPO) {
          return this.can(permission);
        } else {
          return this.can(MODEL_PERMISSION.CAN_CREATE_ORDER_TASK);
        }
      }
      case FIELD_PERMISSION.CAN_CREATE_PO_BENEFICIARY_GROUP_TASKS_IF_PO_ASSIGNED: {
        return this.can(permission) && isAssignPO;
      }
      case FIELD_PERMISSION.CAN_CREATE_PROVIDER_GROUP_TASKS_IF_PO_ASSIGNED: {
        return this.can(permission) && (!isAssignPO || (isAssignPO && !isCurrentUserOrderOwner));
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public orderReportBusinessPermissionCheck = (permission: IPermissionService.Permission, report: OrderReport | null, order: Order) => {
    switch (permission) {
      case MODEL_PERMISSION.CAN_CREATE_ORDER_REPORT: {
        return (
          order.active &&
          order.status.technicalName !== ORDER_STATUS_NAME.cancelled &&
          order.status.technicalName !== ORDER_STATUS_NAME.completed &&
          this.can(permission)
        );
      }
      case MODEL_PERMISSION.CAN_UPDATE_ORDER_REPORT:
      case MODEL_PERMISSION.CAN_DELETE_ORDER_REPORT: {
        if (!report) return false;
        return (
          order.active &&
          order.status.technicalName !== ORDER_STATUS_NAME.cancelled &&
          order.status.technicalName !== ORDER_STATUS_NAME.completed &&
          this.can(permission)
        );
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public orderDocumentBusinessPermissionCheck = (permission: IPermissionService.Permission, order: Order) => {
    switch (permission) {
      case MODEL_PERMISSION.CAN_CREATE_ORDER_DOCUMENT:
      case MODEL_PERMISSION.CAN_UPDATE_ORDER_DOCUMENT:
      case MODEL_PERMISSION.CAN_DELETE_ORDER_DOCUMENT: {
        return this.can(permission) && order.active;
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public orderViewBusinessPermissionCheck = (permission: IPermissionService.Permission, view: OrderView | null): boolean => {
    switch (permission) {
      case MODEL_PERMISSION.CAN_UPDATE_ORDER_VIEW: {
        if (!view || view.type === 'DEFAULT') return false;
        if (view.isShared) return this.orderViewBusinessPermissionCheck(FIELD_PERMISSION.CAN_UPDATE_SHARED_ORDER_VIEW, view);
        else return this.can(permission);
      }
      case MODEL_PERMISSION.CAN_DELETE_ORDER_VIEW: {
        if (!view || view.type === 'DEFAULT') return false;
        if (view.isShared) return this.orderViewBusinessPermissionCheck(FIELD_PERMISSION.CAN_DELETE_SHARED_ORDER_VIEW, view);
        else return this.can(permission);
      }
      case FIELD_PERMISSION.CAN_UPDATE_SHARED_ORDER_VIEW:
      case FIELD_PERMISSION.CAN_DELETE_SHARED_ORDER_VIEW: {
        // Либо есть права, либо свои
        if (!view || view.type === 'DEFAULT' || !this.currentUser) return false;
        else return this.can(permission) || view.createdById === this.currentUser.id;
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public serviceOrderedBusinessPermissionCheck = (
    permission: IPermissionService.Permission,
    serviceOrdered: ServiceOrdered | null,
    order: Order | null,
    configs: IPermissionService.PermissionConfigs
  ): boolean => {
    switch (permission) {
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_SERVICE_DISCOUNT: {
        const isServiceAcceptable = !serviceOrdered || !serviceOrdered.isPaid;
        const orderStatusIsAcceptable =
          order !== null &&
          order.active &&
          order.status.technicalName !== ORDER_STATUS_NAME.completed &&
          order.status.technicalName !== ORDER_STATUS_NAME.cancelled;
        return isServiceAcceptable && orderStatusIsAcceptable && this.can(permission);
      }
      case MODEL_PERMISSION.CAN_CREATE_ORDER_PROVIDER_TRANSACTION: {
        const isServiceAcceptable =
          order != null &&
          serviceOrdered != null &&
          order.active &&
          order.serviceProviderInfo.providerType !== ORDER_SERVICE_PROVIDER_TYPE.none;
        return isServiceAcceptable && this.can(permission);
      }
      case MODEL_PERMISSION.CAN_CREATE_ORDER_SERVICE: {
        // Нельзя проверять отдельно, нужно явно знать, какую услугу мы хотим добавить
        return (
          this.serviceOrderedBusinessPermissionCheck(FIELD_PERMISSION.CAN_CREATE_ORDER_SERVICE_CUSTOM, serviceOrdered, order, configs) ||
          this.serviceOrderedBusinessPermissionCheck(FIELD_PERMISSION.CAN_CREATE_ORDER_SERVICE_FROM_CATALOG, serviceOrdered, order, configs)
        );
      }
      case FIELD_PERMISSION.CAN_CREATE_ORDER_SERVICE_CUSTOM: {
        const isOrderAcceptable =
          !order ||
          (order.active &&
            order.status.technicalName !== ORDER_STATUS_NAME.completed &&
            order.status.technicalName !== ORDER_STATUS_NAME.cancelled);
        return Boolean(configs.canCreateCustomServices) && this.can(permission) && isOrderAcceptable;
      }
      case FIELD_PERMISSION.CAN_CREATE_ORDER_SERVICE_FROM_CATALOG: {
        const isOrderAcceptable =
          !order ||
          (order.active &&
            order.status.technicalName !== ORDER_STATUS_NAME.completed &&
            order.status.technicalName !== ORDER_STATUS_NAME.cancelled);
        return this.can(permission) && isOrderAcceptable;
      }
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_SERVICE_FROM_CATALOG:
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_SERVICE_CUSTOM:
      case MODEL_PERMISSION.CAN_UPDATE_ORDER_SERVICE: {
        if (!serviceOrdered || !order || !order.active) {
          return false;
        }
        const orderStatusIsAcceptable =
          order.status.technicalName !== ORDER_STATUS_NAME.completed && order.status.technicalName !== ORDER_STATUS_NAME.cancelled;
        const serviceStatusIsAcceptable = ServiceOrderedUtils.canBeDeleted(serviceOrdered, this.currentUser);

        if (serviceOrdered.isCustom) {
          const isServiceAcceptable = Boolean(configs.canCreateCustomServices);
          return (
            isServiceAcceptable &&
            this.can(FIELD_PERMISSION.CAN_UPDATE_ORDER_SERVICE_CUSTOM) &&
            serviceStatusIsAcceptable &&
            orderStatusIsAcceptable
          );
        } else {
          return this.can(FIELD_PERMISSION.CAN_UPDATE_ORDER_SERVICE_FROM_CATALOG) && serviceStatusIsAcceptable && orderStatusIsAcceptable;
        }
      }

      case FIELD_PERMISSION.CAN_DELETE_ORDER_SERVICE_FROM_CATALOG:
      case FIELD_PERMISSION.CAN_DELETE_ORDER_SERVICE_CUSTOM:
      case MODEL_PERMISSION.CAN_DELETE_ORDER_SERVICE: {
        if (!serviceOrdered || !order) {
          return false;
        }
        const orderStatusIsAcceptable =
          order.status.technicalName !== ORDER_STATUS_NAME.completed && order.status.technicalName !== ORDER_STATUS_NAME.cancelled;
        const serviceStatusIsAcceptable = ServiceOrderedUtils.canBeDeleted(serviceOrdered, this.currentUser);

        if (serviceOrdered.isCustom) {
          const isServiceAcceptable = Boolean(configs.canCreateCustomServices);
          return (
            isServiceAcceptable &&
            this.can(FIELD_PERMISSION.CAN_DELETE_ORDER_SERVICE_CUSTOM) &&
            serviceStatusIsAcceptable &&
            orderStatusIsAcceptable
          );
        } else {
          return this.can(FIELD_PERMISSION.CAN_DELETE_ORDER_SERVICE_FROM_CATALOG) && serviceStatusIsAcceptable && orderStatusIsAcceptable;
        }
      }
      case FIELD_PERMISSION.CAN_PAY_ORDER_SERVICE_IN_STORE: {
        if (!serviceOrdered || !order) return false;
        else return OrderUtils.isActive(order) && this.can(permission) && ServiceOrderedUtils.canBePaid(serviceOrdered);
      }
      case FIELD_PERMISSION.CAN_PAY_ORDER_SERVICE_BY_CASH_ON_SITE: {
        if (!serviceOrdered || !order) return false;
        else return OrderUtils.isNotCancelledAndCompleted(order) && this.can(permission) && ServiceOrderedUtils.canBePaid(serviceOrdered);
      }
      case FIELD_PERMISSION.CAN_PAY_ORDER_SERVICE_FROM_BALANCE: {
        if (!serviceOrdered || !order) {
          return false;
        } else {
          return (
            this.can(permission) &&
            ServiceOrderedUtils.canBePaid(serviceOrdered) &&
            order.customer !== undefined &&
            serviceOrdered.price.totalClientValue <= order.customer.balance &&
            order.status.technicalName !== ORDER_STATUS_NAME.completed &&
            order.status.technicalName !== ORDER_STATUS_NAME.cancelled
          );
        }
      }
      case FIELD_PERMISSION.CAN_CANCEL_ORDER_SERVICE_PAYMENT: {
        if (!serviceOrdered) return false;
        else return this.can(permission) && ServiceOrderedUtils.canBeUnPaid(serviceOrdered);
      }

      default: {
        return this.can(permission);
      }
    }
  };

  public serviceOrderedSessionBusinessPermissionCheck = (
    permission: IPermissionService.Permission,
    serviceOrdered: ServiceOrdered | null,
    orderSession: OrderContractSessionData | null,
    configs: IPermissionService.PermissionConfigs
  ): boolean => {
    switch (permission) {
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_SERVICE_DISCOUNT: {
        const isServiceAcceptable = !serviceOrdered || !serviceOrdered.isPaid;
        const orderStatusIsAcceptable =
          orderSession !== null &&
          orderSession.status.technicalName !== ORDER_STATUS_NAME.completed &&
          orderSession.status.technicalName !== ORDER_STATUS_NAME.cancelled;
        return isServiceAcceptable && orderStatusIsAcceptable && this.can(permission);
      }

      case MODEL_PERMISSION.CAN_CREATE_ORDER_SERVICE: {
        // Нельзя проверять отдельно, нужно явно знать, какую услугу мы хотим добавить
        return (
          this.serviceOrderedSessionBusinessPermissionCheck(
            FIELD_PERMISSION.CAN_CREATE_ORDER_SERVICE_CUSTOM,
            serviceOrdered,
            orderSession,
            configs
          ) ||
          this.serviceOrderedSessionBusinessPermissionCheck(
            FIELD_PERMISSION.CAN_CREATE_ORDER_SERVICE_FROM_CATALOG,
            serviceOrdered,
            orderSession,
            configs
          )
        );
      }
      case FIELD_PERMISSION.CAN_CREATE_ORDER_SERVICE_CUSTOM: {
        return Boolean(configs.canCreateCustomServices) && this.can(permission);
      }
      case FIELD_PERMISSION.CAN_CREATE_ORDER_SERVICE_FROM_CATALOG: {
        const isOrderAcceptable =
          !orderSession ||
          (orderSession.status.technicalName !== ORDER_STATUS_NAME.completed &&
            orderSession.status.technicalName !== ORDER_STATUS_NAME.cancelled);
        return this.can(permission) && isOrderAcceptable;
      }
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_SERVICE_FROM_CATALOG:
      case FIELD_PERMISSION.CAN_UPDATE_ORDER_SERVICE_CUSTOM:
      case MODEL_PERMISSION.CAN_UPDATE_ORDER_SERVICE: {
        if (!serviceOrdered || !orderSession) {
          return false;
        }
        const orderStatusIsAcceptable =
          orderSession.status.technicalName !== ORDER_STATUS_NAME.completed &&
          orderSession.status.technicalName !== ORDER_STATUS_NAME.cancelled;
        const serviceStatusIsAcceptable = ServiceOrderedUtils.canBeDeleted(serviceOrdered, this.currentUser);
        if (serviceOrdered.isCustom) {
          const isServiceAcceptable = Boolean(configs.canCreateCustomServices);
          return (
            isServiceAcceptable &&
            this.can(FIELD_PERMISSION.CAN_UPDATE_ORDER_SERVICE_CUSTOM) &&
            serviceStatusIsAcceptable &&
            orderStatusIsAcceptable
          );
        } else {
          return this.can(FIELD_PERMISSION.CAN_UPDATE_ORDER_SERVICE_FROM_CATALOG) && serviceStatusIsAcceptable && orderStatusIsAcceptable;
        }
      }

      case FIELD_PERMISSION.CAN_DELETE_ORDER_SERVICE_FROM_CATALOG:
      case FIELD_PERMISSION.CAN_DELETE_ORDER_SERVICE_CUSTOM:
      case MODEL_PERMISSION.CAN_DELETE_ORDER_SERVICE: {
        if (!serviceOrdered || !orderSession) {
          return false;
        }
        const orderStatusIsAcceptable =
          orderSession.status.technicalName !== ORDER_STATUS_NAME.completed &&
          orderSession.status.technicalName !== ORDER_STATUS_NAME.cancelled;
        const serviceStatusIsAcceptable = ServiceOrderedUtils.canBeDeleted(serviceOrdered, this.currentUser);
        if (serviceOrdered.isCustom) {
          const isServiceAcceptable = Boolean(configs.canCreateCustomServices);
          return (
            isServiceAcceptable &&
            this.can(FIELD_PERMISSION.CAN_DELETE_ORDER_SERVICE_CUSTOM) &&
            serviceStatusIsAcceptable &&
            orderStatusIsAcceptable
          );
        } else {
          return this.can(FIELD_PERMISSION.CAN_DELETE_ORDER_SERVICE_FROM_CATALOG) && serviceStatusIsAcceptable && orderStatusIsAcceptable;
        }
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public contractSPBusinessPermissionCheck = (permission: IPermissionService.Permission, contract: ContractSP | null): boolean => {
    switch (permission) {
      case FIELD_PERMISSION.CAN_ACCEPT_SP_CONTRACT: {
        if (!contract || !this.currentUser) {
          return false;
        }

        let isTenantAcceptable: boolean;
        switch (this.currentUser?.type) {
          case TENANT_TYPE.serviceProvider: {
            isTenantAcceptable = contract.suspendedByServiceProvider;
            break;
          }
          case TENANT_TYPE.platformOperator:
          case TENANT_TYPE.enterprise: {
            isTenantAcceptable = contract.suspendedByTenant;
            break;
          }
          default: {
            isTenantAcceptable = false;
          }
        }

        const isStatusAcceptable =
          (contract.status === CONTRACT_STATUS.new && this.currentUser?.type === TENANT_TYPE.serviceProvider) ||
          (contract.status === CONTRACT_STATUS.suspended && isTenantAcceptable);
        return this.can(permission) && isStatusAcceptable;
      }
      case FIELD_PERMISSION.CAN_SUSPEND_SP_CONTRACT: {
        if (!contract || !this.currentUser || !this.can(permission) || contract.status === CONTRACT_STATUS.new) {
          return false;
        } else if (
          this.currentUser.tenant.type === TENANT_TYPE.enterprise ||
          this.currentUser.tenant.type === TENANT_TYPE.platformOperator
        ) {
          return !contract.suspendedByTenant;
        } else {
          return !contract.suspendedByServiceProvider;
        }
      }
      case FIELD_PERMISSION.CAN_READ_SP_CONTRACT_PROVIDER_DATA: {
        return this.can(permission) && contract !== null && contract.status === CONTRACT_STATUS.active;
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public contractPOBusinessPermissionCheck = (permission: IPermissionService.Permission, contract: ContractPO | null): boolean => {
    switch (permission) {
      case FIELD_PERMISSION.CAN_ACCEPT_PO_CONTRACT: {
        if (!contract || !this.currentUser) {
          return false;
        }

        let isTenantAcceptable: boolean;
        switch (this.currentUser?.type) {
          case TENANT_TYPE.enterprise: {
            isTenantAcceptable = contract.suspendedByEnterprise;
            break;
          }
          case TENANT_TYPE.platformOperator: {
            isTenantAcceptable = contract.suspendedByTenant;
            break;
          }
          default: {
            isTenantAcceptable = false;
          }
        }

        const isStatusAcceptable =
          (contract.status === CONTRACT_STATUS.new && this.currentUser?.type === TENANT_TYPE.enterprise) ||
          (contract.status === CONTRACT_STATUS.suspended && isTenantAcceptable);
        return this.can(permission) && isStatusAcceptable;
      }
      case FIELD_PERMISSION.CAN_SUSPEND_PO_CONTRACT: {
        if (!contract || !this.currentUser || !this.can(permission) || contract.status === CONTRACT_STATUS.new) {
          return false;
        } else if (this.currentUser.tenant.type === TENANT_TYPE.platformOperator) {
          return !contract.suspendedByTenant;
        } else {
          return !contract.suspendedByEnterprise;
        }
      }
      case FIELD_PERMISSION.CAN_READ_PO_CONTRACT_PROVIDER_DATA: {
        return this.can(permission) && contract !== null && contract.status === CONTRACT_STATUS.active;
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public invoiceBusinessPermissionCheck = (permission: IPermissionService.Permission, invoice: Invoice | null): boolean => {
    switch (permission) {
      case MODEL_PERMISSION.CAN_APPROVE_INVOICE: {
        if (!invoice || !this.currentUser) {
          return false;
        }
        const canUpdateProvider =
          this.can(MODEL_PERMISSION.CAN_UPDATE_PROVIDER_INVOICE) &&
          invoice.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider;
        const canUpdatePO =
          this.can(MODEL_PERMISSION.CAN_UPDATE_PO_INVOICE) &&
          invoice.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.platform_operator;
        if (invoice.status === INVOICE_STATUS.draft && (canUpdatePO || canUpdateProvider)) {
          return true;
        }
        return false;
      }
      case MODEL_PERMISSION.CAN_MARK_AS_PAID_INVOICE: {
        if (!invoice || !this.currentUser) {
          return false;
        }
        const canUpdateProvider =
          this.can(MODEL_PERMISSION.CAN_MARK_AS_PAID_PROVIDER_INVOICE) &&
          invoice.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider;
        const canUpdatePO =
          this.can(MODEL_PERMISSION.CAN_MARK_AS_PAID_PO_INVOICE) &&
          invoice.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.platform_operator;
        if (invoice.status === INVOICE_STATUS.paymentPending && (canUpdateProvider || canUpdatePO)) {
          return true;
        }
        return false;
      }

      case MODEL_PERMISSION.CAN_CANCEL_INVOICE: {
        if (!invoice || !this.currentUser) {
          return false;
        }
        const canUpdateProvider =
          this.can(MODEL_PERMISSION.CAN_UPDATE_PROVIDER_INVOICE) &&
          invoice.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.provider;
        const canUpdatePO =
          this.can(MODEL_PERMISSION.CAN_UPDATE_PO_INVOICE) &&
          invoice.counterpartyType === FUNDS_TRANSACTION_COUNTERPARTY_TYPE.platform_operator;

        if (invoice.status === INVOICE_STATUS.paymentPending && (canUpdatePO || canUpdateProvider)) {
          return true;
        }
        return false;
      }
      case MODEL_PERMISSION.CAN_DELETE_PO_INVOICE:
      case MODEL_PERMISSION.CAN_DELETE_PROVIDER_INVOICE: {
        if (!invoice || !this.currentUser) {
          return false;
        }

        if (invoice.status === INVOICE_STATUS.draft && this.can(permission)) {
          return true;
        }
        return false;
      }
      case MODEL_PERMISSION.CAN_UPDATE_PO_INVOICE:
      case MODEL_PERMISSION.CAN_UPDATE_PROVIDER_INVOICE: {
        if (!invoice || !this.currentUser) {
          return false;
        }

        if (
          invoice.status === INVOICE_STATUS.draft &&
          invoice?.additionalTransactions.length === 0 &&
          invoice?.orderTransactions.length === 0 &&
          this.can(permission)
        ) {
          return true;
        }
        return false;
      }

      default: {
        return this.can(permission);
      }
    }
  };

  public regionBusinessPermissionCheck = (permission: IPermissionService.Permission, region: City | null): boolean => {
    switch (permission) {
      case MODEL_PERMISSION.CAN_UPDATE_ENTERPRISE_REGION:
      case MODEL_PERMISSION.CAN_DELETE_ENTERPRISE_REGION: {
        if (!region || !this.currentUser) {
          return false;
        }

        return this.can(permission) && !region.isInherited;
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public shopBusinessPermissionCheck = (permission: IPermissionService.Permission): boolean => {
    return this.can(permission);
  };

  public serviceCategoryGroupBusinessPermissionCheck = (
    permission: IPermissionService.Permission,
    serviceCategoryGroup: ServiceCategoryGroup | null
  ): boolean => {
    switch (permission) {
      case MODEL_PERMISSION.CAN_CREATE_ENTERPRISE_SERVICE_GROUP:
      case MODEL_PERMISSION.CAN_DELETE_ENTERPRISE_SERVICE_GROUP:
      case MODEL_PERMISSION.CAN_UPDATE_ENTERPRISE_SERVICE_GROUP: {
        if (!serviceCategoryGroup || !this.currentUser) {
          return false;
        }

        return this.can(permission) && !serviceCategoryGroup.isInherited;
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public serviceCategoryBusinessPermissionCheck = (
    permission: IPermissionService.Permission,
    serviceCategory: ServiceCategory | null
  ): boolean => {
    switch (permission) {
      case MODEL_PERMISSION.CAN_CREATE_ENTERPRISE_SERVICE_CATEGORY:
      case MODEL_PERMISSION.CAN_DELETE_ENTERPRISE_SERVICE_CATEGORY:
      case MODEL_PERMISSION.CAN_UPDATE_ENTERPRISE_SERVICE_CATEGORY: {
        if (!serviceCategory || !this.currentUser) {
          return false;
        }

        return this.can(permission) && !serviceCategory.isInherited;
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public serviceBusinessPermissionCheck = (permission: IPermissionService.Permission, service: BusinessService | null): boolean => {
    switch (permission) {
      case MODEL_PERMISSION.CAN_CREATE_ENTERPRISE_SERVICE:
      case MODEL_PERMISSION.CAN_DELETE_ENTERPRISE_SERVICE:
      case MODEL_PERMISSION.CAN_UPDATE_ENTERPRISE_SERVICE: {
        if (!service || !this.currentUser) {
          return false;
        }

        return this.can(permission) && !service.isInherited;
      }
      default: {
        return this.can(permission);
      }
    }
  };

  public getCurrentUserConfigs = (): IPermissionService.PermissionConfigs => this.configs;
}

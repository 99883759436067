import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import slices from 'storage/slices';
import {
  AcceptOrderAsProviderDTO,
  AcquiringSettingsResponseDTO,
  CreateOrderRequestDTO,
  DeleteOrderBalanceRequestDTO,
  EditOrderPORequestDTO,
  EditOrderSPRequestDTO,
  EditOrderTechniciansRequestDTO,
  EditOrderTechTeamRequestDTO,
  EditVisitIntervalsDTO,
  GetAvailableProvidersForAssignRequestDTO,
  GetAvailableProvidersForAssignResponseDTO,
  GetAvailableProvidersForAssignResponseShortDTO,
  GetOrderListRequestDTO,
  OrderResponseDTO,
  OrderRewardSettingsDTO,
  RejectOrderAsProviderDTO,
  StartDistributionRequestDTO,
  UpdateOrderContractDTO,
  UpdateOrderCustomField,
} from 'typings/dto/order';
import { batch as reduxBatch } from 'react-redux';
import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import IServerEventsService from 'services/serverEvents/IServerEventsService';
import DateUtils from 'utils/Date';
import IOrderActionsService from './IOrderActionsService';
import NavigateFrontendUtils from 'utils/NavigateFrontend';
import ModelUtils from 'utils/models/ModelUtils';
import DIContainer from 'services/DIContainer';
import ISliceActions from 'storage/slices/ISliceActions';
import IMapper from 'utils/mappers/IMapper';
import IEntityApiService from 'services/entityApi/IEntityApiService';
import IModelApiService from 'services/entityApi/IModelApiService';
import { AppDispatch, RootState } from 'storage';
import OrderViewUtils from 'utils/models/OrderViewUtils';
import OrderMapper from 'utils/mappers/Order';
import { BACKEND_ROOT } from 'configs/apis';
import { RoutingServiceDashboard } from 'services/routing/RoutingService';
import { DEFAULT_ORDER_VIEW_NAME } from 'typings/models/order/orderView.enum';
import NavigateBackendUtils from 'utils/NavigateBackend';

export default class OrderActionsServiceImpl
  extends ModelActionsService<Order, CreateOrderRequestDTO, OrderResponseDTO>
  implements IOrderActionsService {
  private statefulUtils: DIContainer.StatefulUtils;

  constructor(
    modelStorageData: { name: DIContainer.ModelsWithActionServices; actions: ISliceActions<Order> },
    modelMapper: IMapper<Order, OrderResponseDTO>,
    entityApiService: IEntityApiService,
    modelApiService: IModelApiService,
    subEntitiesApiServices: DIContainer.SubEntitiesApiServices,
    modelApiRootPath: string,
    serverEventsService: IServerEventsService,
    storageStateGetter: () => RootState,
    storageDispatch: AppDispatch,
    statefulUtils: DIContainer.StatefulUtils
  ) {
    super(
      modelStorageData,
      modelMapper,
      entityApiService,
      modelApiService,
      subEntitiesApiServices,
      modelApiRootPath,
      serverEventsService,
      storageStateGetter,
      storageDispatch
    );

    this.statefulUtils = statefulUtils;
  }

  public archiveOrder = async (orderId: string) => {
    const path = PATH_BACKEND.order.root + '/' + orderId + '/' + PATH_BACKEND_PART.order.archive
    const response = await this.entityApiService.putWithCredentials(path, null)
    return response
  }

  public unarchiveOrder = async (orderId: string) => {
    const path = PATH_BACKEND.order.root + '/' + orderId + '/' + PATH_BACKEND_PART.order.unarchive
    const response = await this.entityApiService.putWithCredentials(path, null)
    return response
  }

  public getAllArchiveYearForFilter = async () => {
    const path = PATH_BACKEND.filter.root + '/' + PATH_BACKEND_PART.filter.order + '/' + PATH_BACKEND_PART.filter.archiveYears
    return this.entityApiService.getWithCredentials<string[]>(path)
      .then(res => res.sort((a, b) => Number(b) - Number(a)))
  }

  /** @throws `BackendResponseError` */
  public follow = async (orderId: string, userId: string, subscribe: boolean): Promise<boolean> => {
    const path = PATH_BACKEND.order.root + '/' + orderId + '/' + PATH_BACKEND_PART.order.follow + '?userId=' + userId;
    await (subscribe
      ? this.entityApiService.postWithCredentials<void>(path, undefined, false)
      : this.entityApiService.deleteWithCredentials(path));
    return subscribe;
  };

  /** @throws `BackendResponseError` */
  public followAll = async (areAllOrdersFollowed: boolean): Promise<void> => {
    const path = PATH_BACKEND.order.root + '/' + PATH_BACKEND_PART.order.followAll;
    await (areAllOrdersFollowed
      ? this.entityApiService.deleteWithCredentials(path)
      : this.entityApiService.postWithCredentials<void>(path, undefined, false));
  };

  public getListAndSetFirst = async (dto: GetOrderListRequestDTO, filter: LocationSearchObject, callback?: (order: Order) => void) => {
    this.storageDispatch(this.modelStorageActions.startAllLoading());
    await reduxBatch(async () => {
      await this.getListBackground(dto, filter);
      if (this.areActionsOutdated) {
        return;
      }
      this.storageDispatch(this.modelStorageActions.stopAllLoading());
      const { orderList, error } = this.getStorageCurrentState();
      // getList выше мог вернуть ошибку (TODO а если она была тут до этого?)
      if (error) {
        return;
      }
      const firstOrder = orderList[0];
      if (firstOrder) {
        this.setOne(firstOrder); // TODO Тут ставится заказ из списка, т.е. не с полной инфой. Пока её достаточно на отображение страницы info (остальные всё равно по клику догрузятся), но нужно иметь в виду
        if (callback) callback(firstOrder);
      }
    });
  };

  public subscribeOnUpdates = (onErrorCallback: (error: any) => void): IServerEventsService.SubscriptionState => {
    const onMessageCallback = (data: IServerEventsService.SSEEntityUpdateMessageData) => {
      const { listOfEntities, oneEntity } = this.getStorageCurrentState();
      const { currentUser } = this.getStorageState().auth;
      const existedEntityFromList = listOfEntities.find((e) => e.id === data.id);
      const updatedEntityDate = new Date(data.updatedAt);
      if (!DateUtils.isValidDate(updatedEntityDate)) {
        return onErrorCallback(new Error("Can't parse event date"));
      }
      const isCreatedOrDeleted =
        data.type === IServerEventsService.SSE_ENTITY_UPDATE_TYPE.CREATED ||
        data.type === IServerEventsService.SSE_ENTITY_UPDATE_TYPE.DELETED;
      // Обновляем список, если сущность добавилась/удалилась из списка, либо если такая сущность есть в нашем списке и она более старая
      // TODO Плохой алгоритм - если в пришедшем заказе что-то поменяли и он начинает попадать под критерии текущей вкладки, то она не обновится
      if (isCreatedOrDeleted || (existedEntityFromList && existedEntityFromList.updatedAt.getTime() < updatedEntityDate.getTime())) {
        const filter = NavigateFrontendUtils.getLocationSearchObjectFromCurrentLocation();
        this.getListBackground({ tenantId: currentUser?.tenant.id || '' }, filter);
      }
      // Обновляем одну сущность, если мы на странице данной сущности и у нас более старая
      if (oneEntity && oneEntity.id === data.id && oneEntity.updatedAt.getTime() < updatedEntityDate.getTime()) {
        this.getByIdBackground(data.id);
      }
    };
    return this.serverEventsService.createSubscription(PATH_BACKEND.order.subscribeOnUpdates, onMessageCallback, onErrorCallback);
  };

  // Метод подписки на обновление конкретного заказа. Используется с локальным стейтом
  public subscribeOnUpdatesSpecificOrder = (
    onErrorCallback: (error: any) => void,
    orderStorageActions: IOrderActionsService,
    order: Order | null,
    isMountedRef: React.MutableRefObject<boolean>,
    setOrder: (value: React.SetStateAction<Order | null>) => void,
    setError: (value: React.SetStateAction<Error | null>) => void,
    setIsLoading: (value: React.SetStateAction<boolean>) => void
  ): IServerEventsService.SubscriptionState => {
    const onMessageCallback = (data: IServerEventsService.SSEEntityUpdateMessageData) => {
      const updatedEntityDate = new Date(data.updatedAt);

      if (!DateUtils.isValidDate(updatedEntityDate)) {
        return onErrorCallback(new Error("Can't parse event date"));
      }

      if (order && order.id === data.id && order.updatedAt.getTime() < updatedEntityDate.getTime()) {
        orderStorageActions
          .requestById(order.id)
          .then((data: Order) => isMountedRef.current && setOrder(data))
          .catch((error: any) => isMountedRef.current && setError(error))
          .finally(() => isMountedRef.current && setIsLoading(false));
      }
    };
    return this.serverEventsService.createSubscription(PATH_BACKEND.order.subscribeOnUpdates, onMessageCallback, onErrorCallback);
  };

  // Override -----------------------------------------------------------------------------

  // Переопределено, т.к. внутри надо использовать флаги загрузки от all
  public getList = async (dto: GetOrderListRequestDTO, filter?: LocationSearchObject) => {
    this.storageDispatch(this.modelStorageActions.startAllLoading());
    await reduxBatch(async () => {
      await this.getListBackground(dto, filter);
      this.storageDispatch(this.modelStorageActions.stopAllLoading());
    });
  };

  public getListBackground(dto: GetOrderListRequestDTO, filter?: LocationSearchObject, customPath?: string): Promise<void> {
    // Предложенные заказы можно получить только если передать спец флаг; получать их надо на определённой известной вкладке;
    // сейчас добавил логику выставления флага сюда (не очень правильно), но можно обязать в dto всегда передавать флаг (лучше), тогда отсюда можно будет удалить;
    // на уровне фильтра не сделать, он не имеет доступа за пределы параметра filter
    if (window.location.pathname.startsWith(RoutingServiceDashboard.order.root + '/' + DEFAULT_ORDER_VIEW_NAME.proposedOrders)) {
      customPath = NavigateBackendUtils.addParamsToExistedUrl(customPath || this.getModelApiRootPath(dto), {
        proposed: 'true',
      });
    }
    return super.getListBackground(dto, filter, customPath);
  }

  // Переопределено, т.к. внутри надо использовать this.setOne
  public getByIdBackground = async (id: string, customPath?: string): Promise<void> => {
    try {
      const response = await this.modelApiService.getById(customPath || this.getModelApiRootPath(null), id, this.modelMapper);
      if (!this.areActionsOutdated) {
        this.setOne(response);
      } else {
        console.warn(`Get by id request ${this.modelStorageName} is outdated`);
      }
    } catch (error) {
      if (!this.areActionsOutdated) {
        console.error(error);
        this.storageDispatch(this.modelStorageActions.setError(error));
      }
    }
  };

  // TODO тут по идее ошибка - при обновлении сущности заказа он может исчезнуть из текущего view, соответственно это view надо грузить с сервера, а не обновлять локальный
  public getByIdBackgroundAndUpdateList = async (id: string, customPath?: string): Promise<void> => {
    try {
      const response = await this.modelApiService.getById(customPath || this.getModelApiRootPath(null), id, this.modelMapper);
      if (!this.areActionsOutdated) {
        this.setOne(response);
        const { listOfEntities, pagination, lastListRequestFilter } = this.getStorageCurrentState();
        const index = listOfEntities.findIndex((i) => i.id === response.id);
        if (index > -1) {
          const listCopy = listOfEntities.slice(0);
          listCopy[index] = response;
          // Надо также передать текущую пагинацию и текущий фильтр, чтобы не сломать логику подгрузки списка
          this.setList(listCopy, pagination, { lastListRequestFilter });
        }
      } else {
        console.warn(`Get by id request ${this.modelStorageName} is outdated`);
      }
    } catch (error) {
      if (!this.areActionsOutdated) {
        console.error(error);
        this.storageDispatch(this.modelStorageActions.setError(error));
      }
    }
  };

  // Помимо самого заказа мы получаем много других сущностей, их надо руками распределить
  // Простой getById внутри переиспользует этот метод
  // TODO Если где-то подобное повторится, то продумать передачу slices через DI
  public setOne = (order: Order | null) => {
    this.storageDispatch(this.modelStorageActions.setOne(order));
    if (order) {
      this.storageDispatch(slices.orderHistory.actions.setList({ data: order.history }));
      this.storageDispatch(slices.orderReview.actions.setOne(order.review || null));
      // prettier-ignore
      this.storageDispatch(slices.orderReviewComment.actions.setList({ data: order.review?.comments.slice(0).sort(ModelUtils.sortByDateDescendingComparator) || [] }));
      this.storageDispatch(slices.orderReport.actions.setList({ data: order.reports }));
      this.storageDispatch(slices.orderDocument.actions.setList({ data: order.documents }));
      this.storageDispatch(slices.task.actions.setList({ data: order.tasks }));
      // prettier-ignore
      this.storageDispatch(slices.orderComment.actions.setList({ data: order.comments.slice(0).sort(ModelUtils.sortByDateDescendingComparator) }));
      this.storageDispatch(slices.serviceOrdered.actions.setList({ data: order.services }));
      this.storageDispatch(slices.orderProviderTransactionsData.actions.setList({ data: order.providerTransactions }));
    } else {
      this.storageDispatch(slices.orderHistory.actions.setList({ data: [] }));
      this.storageDispatch(slices.orderReview.actions.setOne(null));
      this.storageDispatch(slices.orderReviewComment.actions.setList({ data: [] }));
      this.storageDispatch(slices.orderReport.actions.setList({ data: [] }));
      this.storageDispatch(slices.orderDocument.actions.setList({ data: [] }));
      this.storageDispatch(slices.task.actions.setList({ data: [] }));
      this.storageDispatch(slices.orderComment.actions.setList({ data: [] }));
      this.storageDispatch(slices.serviceOrdered.actions.setList({ data: [] }));
      this.storageDispatch(slices.orderProviderTransactionsData.actions.setList({ data: [] }));
    }
  };

  public getAcquiringSettings = (orderId: string): Promise<AcquiringSettingsResponseDTO> => {
    return this.entityApiService
      .get<AcquiringSettingsResponseDTO>(PATH_BACKEND.client.order + '/' + orderId + '/' + PATH_BACKEND_PART.client.acquiringSettings)
      .then((data) => {
        if (data.typeSettings.html) {
          data.typeSettings.html = decodeURIComponent(data.typeSettings.html);
        }
        return data;
      });
  };

  public getDefaultSortFilter = <T extends LocationSearchObject>(filterRaw: T = {} as T): T => {
    // У заказа есть спецметки в фильтрах которых нет на беке и перед отправкой их надо обработать, а т.к. этот метод дёргается везде, то удобно это сделать тут;
    // пока это единичный случай спецметок, иначе придётся как-то систематизировать
    const { currentUser } = this.getStorageState().auth;
    const { customFieldAll: customFields } = this.getStorageState().customField;
    OrderViewUtils.mapFilterSpecialTokens(filterRaw, this.statefulUtils, currentUser, customFields);
    return super.getDefaultSortFilter(filterRaw, 'createdAt', NavigateFrontendUtils.SORT_ORDER.desc);
  };

  public editVisitIntervals = ({ orderId, visitDate }: EditVisitIntervalsDTO) => {
    const url = `${PATH_BACKEND.order.root}/${orderId}/${PATH_BACKEND_PART.order.visitIntervals} `;
    return this.entityApiService.putWithCredentials(url, { visitDate }).finally(() => {
      this.setListOutdated();
    });
  };

  // Provider management -----------------------------------------------------------------------------------------------------------------

  /** @throws `BackendResponseError` */
  public editTechnicians = ({ orderId, scenario, algorithm, technicianIds }: EditOrderTechniciansRequestDTO): Promise<void> => {
    switch (scenario) {
      case 'remove': {
        if (algorithm === 'manual') {
          const url = `${PATH_BACKEND.order.root}/${orderId}/${PATH_BACKEND_PART.order.assign}/${PATH_BACKEND_PART.user.technician}`;
          return this.entityApiService.deleteWithCredentials(url);
        } else {
          const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.order.distribution}/order/${orderId}/${PATH_BACKEND_PART.order.offer}/cancel`;
          return this.entityApiService.postWithCredentials(url, null, false);
        }
      }
      case 'edit': {
        const url = `${PATH_BACKEND.order.root}/${orderId}/${PATH_BACKEND_PART.order.assign}/${PATH_BACKEND_PART.user.technician}`;
        return this.entityApiService.putWithCredentials(url, { technicianIds });
      }
      case 'add': {
        if (algorithm === 'manual') {
          const url = `${PATH_BACKEND.order.root}/${orderId}/${PATH_BACKEND_PART.order.assign}/${PATH_BACKEND_PART.user.technician}`;
          return this.entityApiService.postWithCredentials<void>(url, { technicianIds }, false);
        }

        const dto: StartDistributionRequestDTO = {
          teamIds: [],
          tenantIds: [],
          userIds: technicianIds,
        };
        const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.order.distribution}/order/${orderId}/${PATH_BACKEND_PART.order.offer}/start`;
        return this.entityApiService.postWithCredentials(url, dto, false);
      }
    }
  };

  /** @throws `BackendResponseError` */
  public editSP = ({ orderId, spIds, scenario, algorithm }: EditOrderSPRequestDTO): Promise<void> => {
    switch (scenario) {
      case 'remove': {
        if (algorithm === 'manual') {
          const url = `${PATH_BACKEND.order.root}/${orderId}/${PATH_BACKEND_PART.order.assign}/${PATH_BACKEND_PART.user.technician}`;
          return this.entityApiService.deleteWithCredentials(url);
        } else {
          const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.order.distribution}/order/${orderId}/${PATH_BACKEND_PART.order.offer}/cancel`;
          return this.entityApiService.postWithCredentials(url, null, false);
        }
      }
      case 'add':
      case 'edit': {
        if (algorithm === 'manual') {
          const url = `${PATH_BACKEND.order.root}/${orderId}/${PATH_BACKEND_PART.order.assign}/sp/${spIds[0]}`;
          return this.entityApiService.postWithCredentials(url, null, false);
        }

        const dto: StartDistributionRequestDTO = {
          teamIds: [],
          tenantIds: spIds,
          userIds: [],
        };
        const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.order.distribution}/order/${orderId}/${PATH_BACKEND_PART.order.offer}/start`;
        return this.entityApiService.postWithCredentials(url, dto, false);
      }
    }
  };

  /** @throws `BackendResponseError` */
  public editPO = ({ orderId, poIds, scenario }: EditOrderPORequestDTO): Promise<void> => {
    switch (scenario) {
      case 'remove': {
        const url = `${PATH_BACKEND.order.root}/${orderId}/${PATH_BACKEND_PART.order.assign}/${PATH_BACKEND_PART.user.technician}`;
        return this.entityApiService.deleteWithCredentials(url);
      }
      case 'add':
      case 'edit': {
        const url = `${PATH_BACKEND.order.root}/${orderId}/${PATH_BACKEND_PART.order.assign}/po/${poIds[0]}`;
        return this.entityApiService.postWithCredentials(url, null, false);
      }
    }
  };

  /** @throws `BackendResponseError` */
  public editTechTeam = ({ algorithm, orderId, scenario, teamsIds }: EditOrderTechTeamRequestDTO): Promise<void> => {
    switch (scenario) {
      case 'remove': {
        if (algorithm === 'manual') {
          // TODO
          const url = `${PATH_BACKEND.order.root}/${orderId}/${PATH_BACKEND_PART.order.assign}/${PATH_BACKEND_PART.user.technician}`;
          return this.entityApiService.deleteWithCredentials(url);
        } else {
          const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.order.distribution}/order/${orderId}/${PATH_BACKEND_PART.order.offer}/cancel`;
          return this.entityApiService.postWithCredentials(url, null, false);
        }
      }
      case 'edit': {
        // TODO
        const url = `${PATH_BACKEND.order.root}/${orderId}/${PATH_BACKEND_PART.order.assign}/technician-team`;
        return this.entityApiService.postWithCredentials<void>(url, { teamId: teamsIds[0] }, false);
      }
      case 'add': {
        if (algorithm === 'manual') {
          // TODO
          const url = `${PATH_BACKEND.order.root}/${orderId}/${PATH_BACKEND_PART.order.assign}/technician-team`;
          return this.entityApiService.postWithCredentials<void>(url, { teamId: teamsIds[0] }, false);
        }
        const dto: StartDistributionRequestDTO = {
          teamIds: teamsIds,
          tenantIds: [],
          userIds: [],
        };
        const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.order.distribution}/order/${orderId}/${PATH_BACKEND_PART.order.offer}/start`;
        return this.entityApiService.postWithCredentials(url, dto, false);
      }
    }
  };

  /** @throws `BackendResponseError` */
  public updateProviderRewardSettings = (orderId: string, dto: OrderRewardSettingsDTO) => {
    const url = `${this.getModelApiRootPath(null)}/${orderId}/technicians-reward-settings`;
    return this.entityApiService.putWithCredentials(url, dto);
  };

  /** @throws `BackendResponseError` */
  public updateCustomField = ({ orderId, fieldId, value }: UpdateOrderCustomField): Promise<void> => {
    const url = `${this.getModelApiRootPath(null)}/${orderId}/custom-fields/${fieldId}`;
    return this.entityApiService.putWithCredentials(url, { value });
  };

  /** @throws `BackendResponseError` */
  public updateContract = (orderId: string, dto: UpdateOrderContractDTO): Promise<void> => {
    const url = `${this.getModelApiRootPath(null)}/${orderId}/contract`;
    return this.entityApiService.putWithCredentials(url, dto);
  };

  /** @throws `BackendResponseError` */
  public rejectOrderAsProvider = ({ orderId }: RejectOrderAsProviderDTO): Promise<void> => {
    const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.order.distribution}/order/${orderId}/${PATH_BACKEND_PART.order.offer}/decline`;
    return this.entityApiService.postWithCredentials<void>(url, null, false);
  };

  /** @throws `BackendResponseError` */
  public acceptOrderAsProvider = ({ orderId, ...dto }: AcceptOrderAsProviderDTO): Promise<void> => {
    const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.order.distribution}/order/${orderId}/${PATH_BACKEND_PART.order.offer}/accept`;
    return this.entityApiService.postWithCredentials<void>(url, dto, false);
  };

  /** @throws `BackendResponseError` */
  public setProviderAssignTypeToAuto = (orderId: string): Promise<void> => {
    const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.order.distribution}/order/${orderId}/${PATH_BACKEND_PART.order.assign}/auto`;
    return this.entityApiService.postWithCredentials<void>(url, undefined, false);
  };

  /** @throws `BackendResponseError` */
  public getAvailableProvidersForAssign = ({ orderId, ...dto }: GetAvailableProvidersForAssignRequestDTO) => {
    const url = `${PATH_BACKEND.order.root}/${orderId}/${PATH_BACKEND_PART.order.assign}/provider/available`;
    return this.entityApiService
      .postWithCredentials<GetAvailableProvidersForAssignResponseShortDTO>(url, dto, true)
      .then((this.modelMapper as OrderMapper).getAvailableProvidersForAssignResponseToShortModel);
  };

  /** @throws `BackendResponseError` */
  public getOfferedProvidersForAssign = (orderId: string) => {
    const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.order.distribution}/order/${orderId}/offers`;
    return this.entityApiService
      .getWithCredentials<GetAvailableProvidersForAssignResponseDTO>(url)
      .then((this.modelMapper as OrderMapper).getAvailableProvidersForAssignResponseToModel);
  };

  /** @throws `BackendResponseError` */
  public downloadOrdersCSV = (tenantId: string, fileName: string, currentUser: User): Promise<void> => {
    const { customFieldList } = this.getStorageState().customField;

    const path = PATH_BACKEND.order.root + '/export/csv';
    const filter = NavigateFrontendUtils.getLocationSearchObjectFromCurrentLocation();

    OrderViewUtils.mapFilterSpecialTokens(filter, this.statefulUtils, currentUser, customFieldList);

    const backendFilter = NavigateBackendUtils.locationSearchObjectToRequestFilter(filter);
    let backendFilterStr = NavigateBackendUtils.createDBRequestStrWithFilter('', backendFilter);
    backendFilterStr = decodeURIComponent(backendFilterStr).replace('?filter=', '');

    return this.entityApiService.postWithCredentials<void>(
      path,
      {
        filter: backendFilterStr,
        tenantId,
        fileName,
      },
      false
    );
  };

  /** @throws `BackendResponseError` */
  public deleteOrderBalance = ({ orderId, ...dto }: DeleteOrderBalanceRequestDTO): Promise<void> => {
    const path = `${PATH_BACKEND.order.root}/${orderId}/${PATH_BACKEND_PART.order.balance}`;
    return this.entityApiService.delete(path, {
      body: JSON.stringify(dto),
      credentials: 'include',
    });
  };

  public getAvailableTeamResources = async (orderId: string, requiredHours?: number | string, debug?: boolean) => {
    let path = `${PATH_BACKEND.root}/${PATH_BACKEND_PART.user.root}/${PATH_BACKEND_PART.user.order}/${orderId}/${PATH_BACKEND_PART.availableTeamResources.root}`
    if (requiredHours) {
      path = NavigateBackendUtils.addParamsToExistedUrl(path, { requiredHours })
    }
    if (debug !== undefined) {
      path = NavigateBackendUtils.addParamsToExistedUrl(path, { debug })
    }
    return this.entityApiService.getWithCredentials<AvailableTeamResources>(path)
  }

  // На адресе
  public setTechnicianOnTheJob = async (orderId: string) => {
    let path = `${PATH_BACKEND.order.root}/${orderId}/${PATH_BACKEND_PART.order.setTechnicianOnTheJob}`
    return this.entityApiService.putWithCredentials(path, null)
  }
  // Ушел 
  public unsetTechnicianOnTheJob = async (orderId: string, intervalId: string) => {
    let path = `${PATH_BACKEND.order.root}/${orderId}/${PATH_BACKEND_PART.order.unsetTechnicianOnTheJob}`
    const finalPath = NavigateBackendUtils.addParamsToExistedUrl(path, { intervalId })
    return this.entityApiService.putWithCredentials(finalPath, null)
  }

}

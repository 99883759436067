import { Button, Stack, TextField, Typography } from '@mui/material';
import CopyToClipboardButton from 'components/ui/buttons/CopyToClipboardButton';
import PopupAction from 'components/ui/popups/PopupAction';
import PopupContent from 'components/ui/popups/PopupContent';
import useDI from 'hooks/useDI';
import useLocales from 'hooks/useLocales';
import { useState } from 'react';
import { useSelector } from 'storage';

type Props = {
  apiUserId: string;
};

export default function ResetSuperAdminApiKeyButton({ apiUserId }: Props) {
  const { currentUser } = useSelector((state) => state.auth);
  const { storageActions } = useDI();
  const { translate } = useLocales();
  const [isResetPopupOpened, setIsResetPopupOpened] = useState(false);
  const [isResponsePopupOpened, setIsResponsePopupOpened] = useState(false);
  const [newApiKey, setNewApiKey] = useState('');

  const resetHandler = async () => {
    const code = await storageActions.models.userSuperadmin.resetApiUserKey({ tenantId: currentUser?.tenant.id || '', userId: apiUserId });
    setNewApiKey(code);
    setIsResetPopupOpened(false);
    setIsResponsePopupOpened(true);
  };

  return (
    <>
      <Button sx={{ p: 0 }} onClick={() => setIsResetPopupOpened(true)}>
        {translate('pages.enterpriseApiSettingsEdit.resetApiKeyAndGetNewButton')}
      </Button>
      <PopupAction
        isOpened={isResetPopupOpened}
        closeHandler={() => setIsResetPopupOpened(false)}
        actionHandler={resetHandler}
        title={translate('pages.enterpriseApiSettingsEdit.resetApiKeyAndGetNewTitle')}
      >
        {translate('pages.enterpriseApiSettingsEdit.resetApiKeyAndGetNewTip')}
      </PopupAction>
      <PopupContent
        isOpened={isResponsePopupOpened}
        closeHandler={() => setIsResponsePopupOpened(false)}
        title={translate('pages.enterpriseApiSettingsEdit.resetApiKeyAndGetNewTitle')}
      >
        <Typography variant="body1" color="text.secondary">
          {translate('pages.enterpriseApiSettingsEdit.resetApiKeyAndGetNewResponseTip')}
        </Typography>
        <Stack direction="row" spacing={1}>
          <TextField value={newApiKey} sx={{ width: '100%' }} />
          <CopyToClipboardButton copyText={newApiKey} variant="outlined" color="inherit" sx={{ px: 3 }}>
            {translate('buttons.copy')}
          </CopyToClipboardButton>
        </Stack>
        <Stack alignItems="flex-start">
          <Button variant="contained" sx={{ mt: 3 }} onClick={() => setIsResponsePopupOpened(false)}>
            {translate('buttons.confirm')}
          </Button>
        </Stack>
      </PopupContent>
    </>
  );
}

import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import IAuthApiService from 'services/auth/IAuthApiService';
import DIContainer from 'services/DIContainer';
import IEntityApiService from 'services/entityApi/IEntityApiService';
import IPermissionService from 'services/permission/IPermissionService';
import {
  ChangeCredentialsRequestDTO,
  ChangePasswordRequestDTO,
  GetVerifyCredentialsModeResponseDTO,
  ResendInviteRequestDTO,
  ResetPasswordRequestDTO,
  SendOtpRequestDTO,
  SigninRequestDTO,
  UpdateTokensResponseDTO,
  VerifyCredentialsRequestDTO,
} from 'typings/dto/auth';
import { UserInitialDataResponseDTO } from 'typings/dto/user';
import { UserSPResponseDTO } from 'typings/dto/userSP';
import UserUtils from 'utils/models/UserUtils';
import IAuthTokensService from './IAuthTokensService';

export default class AuthApiServiceImpl implements IAuthApiService {
  constructor(
    private readonly entityApiService: IEntityApiService,
    private readonly authTokensService: IAuthTokensService,
    private readonly modelMappers: DIContainer.Mappers['models']
  ) {}

  /** @throws `BackendResponseError` */
  public signin = async (dto: SigninRequestDTO): Promise<UpdateTokensResponseDTO> => {
    const response = await this.entityApiService.post<UpdateTokensResponseDTO>(PATH_BACKEND.auth.signin, dto, true, {
      credentials: 'include',
    });
    this.authTokensService.setAccessToken(response);
    return response;
  };

  /** @throws `BackendResponseError` */
  public loginFromUser = async (userId: string): Promise<void> => {
    const response = await this.entityApiService.postWithCredentials<UpdateTokensResponseDTO>(
      PATH_BACKEND.root + '/auth/update-current-session',
      userId
    );
    this.authTokensService.setAccessToken(response);
  };

  /** @throws `BackendResponseError` */
  public signout = async () => {
    const response = await this.entityApiService.postWithCredentials<void>(PATH_BACKEND.auth.signout);
    this.authTokensService.setAccessToken(null);
    return response;
  };

  /** @throws `BackendResponseError` */
  public getVerifyCredentialsPageMode = (hashCode: string) =>
    this.entityApiService.get<GetVerifyCredentialsModeResponseDTO | undefined>(`${PATH_BACKEND.auth.verifyCredentials}/${hashCode}`);

  /** @throws `BackendResponseError` */
  public resetPassword = (dto: ResetPasswordRequestDTO): Promise<void> =>
    this.entityApiService.post(PATH_BACKEND.auth.resetPassword, dto, false);

  /** @throws `BackendResponseError` */
  public verifyCredentials = (dto: VerifyCredentialsRequestDTO) => {
    return this.entityApiService.post<boolean>(PATH_BACKEND.auth.verifyCredentials, dto);
  };

  /** @throws `BackendResponseError` */
  public changeCredentials = ({ id, ...dto }: ChangeCredentialsRequestDTO) => {
    return this.entityApiService.postWithCredentials<void>(PATH_BACKEND.auth.changeCredentials, dto, false);
  };

  /** @throws `BackendResponseError` */
  public changePassword = (dto: ChangePasswordRequestDTO): Promise<void> => {
    return this.entityApiService.postWithCredentials(PATH_BACKEND.auth.changePassword, dto, false);
  };

  /** @throws `BackendResponseError` */
  public sendOtpCode = (dto: SendOtpRequestDTO): Promise<void> => {
    return this.entityApiService.post(PATH_BACKEND.otp.root, dto, false);
  };

  /** @throws `BackendResponseError` */
  public resendInvite = (dto: ResendInviteRequestDTO): Promise<void> => {
    return this.entityApiService.post(PATH_BACKEND.auth.resendInvite, dto, false);
  };

  /** @throws `BackendResponseError | Error` */
  public getCurrentUser = async (): Promise<{ user: User | null; permissions: IPermissionService.Permission[] }> => {
    const userData = await this.entityApiService.getWithCredentials<UserInitialDataResponseDTO | undefined>(
      PATH_BACKEND.auth.getCurrentUser
    );
    if (!userData) {
      return { user: null, permissions: [] };
    }

    const path = PATH_BACKEND.user.root + '/' + userData.profileId;
    const userResponseDTO = await this.entityApiService.getWithCredentials<UserSPResponseDTO | null>(path);

    if (userResponseDTO) {
      const user = userResponseDTO && UserUtils.mapUserResponseByType(userResponseDTO, this.modelMappers);
      return { user, permissions: userData.authorities };
    }

    return { user: null, permissions: [] };
  };
}

import RecommendationRuleListFilter from 'components/_dashboardPagesFeatures/recommendationsConfig/rules/RecommendationRuleListFilter';
import RecommendationsRulesListBlock from 'components/_dashboardPagesFeatures/recommendationsConfig/rules/RecommendationsRulesListBlock';
import EmptyListBlock from 'components/features/list/EmptyListBlock';
import { BubbleChartIcon } from 'components/ui/icons';
import LoaderComponent from 'components/ui/loadingIndicators/LoaderComponent';
import useInitializePage from 'hooks/useInitializePage';
import useLocales from 'hooks/useLocales';
import useRecommendationTabs from 'pages/dashboard/recommendation/useRecommendationTabs';
import React, { useMemo, useState } from 'react';
import { PAGE_TYPE } from 'services/routing/PATH_PARAM';
import { RoutingServiceDashboard } from 'services/routing/RoutingService';
import { useSelector } from 'storage';
import { RecommendationConfigResponseDTO } from 'typings/dto/recommendationsConfig';
import StringUtils from 'utils/String';
import useDI from 'hooks/useDI';
import { MODEL_PERMISSION } from 'configs/permissions/modelPermissions';
import ImportDataPopup from 'components/_dashboardPagesFeatures/promotionsConfig/import/ImportDataPopup';
import PopupContent from 'components/ui/popups/PopupContent';

const rootModelPath: keyof RecommendationConfigResponseDTO = 'recommendationsConfig';
const modelPath: keyof RecommendationConfigResponseDTO['recommendationsConfig'] = 'recommendations';

export default function RulesListPage() {
  const { recommendationsConfigIsLoading, recommendationsConfig } = useSelector((state) => state.recommendationsConfig);
  const { currentUser, permissions } = useSelector((state) => state.auth);
  const { translate } = useLocales();
  const tabs = useRecommendationTabs();
  const { areAllLoading: areDictionariesLoading, dictionaryAll } = useSelector((state) => state.dictionary);
  const { areAllLoading: areServicesLoading, serviceList: services } = useSelector((state) => state.service);
  const [rules, setRules] = useState(recommendationsConfig?.recommendationsConfig.recommendations || []);
  const [initialRules, setInitialRules] = useState(recommendationsConfig?.recommendationsConfig.recommendations || []);
  const { storageActions } = useDI();
  const [importPopupOpened, setImportPopupOpened] = useState(false);

  const importHandler = async (file: File, force: boolean) => {
    await storageActions.models.recommendationsConfig.importConfig(file, force ? 'true' : 'false');
  };

  const exportHandler = async () => {
    await storageActions.models.recommendationsConfig.exportConfig();
  };

  const dictionaryMap: Record<string, Dictionary> = useMemo(() => {
    return dictionaryAll.reduce((acc: Record<string, Dictionary>, item: Dictionary) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [areDictionariesLoading]);

  const intersectRulesWithServices = () => {
    if (recommendationsConfig) {
      const rules: RecommendationRule[] = [...recommendationsConfig.recommendationsConfig.recommendations];
      services.forEach((service) => {
        if (
          service.tenant.id === currentUser?.tenant.id &&
          !recommendationsConfig.recommendationsConfig.recommendations.some(
            (rule: RecommendationRule) => rule.serviceItem.id === service.technicalName
          )
        ) {
          const rule = {
            id: StringUtils.generateUUIDv4(),
            serviceItem: { id: service.technicalName, name: service.name },
            serviceCategory: { id: service.category.technicalName, name: service.category.name },
            predefined: [],
          };
          rules.push(rule);
        }
      });
      setRules(rules);
      setInitialRules(rules);
    }
  };

  const isPageInitialized = useInitializePage(() => {
    intersectRulesWithServices();
    const title = translate('pages.recommendationsList.title');
    const breadcrumbs = RoutingServiceDashboard.recommendationsConfig.getListBc();
    const menu: (MenuIconItemData | MenuItemData[])[] = [];
    const submenu: MenuItemData[] = [
      {
        title: translate('buttons.export'),
        handler: () => {
          exportHandler();
        },
      },
      {
        title: translate('buttons.import'),
        handler: () => {
          setImportPopupOpened(true);
        },
      },
    ];
    if (permissions.can(MODEL_PERMISSION.CAN_UPDATE_RECOMMENDATIONS)) {
      menu.push(submenu);
    }
    return { menu, title, breadcrumbs, tabs, currentTab: PAGE_TYPE.list };
  }, [recommendationsConfigIsLoading, areDictionariesLoading, areServicesLoading]);

  if (!isPageInitialized) {
    return <LoaderComponent />;
  }

  if (!recommendationsConfig) {
    return null;
  }

  if (services.length === 0) {
    return (
      <EmptyListBlock
        icon={<BubbleChartIcon />}
        title={translate('pages.recommendationsList.emptyTitle')}
        subtitle={translate('pages.recommendationsList.emptySubtitle')}
      ></EmptyListBlock>
    );
  }

  return (
    <>
      <RecommendationRuleListFilter
        isPageInitialized={isPageInitialized}
        serviceList={services}
        setRulesList={setRules}
        initialRules={initialRules}
      />
      <RecommendationsRulesListBlock
        recommendationRules={rules}
        dictionaryMap={dictionaryMap}
        servicesList={services}
        recommendationsConfig={recommendationsConfig}
        parentModelPath={rootModelPath + '.' + modelPath}
      />
      {importPopupOpened && (
        <PopupContent
          isOpened={importPopupOpened}
          setIsOpened={setImportPopupOpened}
          title={translate('pages.recommendationsList.importTitle')}
          size="small"
        >
          <ImportDataPopup closeHandler={() => setImportPopupOpened(false)} submitHandler={importHandler} />
        </PopupContent>
      )}
    </>
  );
}

//export default React.memo(RulesListPage, () => true);

// TODO: вынести компоненты в lazy
import PGuard from 'components/guards/router/PermissionGuard';
import UserTenantSettingsGlobalGuard from 'components/guards/router/UserTenantSettingsGlobalGuard';
import { Loadable, LoadableBackground, LoadableCustom } from 'components/ui/loadingIndicators/Loadable';
import { FIELD_PERMISSION } from 'configs/permissions/fieldPermissions';
import { MODEL_PERMISSION } from 'configs/permissions/modelPermissions';
import useLocales from 'hooks/useLocales';
import CanReadOrderRedirectPage from 'pages/dashboard/order/CanReadOrderRedirectPage';
import OrderUniversalRedirectPage from 'pages/dashboard/order/OrderUniversalRedirectPage';
import { lazy, useMemo } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { TENANT_TYPE } from 'typings/subEntities/tenant.enum';
import RecommendationsConfigDataWrapper from 'pages/dashboard/recommendation/RecommendationsConfigDataWrapper';
import RulesListPage from 'pages/dashboard/recommendation/rules/RulesListPage';
import RecommendationVariablesEditPage from 'pages/dashboard/recommendation/variables/RecommendationVariablesEditPage';
import AuthGuard from '../../components/guards/router/AuthGuard';
import BooleanGuard from '../../components/guards/router/BooleanGuard';
import GuestGuard from '../../components/guards/router/GuestGuard';
import PATH_PARAM, { PAGE_TYPE, PATH_PARAM_DASHBOARD, PATH_PARAM_TOKEN } from '../../services/routing/PATH_PARAM';
import OrderFilterRedirectPage from '../../pages/dashboard/order/OrderFilterRedirectPage';
import { useSelector } from 'storage';
import OrderAddLinkedSessionPage from 'pages/dashboard/order/order/sessionPages/OrderAddLinkedSessionPage';
import { CALENDAR_VIEW_TYPE } from 'typings/models/calendar/view.enum';
import UnauthorizedLayout from 'components/layouts/UnauthorizedLayout';
import SuperAdminApiSettingsInfoPage from 'pages/dashboard/superadmin/settings/SuperAdminApiSettingsInfoPage';

export default function Router() {
  const { translate } = useLocales();
  const { permissions, currentUser } = useSelector((state) => state.auth);
  // Не уверен, даёт ли useMemo здесь что-нибудь
  const routes: RouteObject[] = useMemo(() => {
    const authRoutes: RouteObject[] = [
      { path: '', element: <Navigate to={PATH_PARAM.auth.login} replace /> },
      { path: PATH_PARAM.auth.login, element: <GuestGuard onSuccess={<Login />} /> },
      { path: PATH_PARAM.auth.profile, element: <VerifyCredentialsPage /> },
      { path: PATH_PARAM.auth.resetPassword, element: <GuestGuard onSuccess={<ResetPasswordPage />} /> },
      { path: PATH_PARAM.auth.spInvitation, element: <GuestGuard onSuccess={<ServiceProviderInvitationPage />} /> },
    ];

    const clientRoutes: RouteObject[] = [
      {
        path: `${PATH_PARAM.client.orders}/:${PATH_PARAM_TOKEN.client.orderId}`,
        element: <ClientRootWrapper />,
        children: [
          { path: '', element: <Navigate to={PAGE_TYPE.info} replace /> },
          { path: PAGE_TYPE.info, element: <ClientOrderInfoPage /> },
          { path: PATH_PARAM.client.comments, element: <ClientCommentListPage /> },
          { path: PATH_PARAM.client.paidSuccessfully, element: <ClientOrderPaidSuccessfullyPage /> },
          { path: PATH_PARAM.client.paidUnsuccessfully, element: <ClientOrderPaidUnsuccessfullyPage /> },
          { path: PATH_PARAM.client.rate, element: <ClientOrderReviewEditPage /> },
          { path: PATH_PARAM.client.services, element: <ClientServicesListPage /> },
        ],
      },
    ];

    const fundsTransactionRoutes: RouteObject[] = [
      {
        path: '',
        element: <Navigate to={`${PATH_PARAM_DASHBOARD.fundsTransaction.addition}/${PAGE_TYPE.list}`} replace />,
      },
      {
        path: `${PATH_PARAM_DASHBOARD.fundsTransaction.addition}/:${PATH_PARAM_TOKEN.fundsTransaction.fundsTransactionId}`,
        element: <PGuard children={<FundsTransactionDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_TRANSACTION_TYPE} />,
        children: [
          {
            path: '',
            element: <PGuard children={<FundsTransactionListPage />} permission={MODEL_PERMISSION.CAN_READ_TRANSACTION_TYPE_LIST} />,
          },
        ],
      },
      {
        path: `${PATH_PARAM_DASHBOARD.fundsTransaction.deduction}/:${PATH_PARAM_TOKEN.fundsTransaction.fundsTransactionId}`,
        element: <PGuard children={<FundsTransactionDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_TRANSACTION_TYPE} />,
        children: [
          {
            path: '',
            element: (
              <PGuard children={<FundTransactionDeductionListPage />} permission={MODEL_PERMISSION.CAN_READ_TRANSACTION_TYPE_LIST} />
            ),
          },
        ],
      },
      {
        path: `:${PATH_PARAM_TOKEN.fundsTransaction.fundsTransactionId}`,
        element: <PGuard children={<FundsTransactionDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_TRANSACTION_TYPE} />,
        children: [
          {
            path: PAGE_TYPE.edit,
            element: <PGuard children={<FundsTransactionEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_TRANSACTION_TYPE} />,
          },
          {
            path: `${PAGE_TYPE.add}/:${PATH_PARAM_TOKEN.fundsTransaction.type}/:${PATH_PARAM_TOKEN.fundsTransaction.group}`,
            element: <PGuard children={<FundsTransactionEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_TRANSACTION_TYPE} />,
          },
          {
            path: PAGE_TYPE.info,
            element: <PGuard children={<FundsTransactionInfoPage />} permission={MODEL_PERMISSION.CAN_READ_TRANSACTION_TYPE} />,
          },
        ],
      },
    ];

    const priceRoutes: RouteObject[] = [
      {
        path: PATH_PARAM_DASHBOARD.price.serviceProvider,
        element: (
          <DashboardSectionLayout title={translate('pages.priceServiceProviderList.title')}>
            <PGuard children={<PricelistServiceProviderDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_SERVICE_PROVIDER_PRICELIST} />
          </DashboardSectionLayout>
        ),
        children: [
          { path: '', element: <Navigate to={PAGE_TYPE.list} replace /> },
          {
            path: `:${PATH_PARAM_TOKEN.price.pricelistId}`,
            children: [
              {
                path: '',
                element: (
                  <PGuard
                    children={<PricelistServiceProviderRedirectToFirstPricelistPage />}
                    permission={MODEL_PERMISSION.CAN_READ_SERVICE_PROVIDER_PRICELIST_LIST}
                  />
                ),
              },
              {
                path: PAGE_TYPE.add,
                element: (
                  <PGuard
                    children={<PricelistServiceProviderEditPage />}
                    permission={MODEL_PERMISSION.CAN_CREATE_SERVICE_PROVIDER_PRICELIST}
                  />
                ),
              },
              {
                path: PAGE_TYPE.edit,
                element: (
                  <PGuard
                    children={<PricelistServiceProviderEditPage />}
                    permission={MODEL_PERMISSION.CAN_UPDATE_SERVICE_PROVIDER_PRICELIST}
                  />
                ),
              },
              {
                path: PAGE_TYPE.list,
                element: (
                  <PGuard children={<PriceServiceProviderDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_SERVICE_PROVIDER_PRICE} />
                ),
                children: [
                  {
                    path: '',
                    element: (
                      <PGuard
                        children={<PriceServiceProviderListPage />}
                        permission={MODEL_PERMISSION.CAN_READ_SERVICE_PROVIDER_PRICE_LIST}
                      />
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: PATH_PARAM_DASHBOARD.price.platformOperator,
        element: (
          <DashboardSectionLayout title={translate('pages.pricePlatformOperatorList.title')}>
            <PGuard
              children={<PricelistPlatformOperatorDataWrapper />}
              permission={MODEL_PERMISSION.CAN_READ_PLATFORM_OPERATOR_PRICELIST}
            />
          </DashboardSectionLayout>
        ),
        children: [
          { path: '', element: <Navigate to={PAGE_TYPE.list} replace /> },
          {
            path: `:${PATH_PARAM_TOKEN.price.pricelistId}`,
            children: [
              {
                path: '',
                element: (
                  <PGuard
                    children={<PricelistPlatformOperatorRedirectToFirstPricelistPage />}
                    permission={MODEL_PERMISSION.CAN_READ_PLATFORM_OPERATOR_PRICELIST_LIST}
                  />
                ),
              },
              {
                path: PAGE_TYPE.add,
                element: (
                  <PGuard
                    children={<PricelistPlatformOperatorEditPage />}
                    permission={MODEL_PERMISSION.CAN_CREATE_PLATFORM_OPERATOR_PRICELIST}
                  />
                ),
              },
              {
                path: PAGE_TYPE.edit,
                element: (
                  <PGuard
                    children={<PricelistPlatformOperatorEditPage />}
                    permission={MODEL_PERMISSION.CAN_UPDATE_PLATFORM_OPERATOR_PRICELIST}
                  />
                ),
              },
              {
                path: PAGE_TYPE.list,
                element: (
                  <PGuard children={<PricePlatformOperatorDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_PLATFORM_OPERATOR_PRICE} />
                ),
                children: [
                  {
                    path: '',
                    element: (
                      <PGuard
                        children={<PricePlatformOperatorListPage />}
                        permission={MODEL_PERMISSION.CAN_READ_PLATFORM_OPERATOR_PRICE_LIST}
                      />
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: PATH_PARAM_DASHBOARD.price.recommendedPrice,
        // Так как recommendedPrice это не отдельная модель, а по факту pricePlatformOperator используются те же dataWrapper
        element: (
          <DashboardSectionLayout title={translate('pages.recommendedClientPriceList.title')}>
            <PGuard
              children={<PricelistPlatformOperatorDataWrapper />}
              permission={MODEL_PERMISSION.CAN_READ_PLATFORM_OPERATOR_PRICELIST}
            />
          </DashboardSectionLayout>
        ),
        children: [
          { path: '', element: <Navigate to={PAGE_TYPE.list} replace /> },
          {
            path: `:${PATH_PARAM_TOKEN.price.pricelistId}`,
            children: [
              {
                path: '',
                element: (
                  <PGuard
                    children={<PricelistRecommendedPriceRedirectToFirstPricelistPage />}
                    permission={MODEL_PERMISSION.CAN_READ_PLATFORM_OPERATOR_PRICELIST_LIST}
                  />
                ),
              },
              {
                path: PAGE_TYPE.add,
                element: (
                  <PGuard
                    children={<PricelistRecommendedPriceEditPage />}
                    permission={MODEL_PERMISSION.CAN_CREATE_PLATFORM_OPERATOR_PRICELIST}
                  />
                ),
              },
              {
                path: PAGE_TYPE.edit,
                element: (
                  <PGuard
                    children={<PricelistRecommendedPriceEditPage />}
                    permission={MODEL_PERMISSION.CAN_UPDATE_PLATFORM_OPERATOR_PRICELIST}
                  />
                ),
              },
              {
                path: PAGE_TYPE.list,
                // Так как recommendedPrice это не отдельная модель, а по факту pricePlatformOperator используются те же dataWrapper
                element: (
                  <PGuard children={<PricePlatformOperatorDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_PLATFORM_OPERATOR_PRICE} />
                ),
                children: [
                  {
                    path: '',
                    element: (
                      <PGuard
                        children={<PriceRecommendedPriceListPage />}
                        permission={MODEL_PERMISSION.CAN_READ_PLATFORM_OPERATOR_PRICE_LIST}
                      />
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: PATH_PARAM_DASHBOARD.price.client,
        element: (
          <DashboardSectionLayout title={translate('pages.priceClientList.title')}>
            <PGuard children={<PriceClientDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_CLIENT_PRICE} />
          </DashboardSectionLayout>
        ),
        children: [
          {
            path: '',
            element: <Navigate to={`${PATH_PARAM_DASHBOARD.price.default}/${PAGE_TYPE.list}`} replace />,
          },
          {
            path: `${PATH_PARAM_DASHBOARD.price.default}/${PAGE_TYPE.list}`,
            element: <PGuard children={<PriceClientListPage />} permission={MODEL_PERMISSION.CAN_READ_CLIENT_PRICE_LIST} />,
          },
          {
            path: `${PATH_PARAM_DASHBOARD.price.default}/${PAGE_TYPE.edit}`,
            element: <PGuard children={<PriceClientEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_CLIENT_PRICE} />,
          },
        ],
      },
      {
        path: PATH_PARAM_DASHBOARD.price.technician,
        element: (
          <DashboardSectionLayout title={translate('pages.priceTechnicianList.title')}>
            <PGuard children={<PriceTechnicianDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_TECHNICIAN_PRICE} />
          </DashboardSectionLayout>
        ),
        children: [
          {
            path: '',
            element: <Navigate to={`${PATH_PARAM_DASHBOARD.price.default}/${PAGE_TYPE.list}`} replace />,
          },
          {
            path: `${PATH_PARAM_DASHBOARD.price.default}/${PAGE_TYPE.list}`,
            element: <PGuard children={<PriceTechnicianListPage />} permission={MODEL_PERMISSION.CAN_READ_TECHNICIAN_PRICE_LIST} />,
          },
          {
            path: `${PATH_PARAM_DASHBOARD.price.default}/${PAGE_TYPE.edit}`,
            element: <PGuard children={<PriceTechnicianEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_TECHNICIAN_PRICE} />,
          },
        ],
      },
    ];

    const orderIdRoutes: RouteObject[] = [
      // { path: '', element: <OrderInfoPage /> }, // кейс дефолтной страницы обрабатывается в OrderBlockLayout, т.к. там есть бизнес требования
      {
        path: PATH_PARAM_DASHBOARD.order.main,
        children: [{ path: PAGE_TYPE.info, element: <OrderInfoPage /> }],
      },
      {
        path: `${PATH_PARAM_DASHBOARD.order.services}/:${PATH_PARAM_TOKEN.order.serviceId}`,
        children: [
          {
            path: '',
            element: <PGuard children={<OrderServiceListPage />} permission={MODEL_PERMISSION.CAN_READ_ORDER_SERVICE_LIST} />,
          },
          {
            path: PAGE_TYPE.edit,
            element: <PGuard children={<OrderServiceEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_ORDER_SERVICE} />,
          },
          {
            path: PAGE_TYPE.add,
            element: <PGuard children={<OrderServiceEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_ORDER_SERVICE} />,
          },
        ],
      },
      {
        path: `${PATH_PARAM_DASHBOARD.order.providerTransactions}/:${PATH_PARAM_TOKEN.order.providerTransactionId}`,
        children: [
          {
            path: '',
            element: (
              <PGuard children={<OrderProviderTransactionsListPage />} permission={MODEL_PERMISSION.CAN_READ_ORDER_TRANSACTION_LIST} />
            ),
          },
          // {
          //   path: PAGE_TYPE.edit,
          //   element: <PGuard children={<OrderServiceEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_ORDER_SERVICE} />,
          // },
          // {
          //   path: PAGE_TYPE.add,
          //   element: <PGuard children={<OrderServiceEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_ORDER_SERVICE} />,
          // },
        ],
      },
      {
        path: `${PATH_PARAM_DASHBOARD.order.report}/:${PATH_PARAM_TOKEN.order.reportId}`,
        element: <PGuard children={<OrderReportDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_ORDER_REPORT} />,
        children: [
          {
            path: '',
            element: <PGuard children={<OrderReportListPage />} permission={MODEL_PERMISSION.CAN_READ_ORDER_REPORT_LIST} />,
          },
          {
            path: PAGE_TYPE.add,
            element: <PGuard children={<OrderReportEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_ORDER_REPORT} />,
          },
          {
            path: PAGE_TYPE.edit,
            element: <PGuard children={<OrderReportEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_ORDER_REPORT} />,
          },
        ],
      },
      {
        path: PATH_PARAM_DASHBOARD.order.review,
        children: [
          {
            path: PAGE_TYPE.info,
            element: <PGuard children={<OrderReviewInfoPage />} permission={MODEL_PERMISSION.CAN_READ_ORDER_REVIEW} />,
          },
          {
            path: PAGE_TYPE.edit,
            element: <PGuard children={<OrderReviewEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_ORDER_REVIEW} />,
          },
          {
            path: PAGE_TYPE.add,
            element: <PGuard children={<OrderReviewEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_ORDER_REVIEW} />,
          },
        ],
      },
      {
        path: PATH_PARAM_DASHBOARD.order.history,
        children: [
          {
            path: PAGE_TYPE.list,
            element: <PGuard children={<OrderHistoryListPage />} permission={MODEL_PERMISSION.CAN_READ_ORDER_HISTORY_LIST} />,
          },
        ],
      },
      {
        path: PATH_PARAM_DASHBOARD.order.goodInformation,
        children: [
          {
            path: PAGE_TYPE.list,
            element: <OrderGoodInformationListPage />,
          },
        ],
      },
      {
        path: PATH_PARAM_DASHBOARD.order.comments,
        children: [
          {
            path: PAGE_TYPE.list,
            element: <PGuard children={<OrderCommentListPage />} permission={MODEL_PERMISSION.CAN_READ_ORDER_COMMENT_LIST} />,
          },
        ],
      },
      {
        path: PATH_PARAM_DASHBOARD.order.documents,
        children: [
          {
            path: PAGE_TYPE.list,
            element: <PGuard children={<OrderDocumentListPage />} permission={MODEL_PERMISSION.CAN_READ_ORDER} />,
          },
        ],
      },
      {
        path: `${PATH_PARAM_DASHBOARD.order.tasks}/:${PATH_PARAM_TOKEN.order.taskId}`,
        children: [
          {
            path: '',
            element: <PGuard children={<OrderTaskListPage />} permission={MODEL_PERMISSION.CAN_READ_ORDER_TASK_LIST} />,
          },
          {
            path: PAGE_TYPE.add,
            element: <PGuard children={<OrderTaskEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_ORDER_TASK} />,
          },
          {
            path: PAGE_TYPE.edit,
            element: <BooleanGuard children={<OrderTaskEditPage />} isAllowed={permissions.can(MODEL_PERMISSION.CAN_UPDATE_ORDER_TASK)} />,
          },
        ],
      },
    ];

    const orderRoutes: RouteObject[] = [
      //на всякий оставлю как была сделана дефолтная инициализация страницы
      //{ path: '', element: <Navigate to={`${DEFAULT_ORDER_VIEW_NAME.all}/${PAGE_TYPE.list}`} replace /> },
      { path: '', element: <OrderFilterRedirectPage /> },
      {
        path: PAGE_TYPE.add,
        element: (
          <PGuard permission={MODEL_PERMISSION.CAN_CREATE_ORDER}>
            <DashboardSectionLayout title={translate('menus.main.orders')} />
          </PGuard>
        ),
        children: [
          { path: '', element: <OrderAddPage /> },
          {
            path: `:${PATH_PARAM_TOKEN.order.orderId}/${PATH_PARAM_DASHBOARD.order.linked}`,
            element: <OrderAddLinkedPage />,
          },
          {
            path: `:${PATH_PARAM_TOKEN.order.orderId}/${PATH_PARAM_DASHBOARD.order.complaint}`,
            element: <OrderAddComplaintPage />,
          },
          {
            path: `:${PATH_PARAM_TOKEN.orderSession.orderSessionId}/${PATH_PARAM_DASHBOARD.order.session}`,
            element: <OrderAddSessionPage />,
          },
          {
            path: `:${PATH_PARAM_TOKEN.orderSession.orderSessionId}/${PATH_PARAM_DASHBOARD.order.session}/:${PATH_PARAM_TOKEN.orderSession.orderId}/${PATH_PARAM_DASHBOARD.orderSession.complaint}`,
            element: <OrderAddSessionPage />,
          },
          {
            path: `:${PATH_PARAM_TOKEN.orderSession.orderSessionId}/${PATH_PARAM_DASHBOARD.order.session}/:${PATH_PARAM_TOKEN.orderSession.orderId}/${PATH_PARAM_DASHBOARD.orderSession.warranty}`,
            element: <OrderAddSessionPage />,
          },
          {
            path: `:${PATH_PARAM_TOKEN.orderSession.orderSessionId}/${PATH_PARAM_DASHBOARD.order.session}/:${PATH_PARAM_TOKEN.orderSession.orderId}/${PATH_PARAM_DASHBOARD.orderSession.linked}`,
            element: <OrderAddLinkedSessionPage />,
          },
        ],
      },
      // Вроде нигде не используется больше, надо удалить если не вылезет 404
      // {
      //   path: `:${PATH_PARAM_TOKEN.order.orderId}/${PAGE_TYPE.add}`,
      //   element: (
      //     <DashboardSectionLayout title={translate('menus.main.orders')}>
      //       <PGuard children={<OrderAddLinkedPage />} permission={MODEL_PERMISSION.CAN_CREATE_ORDER} />
      //     </DashboardSectionLayout>
      //   ),
      // },
      {
        path: `:${PATH_PARAM_TOKEN.order.orderViewTechName}/:${PATH_PARAM_TOKEN.order.orderId}`,
        element: <PGuard children={<OrderRootWrapper />} permission={MODEL_PERMISSION.CAN_READ_ORDER} />,
        children: orderIdRoutes,
      },
      // Вроде нигде не используется больше, надо удалить если не вылезет 404
      // {
      //   path: `:${PATH_PARAM_TOKEN.order.orderId}/${PAGE_TYPE.add}`,
      //   element: (
      //     <DashboardSectionLayout title={translate('menus.main.orders')}>
      //       <PGuard children={<OrderAddLinkedPage />} permission={MODEL_PERMISSION.CAN_CREATE_ORDER} />
      //     </DashboardSectionLayout>
      //   ),
      // },

      // Вроде нигде не используется больше, надо удалить если не вылезет 404
      // {
      //   path: `:${PATH_PARAM_TOKEN.order.orderId}/${PAGE_TYPE.add}`,
      //   element: (
      //     <DashboardSectionLayout title={translate('menus.main.orders')}>
      //       <PGuard children={<OrderAddLinkedPage />} permission={MODEL_PERMISSION.CAN_CREATE_ORDER} />
      //     </DashboardSectionLayout>
      //   ),
      // },
      // {
      //   path: `:${PATH_PARAM_TOKEN.order.orderViewTechName}/:${PATH_PARAM_TOKEN.order.orderId}`,
      //   element: <PGuard children={<OrderRootWrapper />} permission={MODEL_PERMISSION.CAN_READ_ORDER} />,
      //   children: orderIdRoutes,
      // },
    ];

    const calendarRoutes: RouteObject[] = [
      {
        path: '',
        element: <Navigate to={`${CALENDAR_VIEW_TYPE.browsing}`} />,
      },
      {
        path: `:${PATH_PARAM_TOKEN.calendar.viewType}`,
        element: <CalendarRootWrapper />,
        children: [
          {
            path: '',
            element: <CalendarBlock />,
            children: [
              {
                path: `order/:${PATH_PARAM_TOKEN.calendar.orderId}`,
                element: <CrossInterfaceOrderWrapper />,
                children: orderIdRoutes,
              },
            ],
          },
        ],
      },
    ];

    const contractSPRoutes: RouteObject[] = [
      { path: '', element: <Navigate to={PAGE_TYPE.list} replace /> },
      {
        path: `:${PATH_PARAM_TOKEN.contract.contractId}`,
        element: <PGuard children={<ContractSPDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_SP_CONTRACT} />,
        children: [
          {
            path: '',
            element: <PGuard children={<ContractSPListPage />} permission={MODEL_PERMISSION.CAN_READ_SP_CONTRACT_LIST} />,
          },
          {
            path: PAGE_TYPE.edit,
            element: <PGuard children={<ContractSPEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_SP_CONTRACT} />,
          },
          {
            path: PAGE_TYPE.add,
            element: <PGuard children={<ContractSPAddPage />} permission={MODEL_PERMISSION.CAN_CREATE_SP_CONTRACT} />,
          },
          {
            path: PAGE_TYPE.info,
            element: <PGuard children={<ContractSPInfoPage />} permission={MODEL_PERMISSION.CAN_READ_SP_CONTRACT} />,
          },
          {
            path: PATH_PARAM_DASHBOARD.contractSP.legalEntity,
            element: <PGuard children={<ContractSPLEInfoPage />} permission={FIELD_PERMISSION.CAN_READ_SP_CONTRACT_PROVIDER_DATA} />,
          },
          {
            path: PATH_PARAM_DASHBOARD.contractSP.reviews,
            element: <PGuard children={<ContractSPReviewPage />} permission={FIELD_PERMISSION.CAN_READ_SP_CONTRACT_PROVIDER_DATA} />,
          },
          {
            path: `${PATH_PARAM_DASHBOARD.contractSP.employees}/:${PATH_PARAM_TOKEN.contract.employeeId}`,
            element: (
              <PGuard children={<ContractSPEmployeesDataWrapper />} permission={FIELD_PERMISSION.CAN_READ_SP_CONTRACT_PROVIDER_DATA} />
            ),
            children: [
              {
                path: '',
                element: (
                  <PGuard children={<ContractSPEmployeesListPage />} permission={FIELD_PERMISSION.CAN_READ_SP_CONTRACT_PROVIDER_DATA} />
                ),
              },
              {
                path: PAGE_TYPE.info,
                element: <ContractSPEmployeeReviewPage />,
              },
            ],
          },
          {
            path: PATH_PARAM_DASHBOARD.contractSP.prices,
            element: (
              <PGuard permission={MODEL_PERMISSION.CAN_READ_SERVICE_PROVIDER_PRICE}>
                <ContractPriceSPLoaderFix>
                  <ContractPriceSPDataWrapper />
                </ContractPriceSPLoaderFix>
              </PGuard>
            ),
            children: [
              {
                path: PAGE_TYPE.list,
                element: (
                  <PGuard children={<ContractPriceSPListPage />} permission={MODEL_PERMISSION.CAN_READ_SERVICE_PROVIDER_PRICE_LIST} />
                ),
              },
            ],
          },
        ],
      },
    ];

    const contractPORoutes: RouteObject[] = [
      { path: '', element: <Navigate to={PAGE_TYPE.list} replace /> },
      {
        path: `:${PATH_PARAM_TOKEN.contract.contractId}`,
        element: <PGuard children={<ContractPODataWrapper />} permission={MODEL_PERMISSION.CAN_READ_PO_CONTRACT} />,
        children: [
          {
            path: '',
            element: <PGuard children={<ContractPOListPage />} permission={MODEL_PERMISSION.CAN_READ_PO_CONTRACT_LIST} />,
          },
          {
            path: PAGE_TYPE.edit,
            element: <PGuard children={<ContractPOEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_PO_CONTRACT} />,
          },
          {
            path: PAGE_TYPE.add,
            element: <PGuard children={<ContractPOAddPage />} permission={MODEL_PERMISSION.CAN_CREATE_PO_CONTRACT} />,
          },
          {
            path: PAGE_TYPE.info,
            element: <PGuard children={<ContractPOInfoPage />} permission={MODEL_PERMISSION.CAN_READ_PO_CONTRACT} />,
          },
          {
            path: PATH_PARAM_DASHBOARD.contractPO.legalEntity,
            element: <PGuard children={<ContractPOLEInfoPage />} permission={FIELD_PERMISSION.CAN_READ_PO_CONTRACT_PROVIDER_DATA} />,
          },
          {
            path: PATH_PARAM_DASHBOARD.contractPO.reviews,
            element: <PGuard children={<ContractPOReviewPage />} permission={FIELD_PERMISSION.CAN_READ_PO_CONTRACT_PROVIDER_DATA} />,
          },
          {
            path: PATH_PARAM_DASHBOARD.contractPO.prices,
            element: (
              <PGuard permission={MODEL_PERMISSION.CAN_READ_CLIENT_PRICE}>
                <ContractPricePOLoaderFix>
                  <ContractPricePODataWrapper />
                </ContractPricePOLoaderFix>
              </PGuard>
            ),
            children: [
              {
                path: PAGE_TYPE.list,
                element: <PGuard children={<ContractPricePOListPage />} permission={MODEL_PERMISSION.CAN_READ_CLIENT_PRICE_LIST} />,
              },
              {
                path: PAGE_TYPE.edit,
                element: (
                  <PGuard
                    children={<ContractPOPriceSettingsEditPage />}
                    permission={MODEL_PERMISSION.CAN_UPDATE_PO_CONTRACT_PRICELIST_SETTINGS}
                  />
                ),
              },
            ],
          },
        ],
      },
    ];

    const invoiceProviderRoutes: RouteObject[] = [
      { path: '', element: <Navigate to={PAGE_TYPE.list} replace /> },
      {
        path: `:${PATH_PARAM_TOKEN.invoice.invoiceId}`,
        element: <PGuard children={<InvoiceProviderDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_PROVIDER_INVOICE_LIST} />,
        children: [
          {
            path: '',
            element: <PGuard children={<InvoiceProviderListPage />} permission={MODEL_PERMISSION.CAN_READ_PROVIDER_INVOICE_LIST} />,
          },
          {
            path: PAGE_TYPE.info,
            element: <PGuard children={<InvoiceInfoPage />} permission={MODEL_PERMISSION.CAN_READ_PROVIDER_INVOICE_LIST} />,
          },
          {
            path: PAGE_TYPE.add,
            element: <PGuard children={<InvoiceProviderEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_PROVIDER_INVOICE} />,
          },
          {
            path: PAGE_TYPE.edit,
            element: <PGuard children={<InvoiceProviderEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_PROVIDER_INVOICE} />,
          },
          {
            path: `${PATH_PARAM_DASHBOARD.invoiceProvider.orders}/${PAGE_TYPE.add}`,
            element: <PGuard children={<InvoiceOrderTransactionAddPage />} permission={MODEL_PERMISSION.CAN_UPDATE_PROVIDER_INVOICE} />,
          },
        ],
      },
    ];

    const invoicePORoutes: RouteObject[] = [
      { path: '', element: <Navigate to={PAGE_TYPE.list} replace /> },
      {
        path: `:${PATH_PARAM_TOKEN.invoice.invoiceId}`,
        element: <PGuard children={<InvoicePODataWrapper />} permission={MODEL_PERMISSION.CAN_READ_PO_INVOICE_LIST} />,
        children: [
          {
            path: '',
            element: <PGuard children={<InvoicePOListPage />} permission={MODEL_PERMISSION.CAN_READ_PO_INVOICE_LIST} />,
          },
          {
            path: PAGE_TYPE.info,
            element: <PGuard children={<InvoiceInfoPage />} permission={MODEL_PERMISSION.CAN_READ_PO_INVOICE_LIST} />,
          },
          {
            path: PAGE_TYPE.add,
            element: <PGuard children={<InvoicePOEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_PO_INVOICE} />,
          },
          {
            path: PAGE_TYPE.edit,
            element: <PGuard children={<InvoicePOEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_PO_INVOICE} />,
          },
          {
            path: `${PATH_PARAM_DASHBOARD.invoiceProvider.orders}/${PAGE_TYPE.add}`,
            element: <PGuard children={<InvoiceOrderTransactionAddPage />} permission={MODEL_PERMISSION.CAN_UPDATE_PO_INVOICE} />,
          },
        ],
      },
    ];

    const serviceProviderRoutes: RouteObject[] = [
      { path: '', element: <ServiceProviderRedirectToMyPage /> },
      {
        path: `:${PATH_PARAM_TOKEN.serviceProvider.serviceProviderId}`,
        element: <ServiceProviderDataWrapper />,
        children: [
          { path: '', element: null },
          { path: PAGE_TYPE.info, element: <ServiceProviderInfoPage /> },
          {
            path: PAGE_TYPE.edit,
            element: <PGuard children={<ServiceProviderEditPage />} permission={MODEL_PERMISSION.FAKE_FORBIDDEN} />,
          },
          {
            path: PAGE_TYPE.add,
            element: <PGuard children={<ServiceProviderEditPage />} permission={MODEL_PERMISSION.FAKE_FORBIDDEN} />,
          },
          { path: PATH_PARAM_DASHBOARD.serviceProvider.reviewList, element: <ServiceProviderReviewListPage /> },
          {
            path: PATH_PARAM_DASHBOARD.serviceProvider.legalInfo,
            element: (
              <PGuard permission={MODEL_PERMISSION.CAN_READ_SP_LEGAL_ENTITY}>
                <ServiceProviderLegalLoaderFix>
                  <ServiceProviderLegalDataWrapper />
                </ServiceProviderLegalLoaderFix>
              </PGuard>
            ),
            children: [
              {
                path: PAGE_TYPE.info,
                element: <ServiceProviderLegalInfoPage />,
              },
              {
                path: PAGE_TYPE.edit,
                element: <PGuard children={<ServiceProviderLegalEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_SP_LEGAL_ENTITY} />,
              },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.serviceProvider.employees}/:${PATH_PARAM_TOKEN.serviceProvider.employeeId}`,
            element: <PGuard children={<SPEmployeeDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_SP_USER} />,
            children: [
              {
                path: '',
                element: <PGuard children={<SPEmployeeListPage />} permission={MODEL_PERMISSION.CAN_READ_SP_USER_LIST} />,
              },
              { path: PAGE_TYPE.info, element: <SPEmployeeInfoPage /> },
              {
                path: PAGE_TYPE.add,
                element: <PGuard children={<SPEmployeeEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_SP_USER} />,
              },
              {
                path: PAGE_TYPE.edit,
                element: <PGuard children={<SPEmployeeEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_SP_USER} />,
              },
              {
                path: `${PATH_PARAM_DASHBOARD.scEmployee.notifications}/${PAGE_TYPE.info}`,
                element: (
                  <PGuard
                    children={<SPEmployeeNotificationsInfoPage />}
                    permission={FIELD_PERMISSION.CAN_READ_SP_USER_NOTIFICATION_SETTINGS}
                  />
                ),
              },
              {
                path: `${PATH_PARAM_DASHBOARD.scEmployee.notifications}/${PAGE_TYPE.edit}`,
                element: (
                  <PGuard
                    children={<SPEmployeeNotificationsEditPage />}
                    permission={FIELD_PERMISSION.CAN_CHANGE_SP_USER_NOTIFICATION_SETTINGS}
                  />
                ),
              },
              { path: PATH_PARAM_DASHBOARD.scEmployee.reviews, element: <SPEmployeeReviewListPage /> },
              {
                path: `${PATH_PARAM_DASHBOARD.scEmployee.regionServices}/${PAGE_TYPE.list}`,
                element: <TechnicianRegionServiceCategoryListPage />,
              },
              {
                path: `${PATH_PARAM_DASHBOARD.scEmployee.regionServices}/${PAGE_TYPE.edit}`,
                element: <TechnicianRegionServiceCategoryEditPage />,
              },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.serviceProvider.departments}/:${PATH_PARAM_TOKEN.serviceProvider.departmentId}`,
            element: <PGuard children={<SPDepartmentWrapper />} permission={MODEL_PERMISSION.CAN_READ_SP_DEPARTMENT} />,
            children: [
              {
                path: '',
                element: <PGuard children={<SPDepartmentListPage />} permission={MODEL_PERMISSION.CAN_READ_SP_DEPARTMENT_LIST} />,
              },
              { path: PAGE_TYPE.info, element: <SPDepartmentInfoPage /> },
              {
                path: PAGE_TYPE.edit,
                element: <PGuard children={<SPDepartmentEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_SP_DEPARTMENT} />,
              },
              {
                path: PAGE_TYPE.add,
                element: <PGuard children={<SPDepartmentEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_SP_DEPARTMENT} />,
              },
            ],
          },
        ],
      },
    ];

    const widgetRoutes: RouteObject[] = [
      { path: '', element: <Navigate to={PAGE_TYPE.list} replace /> },
      {
        path: `:${PATH_PARAM_TOKEN.widget.enterpriseId}`,
        element: <WidgetMatchSettingEnterpriseDataWrapper />,
        children: [
          {
            path: '',
            element: <PGuard children={<WidgetMatchSettingEnterpriseListPage />} permission={MODEL_PERMISSION.FAKE_FORBIDDEN} />,
          },
          {
            path: `:${PATH_PARAM_TOKEN.widget.brandId}`,
            element: <WidgetMatchSettingBrandDataWrapper />,
            children: [
              {
                path: '',
                element: <PGuard children={<WidgetMatchSettingBrandListPage />} permission={MODEL_PERMISSION.FAKE_FORBIDDEN} />,
              },
              {
                path: PATH_PARAM_DASHBOARD.widget.settings,
                children: [
                  {
                    path: `${PATH_PARAM_DASHBOARD.widget.match}/${PAGE_TYPE.list}`,
                    element: <PGuard children={<WidgetMatchSettingListPage />} permission={MODEL_PERMISSION.FAKE_FORBIDDEN} />,
                  },
                  {
                    path: `${PATH_PARAM_DASHBOARD.widget.serviceVisibility}/${PAGE_TYPE.list}`,
                    element: <PGuard children={<WidgetServiceVisibilitySettingListPage />} permission={MODEL_PERMISSION.FAKE_FORBIDDEN} />,
                  },
                  {
                    path: `${PATH_PARAM_DASHBOARD.widget.serviceVisibility}/${PAGE_TYPE.edit}`,
                    element: <PGuard children={<WidgetServiceVisibilitySettingEditPage />} permission={MODEL_PERMISSION.FAKE_FORBIDDEN} />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ];
    const superadminSettingsRoutes: RouteObject[] = [
      { path: '', element: <Navigate to={`${PATH_PARAM_DASHBOARD.superadmin.apiSettings}/${PAGE_TYPE.info}`} replace /> },
      {
        path: `${PATH_PARAM_DASHBOARD.superadmin.apiSettings}/${PAGE_TYPE.info}`,
        element: <PGuard children={<SuperAdminApiSettingsInfoPage />} permission={MODEL_PERMISSION.CAN_READ_SUPERADMIN} />,
      },
    ];
    const superadminRoutes: RouteObject[] = [
      { path: '', element: <Navigate to={PAGE_TYPE.list} replace /> },
      {
        path: `:${PATH_PARAM_TOKEN.superadmin.userId}`,
        element: <PGuard children={<SuperadminDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_SUPERADMIN_LIST} />,
        children: [
          {
            path: '',
            element: <PGuard children={<SuperadminUserListPage />} permission={MODEL_PERMISSION.CAN_READ_SUPERADMIN_LIST} />,
          },
          {
            path: PAGE_TYPE.info,
            element: <PGuard children={<SuperadminUserInfoPage />} permission={MODEL_PERMISSION.CAN_READ_SUPERADMIN} />,
          },
          {
            path: PAGE_TYPE.add,
            element: <PGuard children={<SuperadminUserCreatePage />} permission={MODEL_PERMISSION.CAN_CREATE_SUPERADMIN} />,
          },
        ],
      },
    ];

    const enterpriseAccountRoutes: RouteObject[] = [
      { path: '', element: <Navigate to={PAGE_TYPE.list} replace /> },
      {
        path: `:${PATH_PARAM_TOKEN.enterpriseAccount.enterpriseId}`,
        element: <PGuard children={<EnterpriseAccountDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_LIST} />,
        children: [
          {
            path: '',
            element: <PGuard children={<EnterpriseAccountListPage />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_LIST} />,
          },
          {
            path: PAGE_TYPE.add,
            element: <PGuard children={<EnterpriseAccountCreatePage />} permission={MODEL_PERMISSION.CAN_CREATE_ENTERPRISE} />,
          },
          {
            path: `${PATH_PARAM_DASHBOARD.enterpriseAccount.employees}/:${PATH_PARAM_TOKEN.enterpriseAccount.userId}`,
            element: <PGuard children={<EnterpriseAccountUserDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_USER_LIST} />,
            children: [
              {
                path: '',
                element: (
                  <PGuard children={<EnterpriseAccountUserListPage />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_USER_LIST} />
                ),
              },
            ],
          },
        ],
      },
    ];

    const platformOperatorAccountRoutes: RouteObject[] = [
      { path: '', element: <Navigate to={PAGE_TYPE.list} replace /> },
      {
        path: `:${PATH_PARAM_TOKEN.platformOperatorAccount.platformOperatorId}`,
        element: <PGuard children={<PlatformOperatorAccountDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_PLATFORM_OPERATOR_LIST} />,
        children: [
          {
            path: '',
            element: (
              <PGuard children={<PlatformOperatorAccountListPage />} permission={MODEL_PERMISSION.CAN_READ_PLATFORM_OPERATOR_LIST} />
            ),
          },
          {
            path: PAGE_TYPE.add,
            element: <PGuard children={<PlatformOperatorAccountCreatePage />} permission={MODEL_PERMISSION.CAN_CREATE_PLATFORM_OPERATOR} />,
          },
          {
            path: `${PATH_PARAM_DASHBOARD.platformOperatorAccount.employees}/:${PATH_PARAM_TOKEN.platformOperatorAccount.userId}`,
            element: (
              <PGuard children={<PlatformOperatorAccountUserDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_USER_LIST} />
            ),
            children: [
              {
                path: '',
                element: (
                  <PGuard children={<PlatformOperatorAccountUserListPage />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_USER_LIST} />
                ),
              },
            ],
          },
        ],
      },
    ];

    const serviceProviderAccountRoutes: RouteObject[] = [
      { path: '', element: <Navigate to={PAGE_TYPE.list} replace /> },
      {
        path: `:${PATH_PARAM_TOKEN.serviceProviderAccount.serviceProviderId}`,
        element: <PGuard children={<ServiceProviderAccountDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_SERVICE_PROVIDER_LIST} />,
        children: [
          {
            path: '',
            element: <PGuard children={<ServiceProviderAccountListPage />} permission={MODEL_PERMISSION.CAN_READ_SERVICE_PROVIDER_LIST} />,
          },
          {
            path: `${PATH_PARAM_DASHBOARD.serviceProviderAccount.employees}/:${PATH_PARAM_TOKEN.serviceProviderAccount.userId}`,
            element: <PGuard children={<ServiceProviderAccountUserDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_SP_USER_LIST} />,
            children: [
              {
                path: '',
                element: <PGuard children={<ServiceProviderAccountUserListPage />} permission={MODEL_PERMISSION.CAN_READ_SP_USER_LIST} />,
              },
            ],
          },
        ],
      },
    ];

    const enterpriseRoutes: RouteObject[] = [
      { path: '', element: <EnterpriseRedirectToMyPage /> },
      {
        path: `:${PATH_PARAM_TOKEN.enterprise.enterpriseId}`,
        element: <PGuard children={<EnterpriseDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE} />,
        children: [
          {
            path: '',
            element: <PGuard children={<EnterpriseListPage />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_LIST} />,
          },
          { path: PAGE_TYPE.info, element: <EnterpriseInfoPage /> },
          {
            path: `${PATH_PARAM_DASHBOARD.enterprise.apiSettings}/${PAGE_TYPE.info}`,
            element: <PGuard children={<EnterpriseApiSettingsInfoPage />} permission={FIELD_PERMISSION.CAN_READ_ENTERPRISE_API_SETTINGS} />,
          },
          {
            path: `${PATH_PARAM_DASHBOARD.enterprise.uiConsole}/${PAGE_TYPE.info}`,
            element: <PGuard children={<EnterpriseUiConsoleInfoPage />} permission={FIELD_PERMISSION.CAN_USE_UI_CONSOLE} />,
          },
          {
            path: `${PATH_PARAM_DASHBOARD.enterprise.apiSettings}/${PAGE_TYPE.edit}`,
            element: (
              <PGuard children={<EnterpriseApiSettingsEditPage />} permission={FIELD_PERMISSION.CAN_UPDATE_ENTERPRISE_API_SETTINGS} />
            ),
          },
          {
            path: `${PATH_PARAM_DASHBOARD.enterprise.orderSettings}/${PAGE_TYPE.info}`,
            element: (
              <PGuard children={<EnterpriseOrderSettingsInfoPage />} permission={FIELD_PERMISSION.CAN_READ_ENTERPRISE_ORDER_SETTINGS} />
            ),
          },
          {
            path: `${PATH_PARAM_DASHBOARD.enterprise.orderSettings}/${PAGE_TYPE.edit}`,
            element: (
              <PGuard children={<EnterpriseOrderSettingsEditPage />} permission={FIELD_PERMISSION.CAN_UPDATE_ENTERPRISE_ORDER_SETTINGS} />
            ),
          },
          {
            path: `${PATH_PARAM_DASHBOARD.enterprise.technicianSettings}/${PAGE_TYPE.edit}`,
            element: (
              <PGuard
                children={<EnterpriseTechnicianSettingsEditPage />}
                permission={FIELD_PERMISSION.CAN_UPDATE_ENTERPRISE_TECHNICIAN_SETTINGS}
              />
            ),
          },
          {
            path: PAGE_TYPE.edit,
            element: <PGuard children={<EnterpriseEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_ENTERPRISE} />,
          },
          {
            path: PAGE_TYPE.add,
            element: <PGuard children={<EnterpriseEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_ENTERPRISE} />,
          },
          {
            path: `${PATH_PARAM_DASHBOARD.enterprise.orderCustomFields}/:${PATH_PARAM_TOKEN.enterprise.customFieldId}`,
            element: <EnterpriseOrderCustomFieldLoaderFix />,
            children: [
              {
                path: '',
                element: (
                  <PGuard
                    children={<EnterpriseOrderCustomFieldListPage />}
                    permission={FIELD_PERMISSION.CAN_READ_ENTERPRISE_ORDER_CUSTOM_FIELD_SETTINGS}
                  />
                ),
              },
              {
                path: PAGE_TYPE.info,
                element: (
                  <PGuard
                    children={<EnterpriseOrderCustomFieldInfoPage />}
                    permission={FIELD_PERMISSION.CAN_READ_ENTERPRISE_ORDER_CUSTOM_FIELD_SETTINGS}
                  />
                ),
              },
              {
                path: PAGE_TYPE.add,
                element: (
                  <PGuard
                    children={<EnterpriseOrderCustomFieldEditPage />}
                    permission={FIELD_PERMISSION.CAN_CREATE_ENTERPRISE_ORDER_CUSTOM_FIELD_SETTINGS}
                  />
                ),
              },
              {
                path: PAGE_TYPE.edit,
                element: (
                  <PGuard
                    children={<EnterpriseOrderCustomFieldEditPage />}
                    permission={FIELD_PERMISSION.CAN_UPDATE_ENTERPRISE_ORDER_CUSTOM_FIELD_SETTINGS}
                  />
                ),
              },
            ],
          },
          {
            path: PATH_PARAM_DASHBOARD.enterprise.serviceCompanies,
            element: <EnterpriseServiceProviderDataWrapper />,
            children: [
              {
                path: PAGE_TYPE.list,
                element: <PGuard children={<EnterpriseServiceProviderListPage />} permission={MODEL_PERMISSION.FAKE_FORBIDDEN} />,
              },
              {
                path: PAGE_TYPE.edit,
                element: <PGuard children={<EnterpriseServiceCompaniesEditPage />} permission={MODEL_PERMISSION.FAKE_FORBIDDEN} />,
              },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.enterprise.brands}/:${PATH_PARAM_TOKEN.enterprise.brandId}`,
            element: <PGuard children={<BrandDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_BRAND} />,
            children: [
              {
                path: '',
                element: <PGuard children={<BrandListPage />} permission={MODEL_PERMISSION.CAN_READ_BRAND_LIST} />,
              },
              { path: PAGE_TYPE.info, element: <BrandInfoPage /> },
              {
                path: PAGE_TYPE.edit,
                element: <PGuard children={<BrandEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_BRAND} />,
              },
              {
                path: PAGE_TYPE.add,
                element: <PGuard children={<BrandEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_BRAND} />,
              },
              // { path: `${PATH_DASHBOARD_LOCAL.brand.telephony}/${PAGE_TYPE.list}`, element: <BrandTelephonyListPage /> },
              {
                path: `${PATH_PARAM_DASHBOARD.brand.clientsApp}/${PAGE_TYPE.info}`,
                element: <BrandClientsAppSettingsInfoPage />,
              },
              {
                path: `${PATH_PARAM_DASHBOARD.brand.clientsApp}/${PAGE_TYPE.edit}`,
                element: <PGuard children={<BrandClientsAppSettingsEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_BRAND} />,
              },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.enterprise.legalEntities}/:${PATH_PARAM_TOKEN.enterprise.legalEntityId}`,
            element: (
              <PGuard children={<EnterpriseLegalEntityDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_LEGAL_ENTITY} />
            ),
            children: [
              {
                path: '',
                element: (
                  <PGuard
                    children={<EnterpriseLegalEntityListPage />}
                    permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_LEGAL_ENTITY_LIST}
                  />
                ),
              },
              { path: PAGE_TYPE.info, element: <EnterpriseLegalEntityInfoPage /> },
              {
                path: PAGE_TYPE.edit,
                element: (
                  <PGuard children={<EnterpriseLegalEntityEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_ENTERPRISE_LEGAL_ENTITY} />
                ),
              },
              {
                path: PAGE_TYPE.add,
                element: (
                  <PGuard children={<EnterpriseLegalEntityEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_ENTERPRISE_LEGAL_ENTITY} />
                ),
              },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.enterprise.departments}/:${PATH_PARAM_TOKEN.enterprise.departmentId}`,
            element: <PGuard children={<EnterpriseDepartmentDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_DEPARTMENT} />,
            children: [
              {
                path: '',
                element: (
                  <PGuard children={<EnterpriseDepartmentListPage />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_DEPARTMENT_LIST} />
                ),
              },
              { path: PAGE_TYPE.info, element: <EnterpriseDepartmentInfoPage /> },
              {
                path: PAGE_TYPE.edit,
                element: (
                  <PGuard children={<EnterpriseDepartmentEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_ENTERPRISE_DEPARTMENT} />
                ),
              },
              {
                path: PAGE_TYPE.add,
                element: (
                  <PGuard children={<EnterpriseDepartmentEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_ENTERPRISE_DEPARTMENT} />
                ),
              },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.enterprise.employees}/:${PATH_PARAM_TOKEN.enterprise.userId}`,
            element: <PGuard children={<EnterpriseEmployeeDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_USER} />,
            children: [
              {
                path: '',
                element: <PGuard children={<EnterpriseEmployeeListPage />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_USER_LIST} />,
              },
              { path: PAGE_TYPE.info, element: <EnterpriseEmployeeInfoPage /> },
              {
                path: PAGE_TYPE.edit,
                element: <PGuard children={<EnterpriseEmployeeEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_ENTERPRISE_USER} />,
              },
              {
                path: PAGE_TYPE.add,
                element: <PGuard children={<EnterpriseEmployeeEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_ENTERPRISE_USER} />,
              },
              {
                path: `${PATH_PARAM_DASHBOARD.enterpriseEmployee.notifications}/${PAGE_TYPE.info}`,
                element: (
                  <PGuard
                    children={<EnterpriseEmployeeNotificationsInfoPage />}
                    permission={FIELD_PERMISSION.CAN_READ_ENTERPRISE_USER_NOTIFICATION_SETTINGS}
                  />
                ),
              },
              {
                path: `${PATH_PARAM_DASHBOARD.enterpriseEmployee.notifications}/${PAGE_TYPE.edit}`,
                element: (
                  <PGuard
                    children={<EnterpriseEmployeeNotificationsEditPage />}
                    permission={FIELD_PERMISSION.CAN_CHANGE_ENTERPRISE_USER_NOTIFICATION_SETTINGS}
                  />
                ),
              },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.enterprise.technicians}/:${PATH_PARAM_TOKEN.enterprise.technicianId}`,
            element: <PGuard children={<EnterpriseTechnicianDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_TECHNICIAN} />,
            children: [
              {
                path: '',
                element: (
                  <PGuard children={<EnterpriseTechnicianListPage />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_TECHNICIAN_LIST} />
                ),
              },
              { path: PAGE_TYPE.info, element: <EnterpriseTechnicianInfoPage /> },
              {
                path: PAGE_TYPE.edit,
                element: (
                  <PGuard children={<EnterpriseTechnicianEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_ENTERPRISE_TECHNICIAN} />
                ),
              },
              {
                path: PAGE_TYPE.add,
                element: (
                  <PGuard children={<EnterpriseTechnicianEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_ENTERPRISE_TECHNICIAN} />
                ),
              },
              {
                path: `${PATH_PARAM_DASHBOARD.enterpriseEmployee.legalEntity}/${PAGE_TYPE.info}`,
                element: <EnterpriseTechnicianLegalEntityInfoPage />,
              },
              {
                path: `${PATH_PARAM_DASHBOARD.enterpriseEmployee.legalEntity}/${PAGE_TYPE.edit}`,
                element: <EnterpriseTechnicianLegalEntityEditPage />,
              },
              {
                path: `${PATH_PARAM_DASHBOARD.scEmployee.regionServices}/${PAGE_TYPE.list}`,
                element: <EnterpriseTechnicianRegionServiceCategoryListPage />,
              },
              {
                path: `${PATH_PARAM_DASHBOARD.scEmployee.regionServices}/${PAGE_TYPE.edit}`,
                element: <EnterpriseTechnicianRegionServiceCategoryEditPage />,
              },
              {
                path: `${PATH_PARAM_DASHBOARD.user.notifications}/${PAGE_TYPE.info}`,
                element: <EnterpriseTechnicianNotificationsInfoPage />,
              },
              {
                path: `${PATH_PARAM_DASHBOARD.user.notifications}/${PAGE_TYPE.edit}`,
                element: (
                  <PGuard
                    children={<EnterpriseTechnicianNotificationsEditPage />}
                    permission={MODEL_PERMISSION.CAN_UPDATE_ENTERPRISE_TECHNICIAN}
                  />
                ),
              },
              {
                path: PATH_PARAM_DASHBOARD.scEmployee.reviews,
                element: (
                  <PGuard
                    children={<EnterpriseTechnicianReviewListPage />}
                    permission={FIELD_PERMISSION.CAN_READ_ENTERPRISE_TECHNICIAN_REVIEW_LIST}
                  />
                ),
              },
              // {
              //   path: `${PATH_PARAM_DASHBOARD.scEmployee.categories}/${PAGE_TYPE.info}`,
              //   element: (
              //     <PGuard children={<EnterpriseTechnicianServiceCategoryListPage />} permission={MODEL_PERMISSION.FAKE_FORBIDDEN} />
              //   ),
              // },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.enterprise.regions}/:${PATH_PARAM_TOKEN.enterprise.regionId}`,
            element: <PGuard children={<EnterpriseRegionDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_REGION} />,
            children: [
              {
                path: '',
                element: <PGuard children={<EnterpriseRegionListPage />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_REGION_LIST} />,
              },
              {
                path: PAGE_TYPE.add,
                element: <PGuard children={<EnterpriseRegionEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_ENTERPRISE_REGION} />,
              },
              {
                path: PAGE_TYPE.edit,
                element: <PGuard children={<EnterpriseRegionEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_ENTERPRISE_REGION} />,
              },
              { path: PAGE_TYPE.info, element: <EnterpriseRegionInfoPage /> },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.enterprise.shops}/:${PATH_PARAM_TOKEN.enterprise.shopId}`,
            element: <PGuard children={<EnterpriseShopDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_SHOP} />,
            children: [
              {
                path: '',
                element: <PGuard children={<EnterpriseShopListPage />} permission={MODEL_PERMISSION.CAN_READ_SHOP_LIST} />,
              },
              {
                path: PAGE_TYPE.add,
                element: <PGuard children={<EnterpriseShopEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_SHOP} />,
              },
              {
                path: PAGE_TYPE.edit,
                element: <PGuard children={<EnterpriseShopEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_SHOP} />,
              },
              { path: PAGE_TYPE.info, element: <EnterpriseShopInfoPage /> },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.enterprise.technicianTeams}/:${PATH_PARAM_TOKEN.enterprise.technicianTeamId}`,
            element: (
              <PGuard
                children={<EnterpriseTechnicianTeamDataWrapper />}
                permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_TECHNICIAN_TEAM}
              />
            ),
            children: [
              {
                path: '',
                element: (
                  <PGuard
                    children={<EnterpriseTechnicianTeamListPage />}
                    permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_TECHNICIAN_TEAM_LIST}
                  />
                ),
              },
              {
                path: PAGE_TYPE.info,
                element: <EnterpriseTechnicianTeamInfoPage />,
              },
              {
                path: PAGE_TYPE.add,
                element: (
                  <PGuard
                    children={<EnterpriseTechnicianTeamEditPage />}
                    permission={MODEL_PERMISSION.CAN_CREATE_ENTERPRISE_TECHNICIAN_TEAM}
                  />
                ),
              },
              {
                path: PAGE_TYPE.edit,
                element: (
                  <PGuard
                    children={<EnterpriseTechnicianTeamEditPage />}
                    permission={MODEL_PERMISSION.CAN_UPDATE_ENTERPRISE_TECHNICIAN_TEAM}
                  />
                ),
              },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.enterprise.servicePackages}/:${PATH_PARAM_TOKEN.enterprise.servicePackageId}`,
            element: <PGuard children={<EnterpriseServicePackageDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_SERVICE_PACKAGE} />,
            children: [
              {
                path: '',
                element: <EnterpriseServicePackageListPage />,
              },
              {
                path: PAGE_TYPE.info,
                element: <EnterpriseServicePackageInfoPage />,
              },
              {
                path: PAGE_TYPE.edit,
                element: (
                  <PGuard children={<EnterpriseServicePackageEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_SERVICE_PACKAGE} />
                ),
              },
              {
                path: PAGE_TYPE.add,
                element: (
                  <PGuard children={<EnterpriseServicePackageEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_SERVICE_PACKAGE} />
                ),
              },
            ],
          },
          {
            path: PATH_PARAM_DASHBOARD.enterprise.services,
            children: [
              {
                path: '',
                element: <PGuard children={<EnterpriseServiceEnterpriseListPage />} permission={MODEL_PERMISSION.FAKE_ALLOWED} />,
              },
              {
                path: `:${PATH_PARAM_TOKEN.enterprise.serviceCategoryGroupId}`,
                element: (
                  <PGuard
                    children={<EnterpriseServiceCategoryGroupDataWrapper />}
                    permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_SERVICE_GROUP}
                  />
                ),
                children: [
                  {
                    path: '',
                    element: (
                      <PGuard
                        children={<EnterpriseServiceCategoryGroupListPage />}
                        permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_SERVICE_GROUP_LIST}
                      />
                    ),
                  },
                  {
                    path: PAGE_TYPE.add,
                    element: (
                      <PGuard
                        children={<EnterpriseServiceCategoryGroupEditPage />}
                        permission={MODEL_PERMISSION.CAN_CREATE_ENTERPRISE_SERVICE_GROUP}
                      />
                    ),
                  },
                  {
                    path: PAGE_TYPE.edit,
                    element: (
                      <PGuard
                        children={<EnterpriseServiceCategoryGroupEditPage />}
                        permission={MODEL_PERMISSION.CAN_UPDATE_ENTERPRISE_SERVICE_GROUP}
                      />
                    ),
                  },
                  {
                    path: `:${PATH_PARAM_TOKEN.enterprise.serviceCategoryId}`,
                    element: (
                      <PGuard
                        children={<EnterpriseServiceCategoryDataWrapper />}
                        permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_SERVICE_CATEGORY}
                      />
                    ),
                    children: [
                      {
                        path: '',
                        element: (
                          <PGuard
                            children={<EnterpriseServiceCategoryListPage />}
                            permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_SERVICE_CATEGORY_LIST}
                          />
                        ),
                      },
                      {
                        path: PAGE_TYPE.add,
                        element: (
                          <PGuard
                            children={<EnterpriseServiceCategoryEditPage />}
                            permission={MODEL_PERMISSION.CAN_CREATE_ENTERPRISE_SERVICE_CATEGORY}
                          />
                        ),
                      },
                      {
                        path: PAGE_TYPE.edit,
                        element: (
                          <PGuard
                            children={<EnterpriseServiceCategoryEditPage />}
                            permission={MODEL_PERMISSION.CAN_UPDATE_ENTERPRISE_SERVICE_CATEGORY}
                          />
                        ),
                      },
                      {
                        path: `:${PATH_PARAM_TOKEN.enterprise.serviceId}`,
                        element: (
                          <PGuard children={<EnterpriseServiceDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_SERVICE} />
                        ),
                        children: [
                          {
                            path: '',
                            element: (
                              <PGuard
                                children={<EnterpriseServiceListPage />}
                                permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_SERVICE_LIST}
                              />
                            ),
                          },
                          {
                            path: PAGE_TYPE.add,
                            element: (
                              <PGuard
                                children={<EnterpriseServiceEditPage />}
                                permission={MODEL_PERMISSION.CAN_CREATE_ENTERPRISE_SERVICE}
                              />
                            ),
                          },
                          {
                            path: PAGE_TYPE.edit,
                            element: (
                              <PGuard
                                children={<EnterpriseServiceEditPage />}
                                permission={MODEL_PERMISSION.CAN_UPDATE_ENTERPRISE_SERVICE}
                              />
                            ),
                          },
                          { path: PAGE_TYPE.info, element: <EnterpriseServiceInfoPage /> },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: ``,
            element: (
              <PGuard
                children={<EnterpriseEmployeeDataWrapper />}
                permission={FIELD_PERMISSION.CAN_READ_ENTERPRISE_USER_NOTIFICATION_SETTINGS}
              />
            ),
            children: [
              {
                path: `${PATH_PARAM_DASHBOARD.user.notifications}/${PAGE_TYPE.info}`,
                element: (
                  <PGuard
                    children={<EnterpriseGlobalNotificationsInfoPage />}
                    permission={FIELD_PERMISSION.CAN_READ_ENTERPRISE_USER_NOTIFICATION_SETTINGS}
                  />
                ),
              },
              {
                path: `${PATH_PARAM_DASHBOARD.user.notifications}/${PAGE_TYPE.edit}`,
                element: (
                  <PGuard
                    children={<EnterpriseGlobalNotificationsEditPage />}
                    permission={FIELD_PERMISSION.CAN_CHANGE_ENTERPRISE_USER_NOTIFICATION_SETTINGS}
                  />
                ),
              },
            ],
          },
        ],
      },
    ];

    const meRoutes: RouteObject[] = [
      { path: '', element: <Navigate to={PAGE_TYPE.info} replace /> },
      { path: PAGE_TYPE.info, element: <CurrentUserInfoPage /> },
      { path: PAGE_TYPE.edit, element: <CurrentUserEditPage /> },
      {
        path: `${PATH_PARAM_DASHBOARD.user.notifications}/${PAGE_TYPE.info}`,
        element: <CurrentUserNotificationInfoPage />,
      },
      {
        path: `${PATH_PARAM_DASHBOARD.user.notifications}/${PAGE_TYPE.edit}`,
        element: <CurrentUserNotificationEditPage />,
      },
      {
        path: PATH_PARAM_DASHBOARD.scEmployee.reviews,
        element: <MeAsTechnicianReviewListPage />,
      },
      {
        path: `${PATH_PARAM_DASHBOARD.enterpriseEmployee.legalEntity}/${PAGE_TYPE.info}`,
        element: <MeAsEnterpriseTechnicianLegalEntityInfoPage />,
      },
      {
        path: `${PATH_PARAM_DASHBOARD.enterpriseEmployee.legalEntity}/${PAGE_TYPE.edit}`,
        element: <MeAsEnterpriseTechnicianLegalEntityEditPage />,
      },
      {
        path: `${PATH_PARAM_DASHBOARD.scEmployee.regionServices}/${PAGE_TYPE.list}`,
        element: <MeAsEnterpriseTechnicianRegionServiceCategoryListPage />,
      },
      {
        path: `${PATH_PARAM_DASHBOARD.scEmployee.regionServices}/${PAGE_TYPE.edit}`,
        element: <MeAsEnterpriseTechnicianRegionServiceCategoryEditPage />,
      },
    ];

    const serviceRoutes: RouteObject[] = [
      { path: '', element: <ServiceEnterpriseRedirectToMyPage /> },
      {
        path: `:${PATH_PARAM_TOKEN.service.enterpriseId}`,
        element: <ServiceListDataWrapper />,
        children: [
          {
            path: `${PAGE_TYPE.list}`,
            element: <PGuard children={<ServiceListPage />} permission={MODEL_PERMISSION.CAN_READ_ENTERPRISE_SERVICE} />,
            children: [
              {
                path: `:${PATH_PARAM_TOKEN.service.serviceId}`,
                element: <ServiceDataWrappper />,
                children: [
                  {
                    path: PAGE_TYPE.add,
                    element: <PGuard children={<ServiceAddPage />} permission={MODEL_PERMISSION.CAN_CREATE_ENTERPRISE_SERVICE} />,
                  },
                  {
                    path: PAGE_TYPE.edit,
                    element: <PGuard children={<ServiceEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_ENTERPRISE_SERVICE} />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ];

    const recommendationRoutes: RouteObject[] = [
      { path: '', element: <Navigate to={`${PATH_PARAM_DASHBOARD.recommendationConfig.rules}/${PAGE_TYPE.list}`} replace /> },
      {
        path: '',
        element: <PGuard children={<RecommendationsConfigDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_RECOMMENDATIONS} />,
        children: [
          {
            path: `${PATH_PARAM_DASHBOARD.recommendationConfig.rules}/:${PATH_PARAM_TOKEN.recommendationConfig.ruleId}`,
            children: [
              { path: '', element: <PGuard children={<RulesListPage />} permission={MODEL_PERMISSION.CAN_READ_RECOMMENDATIONS} /> },
              {
                path: PAGE_TYPE.edit,
                element: <PGuard children={<PromotionsEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_RECOMMENDATIONS} />,
              },
              {
                path: PAGE_TYPE.add,
                element: <PGuard children={<PromotionsEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_RECOMMENDATIONS} />,
              },
              {
                path: PAGE_TYPE.info,
                element: <PGuard children={<PromotionsInfoPage />} permission={MODEL_PERMISSION.CAN_READ_RECOMMENDATIONS} />,
              },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.recommendationConfig.variables}/${PAGE_TYPE.edit}`,
            element: <PGuard children={<RecommendationVariablesEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_RECOMMENDATIONS} />,
          },
        ],
      },
    ];

    const promotionsRoutes: RouteObject[] = [
      { path: '', element: <Navigate to={`${PATH_PARAM_DASHBOARD.promotionsConfig.promotions}/${PAGE_TYPE.list}`} replace /> },
      {
        path: '',
        element: <PGuard children={<PromotionsConfigDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_PROMOTIONS} />,
        children: [
          {
            path: `${PATH_PARAM_DASHBOARD.promotionsConfig.promotions}/:${PATH_PARAM_TOKEN.promotionsConfig.promotionId}`,
            children: [
              { path: '', element: <PGuard children={<PromotionsListPage />} permission={MODEL_PERMISSION.CAN_READ_PROMOTIONS} /> },
              {
                path: PAGE_TYPE.edit,
                element: <PGuard children={<PromotionsEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_PROMOTIONS} />,
              },
              {
                path: PAGE_TYPE.add,
                element: <PGuard children={<PromotionsEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_PROMOTIONS} />,
              },
              {
                path: PAGE_TYPE.info,
                element: <PGuard children={<PromotionsInfoPage />} permission={MODEL_PERMISSION.CAN_READ_PROMOTIONS} />,
              },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.promotionsConfig.categories}/:${PATH_PARAM_TOKEN.promotionsConfig.categoriesId}`,
            children: [
              { path: '', element: <PGuard children={<CategoriesListPage />} permission={MODEL_PERMISSION.CAN_READ_PROMOTIONS} /> },
              {
                path: PAGE_TYPE.edit,
                element: <PGuard children={<CategoriesEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_PROMOTIONS} />,
              },
              {
                path: PAGE_TYPE.add,
                element: <PGuard children={<CategoriesEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_PROMOTIONS} />,
              },
              {
                path: PAGE_TYPE.info,
                element: <PGuard children={<CategoriesInfoPage />} permission={MODEL_PERMISSION.CAN_READ_PROMOTIONS} />,
              },
            ],
          },
          {
            path: PATH_PARAM_DASHBOARD.promotionsConfig.aggregation,
            children: [
              {
                path: PAGE_TYPE.info,
                element: <PGuard children={<AggregationInfoPage />} permission={MODEL_PERMISSION.CAN_READ_PROMOTIONS} />,
              },
              {
                path: PAGE_TYPE.edit,
                element: <PGuard children={<AggregationEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_PROMOTIONS} />,
              },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.promotionsConfig.variables}/${PAGE_TYPE.edit}`,
            element: <PGuard children={<VariablesEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_PROMOTIONS} />,
          },
        ],
      },
    ];

    const ArchiveRoutes: RouteObject[] = [
      {
        path: `:${PATH_PARAM_TOKEN.archive.archiveYear}/:${PATH_PARAM_TOKEN.archive.orderViewTechName}/:${PATH_PARAM_TOKEN.archive.orderId}`,
        element: <PGuard children={<ArchiveRootWrapper />} permission={MODEL_PERMISSION.CAN_READ_ORDER} />,
        children: [
          // { path: '', element: <OrderInfoPage /> }, // кейс дефолтной страницы обрабатывается в OrderBlockLayout, т.к. там есть бизнес требования
          {
            path: PATH_PARAM_DASHBOARD.order.main,
            children: [{ path: PAGE_TYPE.info, element: <OrderInfoPage /> }],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.order.services}/:${PATH_PARAM_TOKEN.order.serviceId}`,
            children: [
              {
                path: '',
                element: <PGuard children={<OrderServiceListPage />} permission={MODEL_PERMISSION.CAN_READ_ORDER_SERVICE_LIST} />,
              },
              {
                path: PAGE_TYPE.edit,
                element: <PGuard children={<OrderServiceEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_ORDER_SERVICE} />,
              },
              {
                path: PAGE_TYPE.add,
                element: <PGuard children={<OrderServiceEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_ORDER_SERVICE} />,
              },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.order.providerTransactions}/:${PATH_PARAM_TOKEN.order.providerTransactionId}`,
            children: [
              {
                path: '',
                element: (
                  <PGuard children={<OrderProviderTransactionsListPage />} permission={MODEL_PERMISSION.CAN_READ_ORDER_TRANSACTION_LIST} />
                ),
              },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.order.report}/:${PATH_PARAM_TOKEN.order.reportId}`,
            element: <PGuard children={<OrderReportDataWrapper />} permission={MODEL_PERMISSION.CAN_READ_ORDER_REPORT} />,
            children: [
              {
                path: '',
                element: <PGuard children={<OrderReportListPage />} permission={MODEL_PERMISSION.CAN_READ_ORDER_REPORT_LIST} />,
              },
              {
                path: PAGE_TYPE.add,
                element: <PGuard children={<OrderReportEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_ORDER_REPORT} />,
              },
              {
                path: PAGE_TYPE.edit,
                element: <PGuard children={<OrderReportEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_ORDER_REPORT} />,
              },
            ],
          },
          {
            path: PATH_PARAM_DASHBOARD.order.review,
            children: [
              {
                path: PAGE_TYPE.info,
                element: <PGuard children={<OrderReviewInfoPage />} permission={MODEL_PERMISSION.CAN_READ_ORDER_REVIEW} />,
              },
              {
                path: PAGE_TYPE.edit,
                element: <PGuard children={<OrderReviewEditPage />} permission={MODEL_PERMISSION.CAN_UPDATE_ORDER_REVIEW} />,
              },
              {
                path: PAGE_TYPE.add,
                element: <PGuard children={<OrderReviewEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_ORDER_REVIEW} />,
              },
            ],
          },
          {
            path: PATH_PARAM_DASHBOARD.order.history,
            children: [
              {
                path: PAGE_TYPE.list,
                element: <PGuard children={<OrderHistoryListPage />} permission={MODEL_PERMISSION.CAN_READ_ORDER_HISTORY_LIST} />,
              },
            ],
          },
          {
            path: PATH_PARAM_DASHBOARD.order.goodInformation,
            children: [
              {
                path: PAGE_TYPE.list,
                element: <OrderGoodInformationListPage />,
              },
            ],
          },
          {
            path: PATH_PARAM_DASHBOARD.order.comments,
            children: [
              {
                path: PAGE_TYPE.list,
                element: <PGuard children={<OrderCommentListPage />} permission={MODEL_PERMISSION.CAN_READ_ORDER_COMMENT_LIST} />,
              },
            ],
          },
          {
            path: PATH_PARAM_DASHBOARD.order.documents,
            children: [
              {
                path: PAGE_TYPE.list,
                element: <PGuard children={<OrderDocumentListPage />} permission={MODEL_PERMISSION.CAN_READ_ORDER} />,
              },
            ],
          },
          {
            path: `${PATH_PARAM_DASHBOARD.order.tasks}/:${PATH_PARAM_TOKEN.order.taskId}`,
            children: [
              {
                path: '',
                element: <PGuard children={<OrderTaskListPage />} permission={MODEL_PERMISSION.CAN_READ_ORDER_TASK_LIST} />,
              },
              {
                path: PAGE_TYPE.add,
                element: <PGuard children={<OrderTaskEditPage />} permission={MODEL_PERMISSION.CAN_CREATE_ORDER_TASK} />,
              },
              {
                path: PAGE_TYPE.edit,
                element: (
                  <BooleanGuard children={<OrderTaskEditPage />} isAllowed={permissions.can(MODEL_PERMISSION.CAN_UPDATE_ORDER_TASK)} />
                ),
              },
            ],
          },
        ],
      },
    ];

    return [
      {
        path: '',
        children: [{ path: '', element: <Navigate to={PATH_PARAM.dashboard.root} replace /> }],
      },
      // Auth routes
      {
        path: PATH_PARAM.auth.root,
        children: authRoutes,
      },
      // Client routes
      {
        path: PATH_PARAM.client.root,
        children: clientRoutes,
      },
      // Order universal link
      {
        path: `${PATH_PARAM_DASHBOARD.order.root}/:${PATH_PARAM_TOKEN.order.orderId}`,
        element: <OrderUniversalRedirectPage />,
      },
      // Dashboard Routes
      {
        path: PATH_PARAM.dashboard.root,
        element: (
          <AuthGuard>
            <UserTenantSettingsGlobalGuard>
              <DashboardRootLayout />
            </UserTenantSettingsGlobalGuard>
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: <CanReadOrderRedirectPage />,
          },
          {
            path: PATH_PARAM_DASHBOARD.price.root,
            children: priceRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.order.root,
            element: <DashboardSectionLayout hideTitleBlock={true} title={translate('menus.main.orders')} />,
            children: orderRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.calendar.root,
            element: <DashboardSectionLayout hideTitleBlock={true} title={translate('menus.main.calendar')} />,
            children: calendarRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.contractSP.root,
            element: <DashboardSectionLayout title={translate('pages.contractSPList.title')} />,
            children: contractSPRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.contractPO.root,
            element: (
              <DashboardSectionLayout
                title={
                  currentUser?.tenant.type === TENANT_TYPE.platformOperator
                    ? translate('pages.contractPOList.title')
                    : translate('pages.contractPOList.titleForEnterprise')
                }
              />
            ),
            children: contractPORoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.invoiceProvider.root,
            element: <DashboardSectionLayout title={translate('pages.invoiceList.title')} />,
            children: invoiceProviderRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.invoicePO.root,
            element: <DashboardSectionLayout title={translate('pages.invoiceListPO.title')} />,
            children: invoicePORoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.serviceProvider.root,
            element: <DashboardSectionLayout title={translate('menus.main.serviceCompanies')} />,
            children: serviceProviderRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.widget.root,
            element: <DashboardSectionLayout title={translate('menus.main.widgetSettings')} />,
            children: widgetRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.superadmin.settings,
            element: <DashboardSectionLayout title={translate('menus.main.settings')} />,
            children: superadminSettingsRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.superadmin.root,
            element: <DashboardSectionLayout title={translate('menus.main.superadmin')} />,
            children: superadminRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.enterpriseAccount.root,
            element: <DashboardSectionLayout title={translate('menus.main.enterprises')} />,
            children: enterpriseAccountRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.platformOperatorAccount.root,
            element: <DashboardSectionLayout title={translate('menus.main.platformOperators')} />,
            children: platformOperatorAccountRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.serviceProviderAccount.root,
            element: <DashboardSectionLayout title={translate('menus.main.serviceCompanies')} />,
            children: serviceProviderAccountRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.enterprise.root,
            element: <DashboardSectionLayout title={translate('menus.main.enterprises')} />,
            children: enterpriseRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.me.root,
            element: (
              <DashboardSectionLayout title={translate('pages.meInfo.title')}>
                <CurrentUserWrapper />
              </DashboardSectionLayout>
            ),
            children: meRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.service.root,
            element: <DashboardSectionLayout title={translate('menus.main.services')} />,
            children: serviceRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.fundsTransaction.root,
            element: <DashboardSectionLayout title={translate('menus.main.fundsTransaction')} />,
            children: fundsTransactionRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.promotionsConfig.root,
            element: <DashboardSectionLayout title={translate('menus.main.promotions')} />,
            children: promotionsRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.recommendationConfig.root,
            element: <DashboardSectionLayout title={translate('menus.main.recommendations')} />,
            children: recommendationRoutes,
          },
          {
            path: PATH_PARAM_DASHBOARD.archive.root,
            element: <DashboardSectionLayout hideTitleBlock={true} title={translate('menus.main.archive')} />,
            children: ArchiveRoutes,
          },
        ],
      },
      {
        path: '*',
        children: [
          { path: 'maintenance', element: <Maintenance /> },
          { path: '404', element: <NotFound /> },
          { path: '*', element: <NotFound /> },
        ],
      },
    ];
  }, []);

  return useRoutes(routes);
}

// LAZY IMPORT PAGES

// Order
const OrderAddPage = Loadable(lazy(() => import('pages/dashboard/order/order/OrderAddPage')));
const OrderInfoPage = Loadable(lazy(() => import('pages/dashboard/order/order/OrderInfoPage')));
const OrderServiceListPage = Loadable(lazy(() => import('pages/dashboard/order/service/OrderServiceListPage')));
const OrderReportDataWrapper = Loadable(lazy(() => import('pages/dashboard/order/report/OrderReportDataWrapper')));
const OrderReportListPage = Loadable(lazy(() => import('pages/dashboard/order/report/OrderReportListPage')));
const OrderReportEditPage = Loadable(lazy(() => import('pages/dashboard/order/report/OrderReportEditPage')));
const OrderReviewInfoPage = Loadable(lazy(() => import('pages/dashboard/order/review/OrderReviewInfoPage')));
const OrderReviewEditPage = Loadable(lazy(() => import('pages/dashboard/order/review/OrderReviewEditPage')));
const OrderHistoryListPage = Loadable(lazy(() => import('pages/dashboard/order/history/OrderHistoryListPage')));
const OrderGoodInformationListPage = Loadable(lazy(() => import('pages/dashboard/order/goodInformation/OrderGoodInformationtListPage')));
const OrderCommentListPage = Loadable(lazy(() => import('pages/dashboard/order/comment/OrderCommentListPage')));
const OrderDocumentListPage = Loadable(lazy(() => import('pages/dashboard/order/document/OrderDocumentListPage')));
const OrderTaskListPage = Loadable(lazy(() => import('pages/dashboard/order/task/OrderTaskListPage')));
const OrderTaskEditPage = Loadable(lazy(() => import('pages/dashboard/order/task/OrderTaskEditPage')));
const OrderServiceEditPage = Loadable(lazy(() => import('pages/dashboard/order/service/OrderServiceEditPage')));
const OrderRootWrapper = Loadable(lazy(() => import('pages/dashboard/order/OrderRootWrapper')));
const OrderProviderTransactionsListPage = Loadable(
  lazy(() => import('pages/dashboard/order/providerTransactions/OrderProviderTransactionsListPage'))
);
const OrderAddLinkedPage = Loadable(lazy(() => import('pages/dashboard/order/order/OrderAddLinkedPage')));
const OrderAddComplaintPage = Loadable(lazy(() => import('pages/dashboard/order/order/OrderAddComplaintPage')));
const OrderAddSessionPage = Loadable(lazy(() => import('pages/dashboard/order/order/sessionPages/OrderAddSessionPage')));

// Archive
const ArchiveRootWrapper = Loadable(lazy(() => import('pages/dashboard/archive/ArchiveRootWrapper')));

// Calendar
const CalendarRootWrapper = Loadable(lazy(() => import('pages/dashboard/calendar/CalendarRootWrapper')));
const CalendarBlock = Loadable(lazy(() => import('pages/dashboard/calendar/calendar/CalendarBlock')));
const CrossInterfaceOrderWrapper = LoadableBackground(lazy(() => import('pages/dashboard/calendar/order/OrderWrapper')));

// UserSuperadmin
const SuperadminDataWrapper = Loadable(lazy(() => import('pages/dashboard/superadmin/SuperadminDataWrapper')));
const SuperadminUserListPage = Loadable(lazy(() => import('pages/dashboard/superadmin/users/SuperadminUserListPage')));
const SuperadminUserInfoPage = Loadable(lazy(() => import('pages/dashboard/superadmin/users/SuperadminUserInfoPage')));
const SuperadminUserCreatePage = Loadable(lazy(() => import('pages/dashboard/superadmin/users/SuperadminUserCreatePage')));

// EnterpriseAccount
const EnterpriseAccountDataWrapper = Loadable(lazy(() => import('pages/dashboard/enterpriseAccount/EnterpriseAccountDataWrapper')));
const EnterpriseAccountListPage = Loadable(lazy(() => import('pages/dashboard/enterpriseAccount/EnterpriseAccountListPage')));
const EnterpriseAccountCreatePage = Loadable(lazy(() => import('pages/dashboard/enterpriseAccount/EnterpriseAccountCreatePage')));
const EnterpriseAccountUserDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/enterpriseAccount/employee/EnterpriseAccountUserDataWrapper'))
);
const EnterpriseAccountUserListPage = Loadable(
  lazy(() => import('pages/dashboard/enterpriseAccount/employee/EnterpriseAccountUserListPage'))
);

// EnterpriseAccount
const PlatformOperatorAccountDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/platformOperatorAccount/PlatformOperatorAccountDataWrapper'))
);
const PlatformOperatorAccountListPage = Loadable(
  lazy(() => import('pages/dashboard/platformOperatorAccount/PlatformOperatorAccountListPage'))
);
const PlatformOperatorAccountCreatePage = Loadable(
  lazy(() => import('pages/dashboard/platformOperatorAccount/PlatformOperatorAccountCreatePage'))
);
const PlatformOperatorAccountUserDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/platformOperatorAccount/employee/PlatformOperatorAccountUserDataWrapper'))
);
const PlatformOperatorAccountUserListPage = Loadable(
  lazy(() => import('pages/dashboard/platformOperatorAccount/employee/PlatformOperatorAccountUserListPage'))
);

// ServiceProviderAccount
const ServiceProviderAccountDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/serviceProviderAccount/ServiceProviderAccountDataWrapper'))
);
const ServiceProviderAccountListPage = Loadable(
  lazy(() => import('pages/dashboard/serviceProviderAccount/ServiceProviderAccountListPage'))
);
const ServiceProviderAccountUserDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/serviceProviderAccount/employee/ServiceProviderAccountUserDataWrapper'))
);
const ServiceProviderAccountUserListPage = Loadable(
  lazy(() => import('pages/dashboard/serviceProviderAccount/employee/ServiceProviderAccountUserListPage'))
);

// Me
const CurrentUserEditPage = Loadable(lazy(() => import('pages/dashboard/me/UserEditPage')));
const CurrentUserInfoPage = Loadable(lazy(() => import('pages/dashboard/me/UserInfoPage')));
const CurrentUserNotificationEditPage = Loadable(lazy(() => import('pages/dashboard/me/UserNotificationEditPage')));
const CurrentUserNotificationInfoPage = Loadable(lazy(() => import('pages/dashboard/me/UserNotificationInfoPage')));
const CurrentUserWrapper = Loadable(lazy(() => import('pages/dashboard/me/UserWrapper')));

const MeAsTechnicianReviewListPage = Loadable(lazy(() => import('pages/dashboard/me/technician/MeAsTechnicianReviewListPage')));
const MeAsEnterpriseTechnicianLegalEntityInfoPage = Loadable(
  lazy(() => import('pages/dashboard/me/technician/MeAsEnterpriseTechnicianLegalEntityInfoPage'))
);
const MeAsEnterpriseTechnicianRegionServiceCategoryEditPage = Loadable(
  lazy(() => import('pages/dashboard/me/technician/MeAsTechnicianRegionServiceCategoryEditPage'))
);
const MeAsEnterpriseTechnicianRegionServiceCategoryListPage = Loadable(
  lazy(() => import('pages/dashboard/me/technician/MeAsTechnicianRegionServiceCategoryListPage'))
);
const MeAsEnterpriseTechnicianLegalEntityEditPage = Loadable(
  lazy(() => import('pages/dashboard/me/technician/MeAsEnterpriseTechnicianLegalEntityEditPage'))
);

// Invoice общие
const InvoiceInfoPage = Loadable(lazy(() => import('pages/dashboard/invoice/InvoiceInfoPage')));
const InvoiceOrderTransactionAddPage = Loadable(lazy(() => import('pages/dashboard/invoice/InvoiceOrderTransactionAddPage')));

// Invoice provider
const InvoiceProviderListPage = Loadable(lazy(() => import('pages/dashboard/invoice/invoiceProvider/InvoiceProviderListPage')));
const InvoiceProviderEditPage = Loadable(lazy(() => import('pages/dashboard/invoice/invoiceProvider/InvoiceProviderEditPage')));
const InvoiceProviderDataWrapper = Loadable(lazy(() => import('pages/dashboard/invoice/invoiceProvider/InvoiceProviderDataWrapper')));

// Invoice PO
const InvoicePODataWrapper = Loadable(lazy(() => import('pages/dashboard/invoice/invoicePO/InvoicePODataWrapper')));
const InvoicePOListPage = Loadable(lazy(() => import('pages/dashboard/invoice/invoicePO/InvoicePOListPage')));
const InvoicePOEditPage = Loadable(lazy(() => import('pages/dashboard/invoice/invoicePO/InvoicePOEditPage')));

// WidgetSettings
const WidgetMatchSettingEnterpriseDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/widgetSetting/enterprise/EnterpriseDataWrapper'))
);
const WidgetMatchSettingEnterpriseListPage = Loadable(lazy(() => import('pages/dashboard/widgetSetting/enterprise/EnterpriseListPage')));
const WidgetMatchSettingListPage = Loadable(lazy(() => import('pages/dashboard/widgetSetting/setting/WidgetMatchSettingListPage')));
const WidgetServiceVisibilitySettingListPage = Loadable(
  lazy(() => import('pages/dashboard/widgetSetting/setting/WidgetServiceVisibilitySettingListPage'))
);
const WidgetServiceVisibilitySettingEditPage = Loadable(
  lazy(() => import('pages/dashboard/widgetSetting/setting/WidgetServiceVisibilitySettingEditPage'))
);
const WidgetMatchSettingBrandDataWrapper = Loadable(lazy(() => import('pages/dashboard/widgetSetting/brand/BrandDataWrapper')));
const WidgetMatchSettingBrandListPage = Loadable(lazy(() => import('pages/dashboard/widgetSetting/brand/BrandListPage')));

// ServiceProvider
const ServiceProviderDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/serviceProvider/serviceProvider/ServiceProviderDataWrapper'))
);
// const ServiceProviderListPage = Loadable(lazy(() => import('pages/dashboard/serviceProvider/serviceProvider/ServiceProviderListPage')));
const ServiceProviderInfoPage = Loadable(lazy(() => import('pages/dashboard/serviceProvider/serviceProvider/ServiceProviderInfoPage')));
const ServiceProviderEditPage = Loadable(lazy(() => import('pages/dashboard/serviceProvider/serviceProvider/ServiceProviderEditPage')));
const ServiceProviderLegalInfoPage = Loadable(
  lazy(() => import('pages/dashboard/serviceProvider/legalEntity/ServiceProviderLegalInfoPage'))
);
const ServiceProviderLegalEditPage = Loadable(
  lazy(() => import('pages/dashboard/serviceProvider/legalEntity/ServiceProviderLegalEditPage'))
);
const ServiceProviderLegalLoaderFix = Loadable(
  lazy(() => import('pages/dashboard/serviceProvider/legalEntity/ServiceProviderLegalLoaderFix'))
);
const ServiceProviderLegalDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/serviceProvider/legalEntity/ServiceProviderLegalDataWrapper'))
);
const ServiceProviderReviewListPage = Loadable(
  lazy(() => import('pages/dashboard/serviceProvider/serviceProvider/ServiceProviderReviewListPage'))
);
const SPEmployeeDataWrapper = Loadable(lazy(() => import('pages/dashboard/serviceProvider/employee/SPEmployeeDataWrapper')));
const SPEmployeeListPage = Loadable(lazy(() => import('pages/dashboard/serviceProvider/employee/SPEmployeeListPage')));
const SPEmployeeInfoPage = Loadable(lazy(() => import('pages/dashboard/serviceProvider/employee/EmployeeInfoPage')));
const SPEmployeeEditPage = Loadable(lazy(() => import('pages/dashboard/serviceProvider/employee/EmployeeEditPage')));
const SPEmployeeNotificationsInfoPage = Loadable(
  lazy(() => import('pages/dashboard/serviceProvider/employee/EmployeeNotificationsInfoPage'))
);
const SPEmployeeNotificationsEditPage = Loadable(
  lazy(() => import('pages/dashboard/serviceProvider/employee/EmployeeNotificationsEditPage'))
);
const SPEmployeeReviewListPage = Loadable(lazy(() => import('pages/dashboard/serviceProvider/employee/TechnicianReviewListPage')));
const SPDepartmentWrapper = Loadable(lazy(() => import('pages/dashboard/serviceProvider/department/SPDepartmentWrapper')));
const SPDepartmentListPage = Loadable(lazy(() => import('pages/dashboard/serviceProvider/department/SPDepartmentListPage')));
const SPDepartmentEditPage = Loadable(lazy(() => import('pages/dashboard/serviceProvider/department/SPDepartmentEditPage')));
const SPDepartmentInfoPage = Loadable(lazy(() => import('pages/dashboard/serviceProvider/department/SPDepartmentInfoPage')));
const ServiceProviderInvitationPage = Loadable(
  lazy(() => import('pages/dashboard/serviceProvider/serviceProvider/ServiceProviderInvitationPage'))
);
const ServiceProviderRedirectToMyPage = Loadable(
  lazy(() => import('pages/dashboard/serviceProvider/serviceProvider/ServiceProviderRedirectToMyPage'))
);

// Enterprise
const EnterpriseDataWrapper = Loadable(lazy(() => import('pages/dashboard/enterprise/enterprise/EnterpriseDataWrapper')));
const EnterpriseEditPage = Loadable(lazy(() => import('pages/dashboard/enterprise/enterprise/EnterpriseEditPage')));
const EnterpriseInfoPage = Loadable(lazy(() => import('pages/dashboard/enterprise/enterprise/EnterpriseInfoPage')));
const EnterpriseListPage = Loadable(lazy(() => import('pages/dashboard/enterprise/enterprise/EnterpriseListPage')));
const EnterpriseServiceProviderDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/enterprise/serviceProvider/ServiceProviderDataWrapper'))
);
const EnterpriseServiceCompaniesEditPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/serviceProvider/ServiceProviderEditPage'))
);
const EnterpriseServiceProviderListPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/serviceProvider/ServiceProviderListPage'))
);
const BrandDataWrapper = Loadable(lazy(() => import('pages/dashboard/enterprise/brand/BrandDataWrapper')));
const BrandEditPage = Loadable(lazy(() => import('pages/dashboard/enterprise/brand/BrandEditPage')));
const BrandInfoPage = Loadable(lazy(() => import('pages/dashboard/enterprise/brand/BrandInfoPage')));
const EnterpriseUiConsoleInfoPage = Loadable(lazy(() => import('pages/dashboard/enterprise/enterprise/EnterpriseUiConsoleInfoPage')));
const BrandListPage = Loadable(lazy(() => import('pages/dashboard/enterprise/brand/BrandListPage')));
const BrandClientsAppSettingsInfoPage = Loadable(lazy(() => import('pages/dashboard/enterprise/brand/BrandClientsAppSettingsInfoPage')));
const BrandClientsAppSettingsEditPage = Loadable(lazy(() => import('pages/dashboard/enterprise/brand/BrandClientsAppSettingsEditPage')));
const EnterpriseLegalEntityDataWrapper = Loadable(lazy(() => import('pages/dashboard/enterprise/legalEntity/LegalEntityDataWrapper')));
const EnterpriseLegalEntityEditPage = Loadable(lazy(() => import('pages/dashboard/enterprise/legalEntity/LegalEntityEditPage')));
const EnterpriseLegalEntityInfoPage = Loadable(lazy(() => import('pages/dashboard/enterprise/legalEntity/LegalEntityInfoPage')));
const EnterpriseLegalEntityListPage = Loadable(lazy(() => import('pages/dashboard/enterprise/legalEntity/LegalEntityListPage')));
const EnterpriseDepartmentDataWrapper = Loadable(lazy(() => import('pages/dashboard/enterprise/department/DepartmentDataWrapper')));
const EnterpriseDepartmentEditPage = Loadable(lazy(() => import('pages/dashboard/enterprise/department/DepartmentEditPage')));
const EnterpriseDepartmentInfoPage = Loadable(lazy(() => import('pages/dashboard/enterprise/department/DepartmentInfoPage')));
const EnterpriseDepartmentListPage = Loadable(lazy(() => import('pages/dashboard/enterprise/department/DepartmentListPage')));
const EnterpriseEmployeeDataWrapper = Loadable(lazy(() => import('pages/dashboard/enterprise/employee/UserDataWrapper')));
const EnterpriseEmployeeEditPage = Loadable(lazy(() => import('pages/dashboard/enterprise/employee/UserEditPage')));
const EnterpriseEmployeeInfoPage = Loadable(lazy(() => import('pages/dashboard/enterprise/employee/UserInfoPage')));
const EnterpriseEmployeeListPage = Loadable(lazy(() => import('pages/dashboard/enterprise/employee/UserListPage')));
const EnterpriseGlobalNotificationsEditPage = Loadable(lazy(() => import('pages/dashboard/enterprise/employee/UserNotificationsEditPage')));
const EnterpriseGlobalNotificationsInfoPage = Loadable(lazy(() => import('pages/dashboard/enterprise/employee/UserNotificationsInfoPage')));
const EnterpriseEmployeeNotificationsEditPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/employee/EnterpriseEmployeeNotificationsEditPage'))
);
const EnterpriseEmployeeNotificationsInfoPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/employee/EnterpriseEmployeeNotificationsInfoPage'))
);
const EnterpriseApiSettingsEditPage = Loadable(lazy(() => import('pages/dashboard/enterprise/enterprise/EnterpriseApiSettingsEditPage')));
const EnterpriseTechnicianSettingsEditPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/enterprise/EnterpriseTechnicianSettingsEditPage'))
);
const EnterpriseApiSettingsInfoPage = Loadable(lazy(() => import('pages/dashboard/enterprise/enterprise/EnterpriseApiSettingsInfoPage')));

const EnterpriseTechnicianLegalEntityInfoPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/technician/EnterpriseTechnicianLegalEntityInfoPage'))
);
const EnterpriseTechnicianLegalEntityEditPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/technician/EnterpriseTechnicianLegalEntityEditPage'))
);
const EnterpriseTechnicianRegionServiceCategoryListPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/technician/EnterpriseTechnicianRegionServiceCategoryListPage'))
);
const EnterpriseTechnicianRegionServiceCategoryEditPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/technician/EnterpriseTechnicianRegionServiceCategoryEditPage'))
);
const TechnicianRegionServiceCategoryEditPage = Loadable(
  lazy(() => import('pages/dashboard/serviceProvider/employee/TechnicianRegionServiceCategoryEditPage'))
);
const TechnicianRegionServiceCategoryListPage = Loadable(
  lazy(() => import('pages/dashboard/serviceProvider/employee/TechnicianRegionServiceCategoryListPage'))
);
const EnterpriseOrderCustomFieldListPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/orderCustomField/EnterpriseOrderCustomFieldListPage'))
);
const EnterpriseOrderCustomFieldLoaderFix = Loadable(
  lazy(() => import('pages/dashboard/enterprise/orderCustomField/EnterpriseOrderCustomFieldLoaderFix'))
);
const EnterpriseOrderCustomFieldInfoPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/orderCustomField/EnterpriseOrderCustomFieldInfoPage'))
);
const EnterpriseOrderCustomFieldEditPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/orderCustomField/EnterpriseOrderCustomFieldEditPage'))
);
const EnterpriseOrderSettingsInfoPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/enterprise/EnterpriseOrderSettingsInfoPage'))
);
const EnterpriseOrderSettingsEditPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/enterprise/EnterpriseOrderSettingsEditPage'))
);
const EnterpriseTechnicianTeamListPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/technicianTeam/EnterpriseTechnicianTeamListPage'))
);
const EnterpriseTechnicianTeamDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/enterprise/technicianTeam/EnterpriseTechnicianTeamDataWrapper'))
);
const EnterpriseTechnicianTeamEditPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/technicianTeam/EnterpriseTechnicianTeamEditPage'))
);
const EnterpriseTechnicianTeamInfoPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/technicianTeam/EnterpriseTechnicianTeamInfoPage'))
);
const EnterpriseRedirectToMyPage = Loadable(lazy(() => import('pages/dashboard/enterprise/enterprise/EnterpriseRedirectToMyPage')));
const EnterpriseRegionListPage = Loadable(lazy(() => import('pages/dashboard/enterprise/region/EnterpriseRegionListPage')));
const EnterpriseRegionDataWrapper = Loadable(lazy(() => import('pages/dashboard/enterprise/region/EnterpriseRegionDataWrapper')));
const EnterpriseRegionEditPage = Loadable(lazy(() => import('pages/dashboard/enterprise/region/EnterpriseRegionEditPage')));
const EnterpriseRegionInfoPage = Loadable(lazy(() => import('pages/dashboard/enterprise/region/EnterpriseRegionInfoPage')));

const EnterpriseShopListPage = Loadable(lazy(() => import('pages/dashboard/enterprise/shop/EnterpriseShopListPage')));
const EnterpriseShopDataWrapper = Loadable(lazy(() => import('pages/dashboard/enterprise/shop/EnterpriseShopDataWrapper')));
const EnterpriseShopEditPage = Loadable(lazy(() => import('pages/dashboard/enterprise/shop/EnterpriseShopEditPage')));
const EnterpriseShopInfoPage = Loadable(lazy(() => import('pages/dashboard/enterprise/shop/EnterpriseShopInfoPage')));

const EnterpriseTechnicianDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/enterprise/technician/EnterpriseTechnicianDataWrapper'))
);
const EnterpriseTechnicianListPage = Loadable(lazy(() => import('pages/dashboard/enterprise/technician/EnterpriseTechnicianListPage')));
const EnterpriseTechnicianInfoPage = Loadable(lazy(() => import('pages/dashboard/enterprise/technician/EnterpriseTechnicianInfoPage')));
const EnterpriseTechnicianEditPage = Loadable(lazy(() => import('pages/dashboard/enterprise/technician/EnterpriseTechnicianEditPage')));
const EnterpriseTechnicianNotificationsInfoPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/technician/EnterpriseTechnicianNotificationsInfoPage'))
);
const EnterpriseTechnicianNotificationsEditPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/technician/EnterpriseTechnicianNotificationsEditPage'))
);
const EnterpriseTechnicianReviewListPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/technician/EnterpriseTechnicianReviewListPage'))
);
const EnterpriseServicePackageDataWrapper = Loadable(lazy(() => import('pages/dashboard/enterprise/servicePackage/ServicePackageWrapper')));
const EnterpriseServicePackageListPage = Loadable(lazy(() => import('pages/dashboard/enterprise/servicePackage/ServicePackageListPage')));
const EnterpriseServicePackageInfoPage = Loadable(lazy(() => import('pages/dashboard/enterprise/servicePackage/ServicePackageInfoPage')));
const EnterpriseServicePackageEditPage = Loadable(lazy(() => import('pages/dashboard/enterprise/servicePackage/ServicePackageEditPage')));

// Authentication
const Login = Loadable(lazy(() => import('pages/authentication/LoginPage')));
const VerifyCredentialsPage = Loadable(lazy(() => import('pages/authentication/VerifyCredentialsPage')));
const ResetPasswordPage = LoadableCustom(
  lazy(() => import('pages/authentication/ResetPasswordPage')),
  <UnauthorizedLayout />
);

// Client
const ClientOrderInfoPage = Loadable(lazy(() => import('pages/client/order/OrderInfoPage')));
const ClientRootWrapper = Loadable(lazy(() => import('pages/client/RootWrapper')));
const ClientOrderPaidSuccessfullyPage = Loadable(lazy(() => import('pages/client/order/OrderPaidSuccessfullyPage')));
const ClientOrderPaidUnsuccessfullyPage = Loadable(lazy(() => import('pages/client/order/OrderPaidUnsuccessfullyPage')));
const ClientOrderReviewEditPage = Loadable(lazy(() => import('pages/client/review/OrderReviewEditPage')));
const ClientCommentListPage = Loadable(lazy(() => import('pages/client/comment/CommentListPage')));
const ClientServicesListPage = Loadable(lazy(() => import('pages/client/services/ServicesListPage')));

// Funds transaction
const FundsTransactionDataWrapper = Loadable(lazy(() => import('pages/dashboard/fundsTransaction/FundsTransactionDataWrapper')));
const FundsTransactionListPage = Loadable(lazy(() => import('pages/dashboard/fundsTransaction/addition/FundsTransactionListPage')));
const FundsTransactionEditPage = Loadable(lazy(() => import('pages/dashboard/fundsTransaction/FundsTransactionEditPage')));
const FundsTransactionInfoPage = Loadable(lazy(() => import('pages/dashboard/fundsTransaction/FundsTransactionInfoPage')));
const FundTransactionDeductionListPage = Loadable(
  lazy(() => import('pages/dashboard/fundsTransaction/deduction/FundsTransactionListDeductionPage'))
);

// Contract SP
const ContractSPDataWrapper = Loadable(lazy(() => import('pages/dashboard/contractSP/contract/ContractSPDataWrapper')));
const ContractSPListPage = Loadable(lazy(() => import('pages/dashboard/contractSP/contract/ContractSPListPage')));
const ContractSPEditPage = Loadable(lazy(() => import('pages/dashboard/contractSP/contract/ContractSPEditPage')));
const ContractSPAddPage = Loadable(lazy(() => import('pages/dashboard/contractSP/contract/ContractSPAddPage')));
const ContractSPInfoPage = Loadable(lazy(() => import('pages/dashboard/contractSP/contract/ContractSPInfoPage')));
const ContractPriceSPLoaderFix = Loadable(lazy(() => import('pages/dashboard/contractSP/price/ContractPriceSPLoaderFix')));
const ContractPriceSPDataWrapper = Loadable(lazy(() => import('pages/dashboard/contractSP/price/ContractPriceSPDataWrapper')));
const ContractPriceSPListPage = Loadable(lazy(() => import('pages/dashboard/contractSP/price/ContractPriceSPListPage')));
const ContractSPLEInfoPage = Loadable(lazy(() => import('pages/dashboard/contractSP/contract/ContractSPLEInfoPage')));
const ContractSPReviewPage = Loadable(lazy(() => import('pages/dashboard/contractSP/contract/ContractSPReviewPage')));
const ContractSPEmployeesDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/contractSP/contract/employees/ContractSPEmployeesDataWrapper'))
);
const ContractSPEmployeesListPage = Loadable(
  lazy(() => import('pages/dashboard/contractSP/contract/employees/ContractSPEmployeesListPage'))
);
const ContractSPEmployeeReviewPage = Loadable(
  lazy(() => import('pages/dashboard/contractSP/contract/employees/ContractSPEmployeeReviewPage'))
);

// Contract PO
const ContractPODataWrapper = Loadable(lazy(() => import('pages/dashboard/contractPO/contract/ContractPODataWrapper')));
const ContractPOListPage = Loadable(lazy(() => import('pages/dashboard/contractPO/contract/ContractPOListPage')));
const ContractPOAddPage = Loadable(lazy(() => import('pages/dashboard/contractPO/contract/ContractPOAddPage')));
const ContractPOEditPage = Loadable(lazy(() => import('pages/dashboard/contractPO/contract/ContractPOEditPage')));
const ContractPOInfoPage = Loadable(lazy(() => import('pages/dashboard/contractPO/contract/ContractPOInfoPage')));
const ContractPOLEInfoPage = Loadable(lazy(() => import('pages/dashboard/contractPO/contract/ContractPOLEInfoPage')));
const ContractPOReviewPage = Loadable(lazy(() => import('pages/dashboard/contractPO/contract/ContractPOReviewPage')));
const ContractPricePOLoaderFix = Loadable(lazy(() => import('pages/dashboard/contractPO/price/ContractPricePOLoaderFix')));
const ContractPricePODataWrapper = Loadable(lazy(() => import('pages/dashboard/contractPO/price/ContractPricePODataWrapper')));
const ContractPricePOListPage = Loadable(lazy(() => import('pages/dashboard/contractPO/price/ContractPricePOListPage')));
const ContractPOPriceSettingsEditPage = Loadable(lazy(() => import('pages/dashboard/contractPO/price/ContractPOPriceSettingsEditPage')));

// Price SP
const PriceServiceProviderDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/priceServiceProvider/priceServiceProvider/PriceServiceProviderDataWrapper'))
);
const PricelistServiceProviderRedirectToFirstPricelistPage = Loadable(
  lazy(() => import('pages/dashboard/priceServiceProvider/pricelistServiceProvider/PricelistServiceProviderRedirectToFirstPricelistPage'))
);
const PricelistServiceProviderDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/priceServiceProvider/pricelistServiceProvider/PricelistServiceProviderDataWrapper'))
);
const PriceServiceProviderListPage = Loadable(
  lazy(() => import('pages/dashboard/priceServiceProvider/priceServiceProvider/PriceServiceProviderListPage'))
);
const PricelistServiceProviderEditPage = Loadable(
  lazy(() => import('pages/dashboard/priceServiceProvider/pricelistServiceProvider/PricelistServiceProviderEditPage'))
);

// Prcie PO
const PricelistPlatformOperatorDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/pricePlatformOperator/pricelistPlatformOperator/PricelistPlatformOperatorDataWrapper'))
);
const PricelistPlatformOperatorRedirectToFirstPricelistPage = Loadable(
  lazy(
    () => import('pages/dashboard/pricePlatformOperator/pricelistPlatformOperator/PricelistPlatformOperatorRedirectToFirstPricelistPage')
  )
);
const PricelistPlatformOperatorEditPage = Loadable(
  lazy(() => import('pages/dashboard/pricePlatformOperator/pricelistPlatformOperator/PricelistPlatformOperatorEditPage'))
);
const PricePlatformOperatorDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/pricePlatformOperator/pricePlatformOperator/PricePlatformOperatorDataWrapper'))
);
const PricePlatformOperatorListPage = Loadable(
  lazy(() => import('pages/dashboard/pricePlatformOperator/pricePlatformOperator/PricePlatformOperatorListPage'))
);
const PricelistRecommendedPriceRedirectToFirstPricelistPage = Loadable(
  lazy(() => import('pages/dashboard/recommendedPrice/pricelistRecommendedPrice/PricelistRecommendedPriceRedirectToFirstPricelistPage'))
);
const PricelistRecommendedPriceEditPage = Loadable(
  lazy(() => import('pages/dashboard/recommendedPrice/pricelistRecommendedPrice/PricelistRecommendedPriceEditPage'))
);
const PriceRecommendedPriceListPage = Loadable(
  lazy(() => import('pages/dashboard/recommendedPrice/priceRecommendedPrice/PriceRecommendedPriceListPage'))
);

// Price technician
const PriceTechnicianDataWrapper = Loadable(lazy(() => import('pages/dashboard/priceTechnician/PriceTechnicianDataWrapper')));
const PriceTechnicianListPage = Loadable(lazy(() => import('pages/dashboard/priceTechnician/PriceTechnicianListPage')));
const PriceTechnicianEditPage = Loadable(lazy(() => import('pages/dashboard/priceTechnician/PriceTechnicianEditPage')));

// Price client
const PriceClientDataWrapper = Loadable(lazy(() => import('pages/dashboard/priceClient/PriceClientDataWrapper')));
const PriceClientListPage = Loadable(lazy(() => import('pages/dashboard/priceClient/PriceClientListPage')));
const PriceClientEditPage = Loadable(lazy(() => import('pages/dashboard/priceClient/PriceClientEditPage')));

// Service
const ServiceEnterpriseRedirectToMyPage = Loadable(lazy(() => import('pages/dashboard/service/ServiceEnterpriseRedirectToMyPage')));
const ServiceListDataWrapper = Loadable(lazy(() => import('pages/dashboard/service/ServiceListDataWrapper')));
const ServiceListPage = Loadable(lazy(() => import('pages/dashboard/service/ServiceListPage')));
// Не требуется оборачивать в Loadable, так как являются попапами
const ServiceDataWrappper = LoadableBackground(lazy(() => import('pages/dashboard/service/ServiceDataWrapper')));
const ServiceEditPage = LoadableBackground(lazy(() => import('pages/dashboard/service/ServiceEditPopup')));
const ServiceAddPage = LoadableBackground(lazy(() => import('pages/dashboard/service/ServiceAddPopup')));

// Service
const EnterpriseServiceDataWrapper = Loadable(lazy(() => import('pages/dashboard/enterprise/service/service/ServiceDataWrapper')));
const EnterpriseServiceListPage = Loadable(lazy(() => import('pages/dashboard/enterprise/service/service/ServiceListPage')));
const EnterpriseServiceEditPage = Loadable(lazy(() => import('pages/dashboard/enterprise/service/service/ServiceEditPage')));
const EnterpriseServiceInfoPage = Loadable(lazy(() => import('pages/dashboard/enterprise/service/service/ServiceInfoPage')));

// Сейчас не используется
// Service enterprise
const EnterpriseServiceEnterpriseListPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/service/enterprise/EnterpriseListPage'))
);

// Service Category
const EnterpriseServiceCategoryGroupDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/enterprise/service/serviceCategoryGroup/ServiceCategoryGroupDataWrapper'))
);
const EnterpriseServiceCategoryGroupListPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/service/serviceCategoryGroup/ServiceCategoryGroupListPage'))
);
const EnterpriseServiceCategoryGroupEditPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/service/serviceCategoryGroup/ServiceCategoryGroupEditPage'))
);
const EnterpriseServiceCategoryDataWrapper = Loadable(
  lazy(() => import('pages/dashboard/enterprise/service/serviceCategory/ServiceCategoryDataWrapper'))
);
const EnterpriseServiceCategoryListPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/service/serviceCategory/ServiceCategoryListPage'))
);
const EnterpriseServiceCategoryEditPage = Loadable(
  lazy(() => import('pages/dashboard/enterprise/service/serviceCategory/ServiceCategoryEditPage'))
);

// Promotions
const PromotionsConfigDataWrapper = Loadable(lazy(() => import('pages/dashboard/promotionsConfig/PromotionsConfigDataWrapper')));
const PromotionsListPage = Loadable(lazy(() => import('pages/dashboard/promotionsConfig/promotions/PromotionsListPage')));
const PromotionsInfoPage = Loadable(lazy(() => import('pages/dashboard/promotionsConfig/promotions/PromotionsInfoPage')));
const PromotionsEditPage = Loadable(lazy(() => import('pages/dashboard/promotionsConfig/promotions/PromotionsEditPage')));
const CategoriesListPage = Loadable(lazy(() => import('pages/dashboard/promotionsConfig/categories/CategoriesListPage')));
const CategoriesInfoPage = Loadable(lazy(() => import('pages/dashboard/promotionsConfig/categories/CategoriesInfoPage')));
const CategoriesEditPage = Loadable(lazy(() => import('pages/dashboard/promotionsConfig/categories/CategoriesEditPage')));
const AggregationInfoPage = Loadable(lazy(() => import('pages/dashboard/promotionsConfig/aggregation/AggregationInfoPage')));
const AggregationEditPage = Loadable(lazy(() => import('pages/dashboard/promotionsConfig/aggregation/AggregationEditPage')));
const VariablesEditPage = Loadable(lazy(() => import('pages/dashboard/promotionsConfig/variables/VariablesEditPage')));

// Other
const DashboardRootLayout = Loadable(lazy(() => import('../../components/layouts/dashboard/DashboardRootLayout')));
const DashboardSectionLayout = Loadable(lazy(() => import('components/layouts/dashboard/DashboardSectionLayout')));
const Maintenance = Loadable(lazy(() => import('pages/Maintenance')));
const NotFound = Loadable(lazy(() => import('pages/Page404')));

import { PromotionsConfigResponseDTO } from 'typings/dto/promotionsConfig';
import Mapper from './Mapper';
import DIContainer from 'services/DIContainer';

export default class PromotionsConfigMapper extends Mapper<PromotionsConfig, PromotionsConfigResponseDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super({
      hash: 'hash',
      version: 'version',
      tenantId: 'tenantId',
      updatedBy: 'updatedBy',
      promotionsConfig: null,
    });
  }

  public responseDTOToModel(dto: PromotionsConfigResponseDTO): PromotionsConfig {
    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      promotionsConfig: {
        categories: dto.promotionsConfig.categories,
        predefined: dto.promotionsConfig.predefined,
        promotionsAggregationTree: dto.promotionsConfig.promotionsAggregationTree,
        promotions: dto.promotionsConfig.promotions.map((promotion) => {
          return {
            ...promotion,
            dateInterval: this.subEntitiesMappers.dateInterval.responseDTOToModel(promotion.dateInterval),
          };
        }),
      },
      version: dto.version,
      tenantId: dto.tenantId,
      updatedBy: dto.updatedBy,
    };
  }

  public modelToRequestDTO(model: PromotionsConfig): PromotionsConfigResponseDTO {
    return {
      id: model.id,
      createdAt: model.createdAt.toISOString(),
      updatedAt: model.updatedAt.toISOString(),
      promotionsConfig: {
        categories: model.promotionsConfig.categories,
        predefined: model.promotionsConfig.predefined,
        promotionsAggregationTree: model.promotionsConfig.promotionsAggregationTree,
        promotions: model.promotionsConfig.promotions.map((promotion) => {
          return {
            ...promotion,
            dateInterval: this.subEntitiesMappers.dateInterval.modelToRequestDTO(promotion.dateInterval),
          };
        }),
      },
      version: model.version,
      tenantId: model.tenantId,
      updatedBy: model.updatedBy,
      hash: model.hash || '',
    };
  }
}

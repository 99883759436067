import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useDI from 'hooks/useDI';
import useIsEditPage from 'hooks/useIsEditPage';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import { ChildFormsState } from 'hooks/useChildForms';
import ConfirmButtonGroup from 'components/ui/forms/ConfirmButtonGroup';
import VariablesEditBlock from 'components/_dashboardPagesFeatures/recommendationsConfig/predefined/VariablesEditBlock';

type Props = {
  isPopupOpened: boolean;
  popupCloseHandler: () => void;
  recommendationsConfig: RecommendationsConfig | null;
  variablesList: PromotionsPredefined.PromotionsPredefinedUnknown[] | undefined;
  varsChildForms: ChildFormsState;
  rerenderPageCallback: VoidFunction;
  popupOpenHandler: (index?: string) => void;
  toOpenIndex?: string;
  dictionaryList: Dictionary[];
  parentModelPath: string;
};

export default function RecommendationsVariablesEditForm({
  isPopupOpened,
  popupCloseHandler,
  variablesList,
  varsChildForms,
  recommendationsConfig,
  rerenderPageCallback,
  popupOpenHandler,
  toOpenIndex,
  dictionaryList,
  parentModelPath,
}: Props) {
  const { storageActions } = useDI();
  const isEditPage = useIsEditPage();
  const handleFormErrors = useSnackbarErrorHandler();
  const [anyDirty, setAnyDirty] = useState(false);
  const [isDeletePopupOpened, setIsDeletePopupOpened] = useState(false);
  const [toDeleteIndex, setToDeleteIndex] = useState<string | null>(null);
  const isMountedRef = useIsMountedRef();
  const [configError, setConfigError] = useState<ConfigError>({});

  const deletePredefinedHandler = async () => {
    if (!recommendationsConfig || toDeleteIndex === null) return;
    varsChildForms.formsMapRef.current.delete(toDeleteIndex);
    rerenderPageCallback();
    setAnyDirty(true);
  };

  const closeDeleteHandler = () => {
    if (isMountedRef.current) {
      setIsDeletePopupOpened(false);
    }
  };
  const cancelHandler = async () => {
    try {
      await storageActions.models.recommendationsConfig.getRecommendationsConfig();
    } catch (error) {
      handleFormErrors({ error, callback: () => {} });
    }
  };
  const saveHandler = async () => {
    try {
      if (!recommendationsConfig) return;
      const predefineds = await varsChildForms.submitForms<PromotionsPredefined>();
      if (predefineds.some((i) => i === undefined)) return;
      const updatedRecommendationsConfig: RecommendationsConfig = cloneDeep(recommendationsConfig);
      updatedRecommendationsConfig.recommendationsConfig.predefined = predefineds;

      if (isEditPage) {
        await storageActions.models.recommendationsConfig.updateRecommendationsConfig(updatedRecommendationsConfig);
      } else {
        await storageActions.models.recommendationsConfig.updateRecommendationsConfig(updatedRecommendationsConfig);
      }
    } catch (error: any) {
      setConfigError(error.details);
      handleFormErrors({ error, callback: () => {} });
    }
  };

  return (
    <>
      <VariablesEditBlock
        anyDirty={anyDirty}
        isPopupOpened={isPopupOpened}
        popupCloseHandler={popupCloseHandler}
        variablesList={variablesList}
        varsChildForms={varsChildForms}
        rerenderPageCallback={rerenderPageCallback}
        setAnyDirty={setAnyDirty}
        setToDeleteIndex={setToDeleteIndex}
        popupOpenHandler={popupOpenHandler}
        dictionaryList={dictionaryList}
        closeDeleteHandler={closeDeleteHandler}
        deletePredefinedHandler={deletePredefinedHandler}
        toOpenIndex={toOpenIndex}
        isDeletePopupOpened={isDeletePopupOpened}
        setIsDeletePopupOpened={setIsDeletePopupOpened}
        parentModelPath={parentModelPath}
        configError={configError}
      />
      {anyDirty && <ConfirmButtonGroup isLoading={false} cancelHandler={cancelHandler} submitHandler={saveHandler} />}
    </>
  );
}

import { Paper, Box } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode
}

export default function UnauthorizedLayout({ children }: Props) {
  return (
    <Box sx={{ height: '100vh' }}>
      <Box sx={{ height: 77, display: { md: 'none', xs: 'flex' }, alignItems: 'center', justifyContent: 'center' }}>
        <img src="/images/marya_logo.png" alt="" />
      </Box>
      <Box
        sx={{
          backgroundImage: 'url(/images/auth/background.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: { xs: 'calc(100% - 77px)', md: '100%' },
          display: 'flex',
          backgroundColor: '#232121AB',
          backgroundBlendMode: 'overlay',
        }}
      >
        <Paper sx={{
          width: { md: 1167, xs: '100%' },
          height: { md: '728px', xs: 'auto' },
          maxHeight: '100hvh',
          borderRadius: 3,
          display: { md: 'grid', xs: 'block' },
          gridTemplateColumns: { md: '3fr 2fr', xs: '1fr' },
          overflow: 'hidden',
          margin: { xs: '40px 16px auto', md: 'auto' },
        }}
        >
          {children}
        </Paper>
      </Box>
    </Box>
  )
}
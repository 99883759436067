import DIContainer from 'services/DIContainer';
import {
  CreateUserSPRequestDTO,
  UserSPManagerAttributesRequestDTO,
  UserSPManagerAttributesResponseDTO,
  UserSPResponseDTO,
  UserSPTechnicianAttributesRequestDTO,
  UserSPTechnicianAttributesResponseDTO,
} from 'typings/dto/userSP';
import { USER_ROLE } from 'typings/models/user.enum';
import { TENANT_TYPE } from 'typings/subEntities/tenant.enum';
import FunctionUtils from 'utils/Function';
import Mapper from './Mapper';
import UserBaseMapper, { userDTOToModelDiff } from './UserBase';

export default class UserSPMapper extends Mapper<UserSP, UserSPResponseDTO> {
  private defaultUserMapper: UserBaseMapper;

  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers, defaultUserMapper: UserBaseMapper) {
    super({ ...userDTOToModelDiff, department: 'department', serviceProvider: 'serviceProvider' });
    this.defaultUserMapper = defaultUserMapper;
  }

  public responseDTOToModel = (dto: UserSPResponseDTO): UserSP => {
    const { position: positionMapper } = this.subEntitiesMappers;
    const tenantUser: UserSP = {
      ...this.defaultUserMapper.responseDTOToModel(dto),
      type: dto.tenant.type as TENANT_TYPE.serviceProvider,
      tenant: dto.tenant as Tenant<TENANT_TYPE.serviceProvider>,
      position: positionMapper.responseDTOToModel(dto.position),
      department: dto.department,
      serviceProvider: dto.serviceProvider,
      attributes: {} as any,
      isManager: false as any,
      isTechnician: false as any,
    };

    dto.businessRoles.forEach((role) => {
      switch (role) {
        case USER_ROLE.orderTechnician: {
          tenantUser.isTechnician = true;
          tenantUser.attributes = {
            ...tenantUser.attributes,
            ...this.responseTechnicianAttributesToModel(dto.attributes as UserSPTechnicianAttributesResponseDTO),
          };
          break;
        }
        case USER_ROLE.orderManager: {
          tenantUser.isManager = true;
          tenantUser.attributes = {
            ...tenantUser.attributes,
            ...this.responseManagerAttributesToModel(dto.attributes as UserSPManagerAttributesResponseDTO),
          };
          break;
        }
      }
    });

    return tenantUser;
  };

  private responseTechnicianAttributesToModel = (dto: UserSPTechnicianAttributesResponseDTO): UserSP.Technician['attributes'] => {
    const outerMappers = this.getOuterMappers();
    return {
      // Возможно где-то будет не приходить, т.к. она нагружает запрос и далеко не всегда нужна
      regionServiceCategories: dto.serviceCategories
        ? dto.serviceCategories.map((item) => ({ region: item.region, categories: item.serviceCategories }))
        : [],
      address: dto.address && this.subEntitiesMappers.address.responseDTOToModel(dto.address),
      statistics: {
        ...dto.statistics,
        // Возможно где-то будет не приходить, т.к. она нагружает запрос и далеко не всегда нужна
        reviews: dto.statistics ? dto.statistics.reviews.map(outerMappers.orderReview.responseDTOToModel) : [],
      },
    };
  };

  private responseManagerAttributesToModel = (dto: UserSPManagerAttributesResponseDTO): UserSP.Manager['attributes'] => {
    return { code: dto.code };
  };

  public modelToRequestDTO(user: UserSP): CreateUserSPRequestDTO {
    const dtoUser: CreateUserSPRequestDTO = {
      ...this.defaultUserMapper.modelToRequestDTO(user),
      tenantId: user.tenant.id,
      departmentId: user.department?.id || '', // Непонятно пока требует бек или нет
      attributes: {} as any,
    };

    user.roles.forEach((role) => {
      switch (role) {
        case USER_ROLE.orderTechnician: {
          dtoUser.attributes = {
            ...dtoUser.attributes,
            ...this.modelTechnicianAttributesToRequestDTO(user.attributes as UserSP.Technician['attributes']),
          };
          break;
        }
        case USER_ROLE.orderManager: {
          dtoUser.attributes = {
            ...dtoUser.attributes,
            ...this.modelManagerAttributesToRequestDTO(user.attributes as UserSP.Manager['attributes']),
          };
          break;
        }
        case USER_ROLE.orderSpManager: {
          break;
        }
        default: {
          FunctionUtils.exhaustiveCheck(role);
        }
      }
    });

    return dtoUser;
  }

  public modelTechnicianAttributesToRequestDTO(attributes: UserSP.Technician['attributes']): UserSPTechnicianAttributesRequestDTO {
    return {
      serviceCategories: attributes.regionServiceCategories.map((item) => ({
        regionId: item.region.id,
        serviceCategories: item.categories.map((c) => c.id),
      })),
      address: attributes.address && this.subEntitiesMappers.address.modelToRequestDTO(attributes.address),
    };
  }

  public modelManagerAttributesToRequestDTO(attributes: UserSP.Manager['attributes']): UserSPManagerAttributesRequestDTO {
    return {
      code: attributes.code,
    };
  }

  public responseDTOToModelIdAndNamesOnly = (dto: UserSPResponseDTO): UserSP => {
    const user = this.responseDTOToModelViaDiffMap(dto);
    if (!user.name) {
      // В некоторых эндпоинтах для фильтров юзеры приходят как EntityWithName и стандартный маппер выше не найдёт имя
      user.name = (dto as unknown as EntityWithName).name;
    }
    return user;
  };
}

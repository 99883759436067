import IAuthTokensService from 'services/auth/IAuthTokensService';
import EventSourceCustom from '../../utils/implementations/EventSourceCustom';
import IServerEventsService from './IServerEventsService';
import CookieUtils from 'utils/Cookie';
import { PATH_BACKEND_PART } from 'configs/routes/pathsBackend';

export default class ServerEventsServiceImpl implements IServerEventsService {

  constructor(private authTokensService: IAuthTokensService) { }

  public createSubscription = (
    url: string,
    onMessageCallback: (data: IServerEventsService.SSEEntityUpdateMessageData) => void,
    onErrorCallback: (error: any) => void
  ): IServerEventsService.SubscriptionState => {

    let subscriptionEntityName = url.match(/([^/]+)\/subscribe$/)?.[1] || '';
    let subscriptionState: IServerEventsService.SubscriptionState;

    // Подписка на изменение accessToken
    this.authTokensService.subscribeOnAccessTokenUpdate((accessToken) => {
      subscriptionState?.clearSubscription()
      if (!accessToken) {
        return
      }
      CookieUtils.setNamedCookie('access_token', accessToken, undefined, `/${subscriptionEntityName}/${PATH_BACKEND_PART.common.subscribeOnUpdates}`)
      const eventSource = new EventSourceCustom(url, { withCredentials: true });
      subscriptionState = this.createSubscriptionState(eventSource);
      this.createSubscriptionEventSourceCallbacks(url, eventSource, subscriptionState, onMessageCallback, onErrorCallback, subscriptionEntityName)
    })

    if (this.authTokensService.accessToken) {
      CookieUtils.setNamedCookie('access_token', this.authTokensService.accessToken, undefined, `/${subscriptionEntityName}/${PATH_BACKEND_PART.common.subscribeOnUpdates}`)
    }
    const eventSource = new EventSourceCustom(url, { withCredentials: true });
    subscriptionState = this.createSubscriptionState(eventSource);
    this.createSubscriptionEventSourceCallbacks(url, eventSource, subscriptionState, onMessageCallback, onErrorCallback, subscriptionEntityName)
    console.debug(`[SSE ${subscriptionEntityName}]: Subscribed on ${url}`);
    return subscriptionState;
  };

  /** Мутирует eventSource */
  private createSubscriptionEventSourceCallbacks = (
    url: string,
    eventSource: EventSourceCustom,
    subscriptionState: IServerEventsService.SubscriptionState | undefined,
    onMessageCallback: (data: IServerEventsService.SSEEntityUpdateMessageData) => void,
    onErrorCallback: (error: any) => void,
    subscriptionEntityName: string
  ) => {
    if (!subscriptionState) return 
    eventSource.onopen = () => (subscriptionState.failedAttempts = 0);
    eventSource.onmessage = ServerEventsServiceImpl.onMessageHandlerFactory(onMessageCallback, subscriptionEntityName);
    eventSource.onerror = ServerEventsServiceImpl.onErrorHandlerFactory(
      eventSource,
      onErrorCallback,
      subscriptionState,
      subscriptionEntityName
    );
  }

  private createSubscriptionState = (source: EventSource | EventSourceCustom): IServerEventsService.SubscriptionState => {
    return {
      failedAttempts: 0,
      connectionType: source instanceof EventSourceCustom ? source.connectionType : 'sse',
      clearSubscription: () => source.close(),
    };
  };

  // Static ------------------------------------------------------------------------

  public static readonly MAX_RECONNECT_ATTEMPTS = 3;

  public static onErrorHandlerFactory =
    (
      eventSource: EventSource | EventSourceCustom,
      onErrorCallback: (error: any) => void,
      subscriptionState: IServerEventsService.SubscriptionState,
      subscriptionEntityName: string
    ) =>
    () => {
      subscriptionState.failedAttempts += 1;
      if (eventSource.readyState === EventSource.CLOSED || subscriptionState.failedAttempts > this.MAX_RECONNECT_ATTEMPTS) {
        const error = new Error(`[SSE ${subscriptionEntityName}]: Error: connection closed: too many attempts`); // TODO перевод?
        onErrorCallback(error);
        subscriptionState.clearSubscription();
        // Если мы работает с кастомным типом, то у него есть встроенный механизм восстановления соединения через промежуток времени
        // По идее здесь же мы можем сбросить счётчик failedAttempts, чтобы в будущем при переподключении вновь было N попыток, но тогда он будет отражать неверные данные, поэтому пока остаётся как есть и после переподключения есть только одна попытка на реконнект, прежде чем мы вновь попадём сюда же
        if (eventSource instanceof EventSourceCustom) {
          eventSource.reinitialize();
          subscriptionState.failedAttempts = 0;
        }
      }
    };

  public static onMessageHandlerFactory =
    (onMessageCallback: (data: IServerEventsService.SSEEntityUpdateMessageData) => void, subscriptionEntityName: string) =>
    (event: IServerEventsService.SSEMessage) => {
      console.debug(`[SSE ${subscriptionEntityName}]: received a message`, event.data);
      const data = event.data ? JSON.parse(event.data) : {};
      onMessageCallback(data);
    };
}

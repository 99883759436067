import React from 'react';
import { Stack, Box, Typography, StackProps } from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';

type Props = StackProps & {
  data: InfoListRowProps[];
};

/** @example [title]: [content] */
export default function InfoList({ data, title, sx, ...restProps }: Props) {
  const isMobile = useIsMobile();
  return (
    <Stack sx={{ mt: { xs: 1, md: 2 }, ...sx }} {...restProps}>
      {title && <Typography variant="subtitle1">{title}</Typography>}
      {data.map((item) => (
        <DataRow key={item.id} isMobile={isMobile} {...item} />
      ))}
    </Stack>
  );
}

export type InfoListRowProps = {
  /** Используется в качестве ключа (key) компонента */
  id: string;
  title: string;
  content: React.ReactNode;
  isVisible?: boolean;
};

function DataRow({ isVisible = true, content: children, title, isMobile }: InfoListRowProps & { isMobile: boolean }) {
  if (!isVisible) {
    return null;
  }

  return (
    <Box
      gap={{ xs: 1, md: 2 }}
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '170px 1fr' },
        borderBottom: (theme) => '1px solid ' + theme.palette.grey[300],
        py: { xs: 1, md: 2 },
      }}
    >
      <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'} color="text.secondary" sx={{ wordWrap: 'break-word' }}>
        {title}
      </Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} component="div">
        {children}
      </Typography>
    </Box>
  );
}

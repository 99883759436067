// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react'; // TODO нужно?
import { store, persistor } from './storage'; // TODO persistor нужно?
import { SettingsProvider } from './hooks/_contexts/SettingsContext';
import { CollapseDrawerProvider } from './hooks/_contexts/CollapseDrawerContext';
import App from './App';
import { DebugContextProvider } from 'hooks/_contexts/DebugContext';

// ----------------------------------------------------------------------

ReactDOM.render(
  <HelmetProvider>
    <DebugContextProvider>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistor}>
          <SettingsProvider>
            <CollapseDrawerProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </CollapseDrawerProvider>
          </SettingsProvider>
        </PersistGate>
      </ReduxProvider>
    </DebugContextProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

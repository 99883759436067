import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { CalendarVisiIntervalRequestDTO, CalendarVisitIntervalResponseDTO, TechnicianManyVisitIntervalRequestDTO, TechnicianTeamManyVisitIntervalRequestDTO, TechnicianTeamVisitIntervalCreateRequestDTO, TechnicianTeamVisitIntervalRequestDTO, TechnicianVisitIntervalCreateRequestDTO, TechnicianVisitIntervalRequestDTO, TechnicianVisitIntervalResponseDTO } from 'typings/dto/calendarVisitInterval';
import DIContainer from 'services/DIContainer';
import NavigateBackendUtils from 'utils/NavigateBackend';
import { AppDispatch, RootState } from 'storage';
import IServerEventsService from 'services/serverEvents/IServerEventsService';
import IModelApiService from 'services/entityApi/IModelApiService';
import IEntityApiService from 'services/entityApi/IEntityApiService';
import IMapper from 'utils/mappers/IMapper';
import { batch } from 'react-redux';
import OrderViewUtils from 'utils/models/OrderViewUtils';
import NavigateFrontendUtils from 'utils/NavigateFrontend';
import { BACKEND_ROOT } from 'configs/apis';
import { PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import ICalendarVisitIntervalActions from './ICalendarVisitIntervalActions';
import CalendarUtils from 'utils/models/Calendar';
import ISliceActions from 'storage/slices/ISliceActions';

export default class CalendarVisitIntervalActionsService
  extends ModelActionsService<CalendarVisitInterval, CalendarVisiIntervalRequestDTO, CalendarVisitIntervalResponseDTO>
  implements ICalendarVisitIntervalActions {

  private statefulUtils: DIContainer.StatefulUtils;

  constructor(
    modelStorageData: { name: DIContainer.ModelsWithActionServices; actions: ISliceActions<CalendarVisitInterval> },
    modelMapper: IMapper<CalendarVisitInterval, CalendarVisitIntervalResponseDTO>,
    entityApiService: IEntityApiService,
    modelApiService: IModelApiService,
    subEntitiesApiServices: DIContainer.SubEntitiesApiServices,
    modelApiRootPath: string,
    serverEventsService: IServerEventsService,
    storageStateGetter: () => RootState,
    storageDispatch: AppDispatch,
    statefulUtils: DIContainer.StatefulUtils
  ) {
    super(
      modelStorageData,
      modelMapper,
      entityApiService,
      modelApiService,
      subEntitiesApiServices,
      modelApiRootPath,
      serverEventsService,
      storageStateGetter,
      storageDispatch
    )
    this.statefulUtils = statefulUtils;
  }

  public getDefaultSortFilter = <T extends LocationSearchObject>(filterRaw: T = {} as T): T => {
    // У заказа есть спецметки в фильтрах которых нет на беке и перед отправкой их надо обработать, а т.к. этот метод дёргается везде, то удобно это сделать тут;
    // пока это единичный случай спецметок, иначе придётся как-то систематизировать
    const { currentUser } = this.getStorageState().auth;
    const { customFieldAll: customFields } = this.getStorageState().customField;
    OrderViewUtils.mapFilterSpecialTokens(filterRaw, this.statefulUtils, currentUser, customFields);
    return super.getDefaultSortFilter(filterRaw, 'createdAt', NavigateFrontendUtils.SORT_ORDER.desc);
  };

  public getAll = async (
    dto?: Record<string, any> | null,
    filter?: LocationSearchObject,
  ) => {
    this.storageDispatch(this.modelStorageActions.startAllLoading());
    batch(async () => {
      this.getAllBackground(dto, filter)
      this.storageDispatch(this.modelStorageActions.stopAllLoading());
    });
  };

  public getAllBackground = async (
    dto?: Record<string, any> | null,
    filter?: LocationSearchObject,
  ) => {
    let newFilter = this.getDefaultSortFilter(filter)
    const backendFilter = filter && NavigateBackendUtils.locationSearchObjectToRequestFilter(newFilter);
    let fullPath = NavigateBackendUtils.createDBRequestStrWithFilter(this.getModelApiRootPath(dto), { size: NavigateBackendUtils.MAX_PAGE_SIZE, ...backendFilter });
    if (dto) fullPath = NavigateBackendUtils.addParamsToExistedUrl(fullPath, dto);
    let response = await this.entityApiService.getListWithCredentials<CalendarVisitIntervalResponseDTO>(fullPath).then((responseContainer) => ({
      data: responseContainer.data.flatMap(this.modelMapper.responseDTOToModel),
      pagination: responseContainer.pagination,
      requestProps: { lastListRequestFilter: backendFilter },
    }));
    this.storageDispatch(this.modelStorageActions.setAll(response))
  };

  public createTechnicianVisitIntervals = async ({ technicianId, visitDates }: TechnicianVisitIntervalCreateRequestDTO) => {
    const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.user.root}/${technicianId}/${PATH_BACKEND_PART.user.calendar}`;
    return this.entityApiService.postWithCredentials<void>(url, { visitDates: visitDates.map(visit => ({ from: visit.from.toISOString(), to: visit.to.toISOString() })) })
      .then(() => this.setAllOutdated())
  }
  public deleteTechnicianVisitIntervals = async ({ technicianId, visitDates }: TechnicianVisitIntervalCreateRequestDTO) => {
    const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.user.root}/${technicianId}/${PATH_BACKEND_PART.user.calendar}`;
    const body = JSON.stringify({ visitDates: visitDates.map(visit => ({ from: visit.from.toISOString(), to: visit.to.toISOString() })) })
    return this.entityApiService.delete(url, { credentials: 'include', body })
      .then(() => this.setAllOutdated())
  }
  public getTechnicianVisitIntervals = async ({ start, end, technicianId }: TechnicianVisitIntervalRequestDTO) => {
    const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.user.root}/${technicianId}/${PATH_BACKEND_PART.user.calendar}`;
    const dto: Partial<Record<'start' | 'end', string>> = {}
    if (start) dto.start = start.toISOString()
    if (end) dto.end = end.toISOString()
    const fullPath = NavigateBackendUtils.addParamsToExistedUrl(url, dto);
    return this.entityApiService.getWithCredentials<TechnicianVisitIntervalResponseDTO[]>(fullPath)
  }
  public getManyTechnicianVisitIntervals = async ({ end, start, technicianIds }: TechnicianManyVisitIntervalRequestDTO) => {
    const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.user.root}/${PATH_BACKEND_PART.user.calendar}`;
    let fullPath = NavigateBackendUtils.addParamsToExistedUrl(url, { start: start.toISOString(), end: end.toISOString() });
    fullPath = NavigateBackendUtils.addArrayParamsToExistedUrl(fullPath, { ids: technicianIds })
    return this.entityApiService.getWithCredentials<Record<string, TechnicianVisitIntervalResponseDTO[]>>(fullPath)
      .then(CalendarUtils.translateTechniciansVisitIntervalMapToEventsArray)
      .then(CalendarUtils.filterFormOrderVisitIntervals)
  }

  public createTechnicianTeamVisitIntervals = async ({ teamId, visitDates }: TechnicianTeamVisitIntervalCreateRequestDTO) => {
    const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.technicianTeam.root}/${teamId}/${PATH_BACKEND_PART.technicianTeam.calendar}`;
    return this.entityApiService.postWithCredentials<void>(url, { visitDates: visitDates.map(visit => ({ from: visit.from.toISOString(), to: visit.to.toISOString() })) })
      .then(() => this.setAllOutdated())
  }
  public deleteTechnicianTeamVisitIntervals = async ({ teamId, visitDates }: TechnicianTeamVisitIntervalCreateRequestDTO) => {
    const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.technicianTeam.root}/${teamId}/${PATH_BACKEND_PART.technicianTeam.calendar}`;
    const body = JSON.stringify({ visitDates: visitDates.map(visit => ({ from: visit.from.toISOString(), to: visit.to.toISOString() })) })
    return this.entityApiService.delete(url, { credentials: 'include', body })
      .then(() => this.setAllOutdated())
  }
  public getTechnicianTeamVisitIntervals = async ({ start, end, teamId }: TechnicianTeamVisitIntervalRequestDTO) => {
    const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.technicianTeam.root}/${teamId}/${PATH_BACKEND_PART.technicianTeam.calendar}`;
    const dto: Partial<Record<'start' | 'end', string>> = {}
    if (start) dto.start = start.toISOString()
    if (end) dto.end = end.toISOString()
    const fullPath = NavigateBackendUtils.addParamsToExistedUrl(url, dto);
    return this.entityApiService.getWithCredentials<TechnicianVisitIntervalResponseDTO[]>(fullPath)
  }
  public getManyTechnicianTeamVisitIntervals = async ({ end, start, teamIds }: TechnicianTeamManyVisitIntervalRequestDTO) => {
    const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.technicianTeam.root}/${PATH_BACKEND_PART.technicianTeam.calendar}`;
    let fullPath = NavigateBackendUtils.addParamsToExistedUrl(url, { start: start.toISOString(), end: end.toISOString() });
    fullPath = NavigateBackendUtils.addArrayParamsToExistedUrl(fullPath, { ids: teamIds })
    return this.entityApiService.getWithCredentials<Record<string, TechnicianVisitIntervalResponseDTO[]>>(fullPath)
      .then(CalendarUtils.translateTechniciansVisitIntervalMapToEventsArray)
      .then(CalendarUtils.filterFormOrderVisitIntervals)
  }
}

import DIContainer from 'services/DIContainer';
import {
  GetAvailableProvidersForAssignResponseDTO,
  GetAvailableProvidersForAssignResponseShortDTO,
  OrderResponseDTO,
} from 'typings/dto/order';
import { ORDER_DEFAULT_TYPE, ORDER_TYPE_ICON_NAME } from 'typings/models/order/orderType.enum';
import OrderUtils from 'utils/models/OrderUtils';
import UserUtils from 'utils/models/UserUtils';
import Mapper from './Mapper';
import DateWithTimezone from 'utils/implementations/DateWithTimezone';
import DateUtils from 'utils/Date';

export default class OrderMapper extends Mapper<Order, OrderResponseDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers, private dateUtils: DateUtils) {
    super(
      //temp
      {
        altId: 'altId',
        active: 'active',
        region: 'region',
        shop: 'shop',
        customer: 'customer',
        owner: 'owner',
        number: 'number',
        serviceProvider: 'serviceProvider',
        platformOperator: 'platformOperator',
        status: 'status',
        visitIntervals: 'visitDateIntervals',
        brand: 'brand',
        technicians: 'technicians',
        history: 'history',
        review: 'review',
        type: 'type',
        report: 'reports',
        tasks: 'tasks',
        comments: 'comments',
        contracts: 'contracts',
        address: 'address',
        department: 'department',
        legalEntity: 'legalEntity',
        techniciansNumberRequired: 'numberOfTechnicians',
        serviceItems: 'services',
        currency: 'currency',
        assignInfo: null,
        isFollowed: 'isFollowed',
        labels: 'labels',
        productDeliveryIntervals: 'deliveryIntervals',
        relations: 'relations',
        customFields: 'customFields',
        techniciansRewardSettings: 'techniciansRewardSettings',
        technicianTeam: 'technicianTeam',
        providerTransactions: 'providerTransactions',
        servicesTechniciansRewards: null,
        reclamationOrders: 'reclamationOrders',
        linkedOrders: 'linkedOrders',
        documents: 'documents',
        tables: 'tables',
        totalCategoryPrices: 'totalCategoryPrices',
        apartmentCode: 'apartmentCode',
        workIntervals: 'workIntervals',
      }
      // null
    );
  }

  public responseDTOToModel = (dto: OrderResponseDTO): Order => {
    const outerMappers = this.getOuterMappers();
    const timezone = dto.region.timezone;

    const orderType: EntityWithTenant & {
      logoId: ORDER_TYPE_ICON_NAME;
    } = {
      ...dto.type,
      technicalName: dto.type.technicalName,
      tenant: dto.type.tenant,
      logoId: dto.type.logoId,
    };
    const customer = dto.customer
      ? {
          phones: dto.customer.phones.map(this.subEntitiesMappers.phone.responseDTOToModel),
          name: dto.customer.name,
          technicalName: dto.customer.technicalName,
          balance: dto.customer.balance,
          email: dto.customer.email,
          passport: dto.customer.passport
            ? {
                ...dto.customer.passport,
              }
            : undefined,
          registrationAddress: dto.customer.registrationAddress,
          vip: dto.customer.vip,
          additionalContactInfo: dto.customer.additionalContactInfo,
          customerLegalEntity: dto.customer.customerLegalEntity,
        }
      : undefined;
    const status = {
      technicalName: dto.status.value,
      isProblematic: dto.status.problematic,
      isWorkStarted: dto.status.onTheJob,
      isWorkInProgress: dto.status.workInProgress,
      completionState: dto.status.completionState,
    };

    const visitDateIntervals = dto.visitIntervals.map((intervalDTO) => {
      const interval: Order.VisitDate = this.subEntitiesMappers.dateInterval.responseDTOToModel(intervalDTO, timezone);
      interval.workEstimateMin = intervalDTO.workEstimation?.durationMinutes;
      interval.completionState = intervalDTO.completionState;
      return interval;
    });

    const workIntervals = dto.workIntervals.map((intervalDTO) => {
      return {
        id: intervalDTO.id,
        from: new DateWithTimezone(intervalDTO.from, timezone, this.dateUtils),
        to: intervalDTO.to ? new DateWithTimezone(intervalDTO.to, timezone, this.dateUtils) : undefined,
      };
    });

    const customFieldMapper = this.subEntitiesMappers.orderCustomField;
    const customFields = Object.keys(dto.customFields).reduce((acc, technicalName) => {
      acc[technicalName] = customFieldMapper.responseDTOToModel(dto.customFields[technicalName], outerMappers);
      return acc;
    }, {} as Order['customFields']);
    // TODO нужно чтобы бек сразу присылал?
    dto.tasks.forEach((task) => {
      task.timezone = timezone;
    });

    const technicians = dto.technicians.map((u) => UserUtils.mapUserResponseByType(u, outerMappers)) as UserTechnician[];
    const services = dto.serviceItems.map(outerMappers.serviceOrdered.responseDTOToModel);

    const contracts = dto.contracts.map((contract) => {
      return {
        ...contract,
        readinessDate: contract.readinessDate ? new DateWithTimezone(contract.readinessDate, timezone, this.dateUtils) : undefined,
        warehouseShippingDate: contract.warehouseShippingDate
          ? new DateWithTimezone(contract.warehouseShippingDate, timezone, this.dateUtils)
          : undefined,
        finalSketch: contract.finalSketch ? contract.finalSketch.map((s) => this.subEntitiesMappers.file.responseDTOToModel_temp(s)) : [],
      };
    });

    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      customer,
      contracts,
      status,
      type: orderType,
      relations: dto.relations
        ? {
            ...dto.relations,
            isReclamation: orderType.technicalName === ORDER_DEFAULT_TYPE.complaint && Boolean(dto.relations.parent),
          }
        : undefined,
      address: dto.address ? this.subEntitiesMappers.address.responseDTOToModel(dto.address) : undefined,
      deliveryIntervals: dto.productDeliveryIntervals
        ? dto.productDeliveryIntervals.map((interval) => this.subEntitiesMappers.dateInterval.responseDTOToModel(interval, timezone))
        : [], //temp
      workIntervals,
      visitDateIntervals,
      labels: dto.labels.map(outerMappers.orderLabel.responseDTOToModel),
      history: dto.history.map(outerMappers.orderHistory.responseDTOToModel),
      review: dto.review && outerMappers.orderReview.responseDTOToModel(dto.review),
      reports: dto.report.map(outerMappers.orderReport.responseDTOToModel),
      tasks: dto.tasks.map(outerMappers.task.responseDTOToModel),
      comments: dto.comments.map(outerMappers.orderComment.responseDTOToModel),
      services,
      technicians,
      technicianTeam: dto.technicianTeam && outerMappers.technicianTeam.responseDTOToModel(dto.technicianTeam),
      customFields,
      serviceProviderInfo: {
        operatorType: dto.assignInfo.operatorType,
        assignType: dto.assignInfo.assignMethodType,
        providerType: dto.assignInfo.providerType,
        technicianAssignStatus: dto.assignInfo.technicianAssignStatus,
        assignAlgorithm: dto.assignInfo.assignAlgorithmType,
      },
      techniciansRewardSettings: dto.techniciansRewardSettings
        ? OrderUtils.countDefaultRewardSettings(dto.techniciansRewardSettings, technicians)
        : undefined,
      techniciansRewardValues:
        dto.servicesTechniciansRewards && OrderUtils.countRewardValuesSettings(dto.servicesTechniciansRewards, services),
      providerTransactions: dto.providerTransactions.map(outerMappers.orderProviderTransactionsData.responseDTOToModel),
      reclamationOrders: dto.reclamationOrders.map(this.responseDTOToModel),
      linkedOrders: dto.linkedOrders.map(this.responseDTOToModel),
      documents: dto.documents.map(outerMappers.orderDocument.responseDTOToModel),
      tables: dto.tables?.map(outerMappers.orderTable.responseDTOToModel),
    };
  };

  public getAvailableProvidersForAssignResponseToModel = (dto: GetAvailableProvidersForAssignResponseDTO) => {
    const outerMappers = this.getOuterMappers();
    return {
      users: dto.users.map((user) => UserUtils.mapUserResponseByType(user, outerMappers)),
      serviceProviders: dto.serviceProviders.map((sp) => outerMappers.serviceProvider.responseDTOToModel(sp)),
      teams: dto.internalTeams.map((t) => outerMappers.technicianTeam.responseDTOToModel(t)),
      platformOperators: dto.platformOperators.map((e) => outerMappers.enterprise.responseDTOToModel(e)),
    };
  };

  public getAvailableProvidersForAssignResponseToShortModel = (dto: GetAvailableProvidersForAssignResponseShortDTO) => {
    const outerMappers = this.getOuterMappers();

    return {
      users: dto.users.map((user) => UserUtils.mapUserResponseByType(user, outerMappers)),
      serviceProviders: dto.serviceProviders.map((sp) => outerMappers.serviceProvider.responseDTOToModel(sp)),
      teams: dto.internalTeams.map((t) => outerMappers.technicianTeam.responseDTOToShortModel(t)),
      platformOperators: dto.platformOperators.map((e) => outerMappers.enterprise.responseDTOToModel(e)),
    };
  };
}

// Т.к. фильтрация сейчас реализована через туркрафт, значения этого enum должны совпадать с полями в базе
// А ещё есть промежуточный этап пропуска этих полей через маппер на фронте (где-то описывал) (нужно вырезать к чёрту), поэтому надо следить ещё за ним
import { OrderResponseDTO } from '../../dto/order';

export enum ORDER_FILTER {
  status = 'status.value',
  completionState = 'status.completionState',
  visitCompletionState = 'visitDateIntervals.completionState',
  isProblematic = 'status.problematic',
  isWorkStarted = 'status.onTheJob',
  type = 'type.id',
  hasLinked = 'relations.links.id',
  linkedTypeId = 'relations.links.type.id',
  hasReclamation = 'relations.reclamations.id',

  active = 'active',
  archiveYear = 'archiveYear',

  brand = 'brand.id',
  region = 'region.id',
  providerType = 'provider', // Обрабатывается руками
  serviceProvider = 'serviceProvider.id',
  platformOperator = 'platformOperator.id',
  technicianAssignStatus = `technicianAssignStatus`, // Обрабатывается руками
  technician = 'technicians.id',
  /** Фильтрует по заказам, в которых есть таски на текущего пользователя */
  myTask = 'myTasks.status',
  /** Фильтрует по заказам, в которых есть таски на любых пользователей */
  task = 'tasks.status',
  department = 'departments.id',
  /** Заказ должен содержать эти лейблы */
  label = 'labels.technicalName',
  /** Тип выборки лейблов, содержат или не содержат */
  labelMatchTypeExcluded = 'labelMatchTypeExcluded',
  /** Поиск сразу по нескольким тестовым полям заказа (Номер заказа, Адрес, Телефоны клиента, Полное имя клиента) */
  search = 'search',

  installationDate = 'visitIntervals',
  installationDateType = 'installationDateType', // Обрабатывается руками

  deliveryDate = 'productDeliveryIntervals',
  deliveryDateType = 'deliveryDateType', // Обрабатывается руками

  creationDate = 'createdAt',
  creationDateType = 'creationDateType',

  /** Метка, сама значение не несёт */
  customField = 'customField',

  review = 'review.id',
  reviewText = 'review.text',
  rating = 'review.rating',

  enterprise = 'owner.id',

  contractType = 'contracts.contractType',
}

/** Названия полей, которых нет в dto order */
export const ORDER_FILTER_CUSTOM_FIELDS = new Set<string>([
  ORDER_FILTER.myTask,
  ORDER_FILTER.providerType,
  ORDER_FILTER.technicianAssignStatus,
  ORDER_FILTER.search,
  ORDER_FILTER.installationDateType,
  ORDER_FILTER.labelMatchTypeExcluded,
  ORDER_FILTER.deliveryDateType,
]);

export enum ORDER_FILTER_SPECIAL_VALUE {
  exist = 'exist',
  notExist = 'not-exist',
}

export enum ORDER_FILTER_DATE {
  between = 'between',
  afterCurrent = 'after-current',
  beforeCurrent = 'before-current',
  before = 'before',
  after = 'after',
  exist = 'exist',
  notExist = 'not-exist',
}
// ORDER_FILTER_SEARCH_FIELDS ---------------------------------------------------

type OrderNumberFieldName = Extract<keyof OrderResponseDTO, 'number' | 'altId'>;
type OrderCustomerFieldNames = `${Extract<keyof OrderResponseDTO, 'customer'>}.${keyof NonNullable<OrderResponseDTO['customer']>}`;
type OrderAddressFieldNames = `${Extract<keyof OrderResponseDTO, 'address'>}.${keyof NonNullable<OrderResponseDTO['address']>}`;
type OrderCommentFieldNames = `${Extract<keyof OrderResponseDTO, 'comments'>}.${keyof OrderResponseDTO['comments'][0]}`;
type OrderContractFieldName = `${Extract<keyof OrderResponseDTO, 'contracts'>}.${keyof OrderResponseDTO['contracts'][0]}`;
type OrderApartmentCodeFieldName = Extract<keyof OrderResponseDTO, 'apartmentCode'>;
type OrderTextSearchAvailableFieldNames =
  | OrderNumberFieldName
  | OrderCustomerFieldNames
  | OrderAddressFieldNames
  | OrderCommentFieldNames
  | OrderContractFieldName
  | OrderApartmentCodeFieldName;

/** Список полей, по которым идет поиск в поле поиска на странице заказов */
export const ORDER_FILTER_SEARCH_FIELDS: OrderTextSearchAvailableFieldNames[] = [
  'number',
  'customer.name',
  'address.addressLine1',
  'customer.phones',
  'customer.email',
  'comments.text',
  'contracts.contractId',
  'contracts.calculationId',
  'apartmentCode',
  'altId',
];

import { CreateEnterpriseDepartmentRequestDTO, EnterpriseDepartmentResponseDTO } from 'typings/dto/enterpriseDepartment';
import ModelActionsService from '../ModelActionsService';
import IDepartmentActionsService from './IDepartmentActionsService';
import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import NavigateBackendUtils from '../../../utils/NavigateBackend';

export default class DepartmentActionsServiceImpl
  extends ModelActionsService<EnterpriseDepartment, CreateEnterpriseDepartmentRequestDTO, EnterpriseDepartmentResponseDTO>
  implements IDepartmentActionsService
{
  /** @throws `BackendResponseError` */
  public getValuesFromOrderForFilter = (): Promise<void> => {
    const url = PATH_BACKEND.enterpriseDepartment.orderFilter;
    return this.getAllForFilter(undefined, undefined, url);
  };

  public getAllUnPaged(tenantId: string): Promise<Array<EnterpriseDepartmentResponseDTO>> {
    const url = NavigateBackendUtils.addParamsToExistedUrl(PATH_BACKEND.enterpriseDepartment.unPaged, { tenantId: tenantId });
    return this.entityApiService.getWithCredentials(url);
  }
}

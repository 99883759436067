import FrontendRoutesBase from '../dashboard/FrontendRoutesBase';
import IFrontendRoutes from '../dashboard/IFrontendRoutes';
import { PATH_PARAM_CLIENT, PAGE_TYPE, PATH_PARAM_TOKEN } from '../PATH_PARAM';

export default class ClientOrderRoutes extends FrontendRoutesBase {
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.client> = (params) =>
    this.getPath(PATH_PARAM_CLIENT.root, `${PATH_PARAM_CLIENT.orders}/${params.orderId}/${PAGE_TYPE.info}`);

  getCommentsPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.client> = (params) =>
    this.getPath(PATH_PARAM_CLIENT.root, `${PATH_PARAM_CLIENT.orders}/${params.orderId}/${PATH_PARAM_CLIENT.comments}`);

  getPaidSuccessfullyPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.client> = (params) =>
    this.getPath(PATH_PARAM_CLIENT.root, `${PATH_PARAM_CLIENT.orders}/${params.orderId}/${PATH_PARAM_CLIENT.paidSuccessfully}`);

  getPaidUnsuccessfullyPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.client> = (params) =>
    this.getPath(PATH_PARAM_CLIENT.root, `${PATH_PARAM_CLIENT.orders}/${params.orderId}/${PATH_PARAM_CLIENT.paidUnsuccessfully}`);

  getRatePath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.client> = (params) =>
    this.getPath(PATH_PARAM_CLIENT.root, `${PATH_PARAM_CLIENT.orders}/${params.orderId}/${PATH_PARAM_CLIENT.rate}`);

  getServicesPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.client> = (params) =>
    this.getPath(PATH_PARAM_CLIENT.root, `${PATH_PARAM_CLIENT.orders}/${params.orderId}/${PATH_PARAM_CLIENT.services}`);
}

export default class CookieUtils {
  private static domain = getCurrentDomain();
  private static cookieMaxAge = 10 * 24 * 60 * 60; // temp todo

  public static getNamedCookie = (name: string): string | null => {
    return (
      decodeURIComponent(
        document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'))?.[1] || ''
      ) || null
    );
  };

  public static setNamedCookie = (name: string, value: string, maxAgeMin?: number, path?: string) => {
    const cookieMaxAge = maxAgeMin !== undefined ? maxAgeMin * 60 : this.cookieMaxAge;
    const options = [
      `${encodeURIComponent(name)}=${encodeURIComponent(value)}`,
      `path=${path ?? '/'}`,
      `max-age=${cookieMaxAge}`,
      `domain=${this.domain}`,
    ];
    const cookie = options.join('; ');
    document.cookie = cookie;
  };
}

function getCurrentDomain() {
  let domain = window.location.hostname || 'localhost';
  const subDomains = domain.split('.');
  // Отрезаем домен третьего+ уровня
  if (subDomains.length > 2) domain = subDomains.splice(subDomains.length - 2).join('.');
  return domain;
}

import { Button, Stack, TextField, Typography } from '@mui/material';
import CopyToClipboardButton from 'components/ui/buttons/CopyToClipboardButton';
import PopupContent from 'components/ui/popups/PopupContent';
import useDI from 'hooks/useDI';
import useLocales from 'hooks/useLocales';
import React, { useState } from 'react';
import { useSelector } from 'storage';
import ConfirmButtonGroup from 'components/ui/forms/ConfirmButtonGroup';

type Props = {
  isResetPopupOpened: boolean;
  closePopup: () => void;
};

export default function CreateSuperadminApiKeyButton({ isResetPopupOpened, closePopup }: Props) {
  const { currentUser } = useSelector((state) => state.auth);
  const { storageActions } = useDI();
  const { translate } = useLocales();
  const [newTechnicalName, setNewTechnicalName] = useState('');

  const [isResponsePopupOpened, setIsResponsePopupOpened] = useState(false);
  const [newApiKey, setNewApiKey] = useState('');

  const resetHandler = async () => {
    const code = await storageActions.models.userSuperadmin.createApiUserKey({
      tenantId: currentUser?.tenant.id || '',
      technicalName: newTechnicalName,
    });
    setNewApiKey(code);
    closePopup();
    setIsResponsePopupOpened(true);
  };

  return (
    <>
      <PopupContent
        isOpened={isResetPopupOpened}
        closeHandler={() => closePopup()}
        title={translate('pages.superadmin.apiSettings.createTitle')}
      >
        <TextField
          sx={{ mt: 2 }}
          label={translate('pages.superadmin.apiSettings.keyTechnicalName')}
          onChange={(e) => setNewTechnicalName(e.target.value as string)}
          fullWidth
        />
        <ConfirmButtonGroup
          sx={{ m: 0 }}
          cancelHandler={closePopup}
          submitHandler={resetHandler}
          submitText={translate('buttons.create')}
          isLoading={false}
          fullWidth
        />
      </PopupContent>

      <PopupContent
        isOpened={isResponsePopupOpened}
        closeHandler={() => setIsResponsePopupOpened(false)}
        title={translate('pages.enterpriseApiSettingsEdit.resetApiKeyAndGetNewTitle')}
      >
        <Typography variant="body1" color="text.secondary">
          {translate('pages.enterpriseApiSettingsEdit.resetApiKeyAndGetNewResponseTip')}
        </Typography>
        <Stack direction="row" spacing={1}>
          <TextField value={newApiKey} sx={{ width: '100%' }} />
          <CopyToClipboardButton copyText={newApiKey} variant="outlined" color="inherit" sx={{ px: 3 }}>
            {translate('buttons.copy')}
          </CopyToClipboardButton>
        </Stack>
        <Stack alignItems="flex-start">
          <Button variant="contained" sx={{ mt: 3 }} onClick={() => setIsResponsePopupOpened(false)}>
            {translate('buttons.confirm')}
          </Button>
        </Stack>
      </PopupContent>
    </>
  );
}

import Mapper from './Mapper';
import DIContainer from 'services/DIContainer';
import { RecommendationConfigResponseDTO } from 'typings/dto/recommendationsConfig';

export default class RecommendationsConfigMapper extends Mapper<RecommendationsConfig, RecommendationConfigResponseDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super({
      hash: 'hash',
      version: 'version',
      tenantId: 'tenantId',
      updatedBy: 'updatedBy',
      recommendationsConfig: null,
    });
  }

  public responseDTOToModel(dto: RecommendationConfigResponseDTO): RecommendationsConfig {
    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      recommendationsConfig: {
        recommendations: dto.recommendationsConfig.recommendations,
        predefined: dto.recommendationsConfig.predefined,
      },
      version: dto.version,
      tenantId: dto.tenantId,
      updatedBy: dto.updatedBy,
    };
  }

  public modelToRequestDTO(model: RecommendationsConfig): RecommendationConfigResponseDTO {
    return {
      id: model.id,
      createdAt: model.createdAt.toISOString(),
      updatedAt: model.updatedAt.toISOString(),
      recommendationsConfig: {
        recommendations: model.recommendationsConfig.recommendations,
        predefined: model.recommendationsConfig.predefined,
      },
      version: model.version,
      tenantId: model.tenantId,
      updatedBy: model.updatedBy,
      hash: model.hash || '',
    };
  }
}

/** Зарезервированные слова для названия типа страницы, с завязанной на это определённой логикой */
export enum PAGE_TYPE {
  list = 'list',
  info = 'info',
  edit = 'edit',
  add = 'add',
  /** Костыль для add, используется совместно с ним, такая страница не является ни страницей списка, ни сущности, и не тригерит никакие хуки */
  new = 'new',
}

/** @see PATH_PARAM */
export const PATH_PARAM_DASHBOARD = {
  root: 'dashboard',
  me: {
    root: 'me',
    info: PAGE_TYPE.info,
  },
  order: {
    root: 'orders',
    main: 'main',
    services: 'services',
    report: 'report',
    review: 'review',
    tasks: 'tasks',
    history: 'history',
    providerTransactions: 'provider-transactions',
    documents: 'documents',
    goodInformation: 'good-information',
    comments: 'comments',
    complaint: 'complaint',
    warranty: 'warranty',
    linked: 'linked',
    session: 'session',
  },
  calendar: {
    root: 'calendar',
    order: 'order',
  },
  service: {
    root: 'service-catalog',
  },
  administration: {
    root: 'administration',
  },
  superadmin: {
    root: 'superadmin',
    apiSettings: 'api',
    settings: 'settings',
  },
  enterpriseAccount: {
    root: 'enterprise-account',
    employees: 'employees',
  },
  platformOperatorAccount: {
    root: 'platform-operator-account',
    employees: 'employees',
  },
  serviceProviderAccount: {
    root: 'service-providers-account',
    employees: 'employees',
  },
  call: {
    root: 'calls',
  },
  contractSP: {
    root: 'contract-sp',
    prices: 'prices',
    legalEntity: 'legal-entity',
    reviews: 'reviews',
    employees: 'employees',
  },
  contractPO: {
    root: 'contract-po',
    prices: 'prices',
    legalEntity: 'legal-entity',
    reviews: 'reviews',
  },
  enterprise: {
    root: 'enterprise',
    brands: 'brands',
    orderCustomFields: 'order-custom-fields',
    legalEntities: 'legal-entities',
    notifications: 'notifications',
    uiConsole: 'uiConsole',
    employees: 'employees',
    departments: 'departments',
    settings: PAGE_TYPE.info,
    orderSettings: 'order-settings',
    serviceCompanies: 'service-companies',
    servicePackages: 'service-packages',
    services: 'services',
    technicians: 'technicians',
    regions: 'regions',
    shops: 'shops',
    technicianTeams: 'technician-teams',
    apiSettings: 'api',
    technicianSettings: 'technician-settings',
  },
  promotionsConfig: {
    root: 'promo',
    promotions: 'promotions',
    categories: 'categories',
    aggregation: 'aggregation',
    variables: 'variables',
  },
  brand: {
    info: PAGE_TYPE.info,
    telephony: 'telephony',
    apiSettings: 'api-settings',
    widgetServiceSettings: 'widget-service-settings',
    clientsApp: 'clients-app',
  },
  enterpriseEmployee: {
    info: PAGE_TYPE.info,
    legalEntity: 'legal-entity',
    notifications: 'notifications',
  },
  serviceProvider: {
    root: 'service-providers',
    mainInfo: PAGE_TYPE.info,
    legalInfo: 'legal',
    notifications: 'notifications',
    reviewList: 'reviews',
    employees: 'employees',
    departments: 'departments',
  },
  scEmployee: {
    info: PAGE_TYPE.info,
    notifications: 'notifications',
    reviews: 'reviews',
    categories: 'services',
    regionServices: 'region-services',
  },
  invoiceProvider: {
    root: 'invoice-provider',
    // enterprise: 'enterprise',
    // serviceProvider: 'service-company',
    orders: 'orders',
  },
  invoicePO: {
    root: 'invoice-platform-operator',
    enterprise: 'enterprise',
    serviceProvider: 'service-company',
    orders: 'orders',
  },
  user: {
    notifications: 'notifications',
  },
  widget: {
    root: 'widget',
    settings: 'settings',
    match: 'match',
    serviceVisibility: 'service-visibility',
  },
  task: {
    root: 'tasks',
  },
  price: {
    root: 'price',
    client: 'client',
    technician: 'technician',
    serviceProvider: 'service-provider',
    platformOperator: 'platform-operator',
    recommendedPrice: 'recommended-client-price',
    default: 'default',
  },
  fundsTransaction: {
    root: 'funds-transactions',
    addition: 'addition',
    deduction: 'deduction',
  },
  recommendationConfig: {
    root: 'recommendation',
    rules: 'rules',
    variables: 'variables',
  },
  orderSession: {
    warranty: 'warranty',
    complaint: 'complaint',
    linked: 'linked',
  },
  archive: {
    root: 'archive',
  },
};

export const PATH_PARAM_CLIENT = {
  root: 'client',
  comments: 'comments',
  orders: 'orders',
  rate: 'rate',
  services: 'services',
  /** @todo delete? */
  paidSuccessfully: 'paid-successfully',
  /** @todo delete? */
  paidUnsuccessfully: 'paid-unsuccessfully',
};

export const PATH_PARAM_AUTH = {
  root: 'auth',
  login: 'login',
  resetPassword: 'reset-password',
  profile: 'profile',
  spInvitation: 'service-provider-invitation',
};

/** Как в реакт роутере названы те или иные холдеры id'шников в адресе */
export const PATH_PARAM_TOKEN = {
  calendar: {
    orderId: 'orderId',
    viewType: 'viewType', // browsing | appointment
    serviceId: 'serviceId',
    reportId: 'reportId',
    taskId: 'taskId',
    providerTransactionId: 'providerTransactionId',
  },
  client: {
    orderId: 'orderId',
  },
  fundsTransaction: {
    fundsTransactionId: 'fundsTransactionId',
    type: 'type',
    group: 'group',
  },
  serviceProvider: {
    serviceProviderId: 'serviceProviderId',
    employeeId: 'employeeId',
    departmentId: 'departmentId',
  },
  serviceProviderAccount: {
    serviceProviderId: 'serviceProviderId',
    userId: 'userId',
  },
  widget: {
    enterpriseId: 'enterpriseId',
    brandId: 'brandId',
  },
  invoice: {
    invoiceId: 'invoiceId',
  },
  city: {
    cityId: 'cityId',
  },
  administration: {
    userId: 'userId',
  },
  superadmin: {
    userId: 'userId',
  },
  contract: {
    contractId: 'contractId',
    employeeId: 'employeeId',
  },
  enterprise: {
    enterpriseId: 'enterpriseId',
    brandId: 'brandId',
    legalEntityId: 'legalEntityId',
    departmentId: 'departmentId',
    userId: 'userId',
    technicianId: 'technicianId',
    technicianTeamId: 'technicianTeamId',
    regionId: 'regionId',
    shopId: 'shopId',
    customFieldId: 'customFieldId',
    servicePackageId: 'servicePackageId',
    serviceCategoryGroupId: 'serviceCategoryGroupId',
    serviceCategoryId: 'serviceCategoryId',
    serviceId: 'serviceId',
  },
  promotionsConfig: {
    promotionId: 'promotionId',
    categoriesId: 'categoriesId',
  },
  enterpriseAccount: {
    enterpriseId: 'enterpriseId',
    userId: 'userId',
  },
  platformOperatorAccount: {
    platformOperatorId: 'platformOperatorId',
    userId: 'userId',
  },
  service: {
    enterpriseId: 'enterpriseId',
    serviceId: 'serviceId',
  },
  order: {
    orderViewTechName: 'orderViewTechName',
    orderId: 'orderId',
    serviceId: 'serviceId',
    reportId: 'reportId',
    taskId: 'taskId',
    providerTransactionId: 'providerTransactionId',
  },
  price: {
    priceId: 'priceId',
    pricelistId: 'pricelistId',
  },
  recommendationConfig: {
    ruleId: 'ruleId',
  },
  orderSession: {
    orderSessionId: 'orderSessionId',
    orderId: 'orderId',
  },
  archive: {
    orderViewTechName: 'orderViewTechName',
    orderId: 'orderId',
    serviceId: 'serviceId',
    reportId: 'reportId',
    taskId: 'taskId',
    providerTransactionId: 'providerTransactionId',
    archiveYear: 'archiveYear',
  },
};

/** Кусочки адреса фронта в виде констант */
const PATH_PARAM = {
  dashboard: PATH_PARAM_DASHBOARD,
  client: PATH_PARAM_CLIENT,
  auth: PATH_PARAM_AUTH,
  token: PATH_PARAM_TOKEN,
};

export default PATH_PARAM;

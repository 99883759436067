import {
  CreateSuperAdminUserApiKeyRequestDTO,
  CreateUserSuperadminRequestDTO,
  GetSuperAdminApiUserListRequestDTO,
  ResetSuperAdminUserApiKeyRequestDTO,
  UserSuperadminResponseDTO,
} from 'typings/dto/userSuperadmin';
import ModelActionsService from '../ModelActionsService';
import IUserSuperadminActionsService from './IUserSuperadminActionsService';
import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import NavigateFrontendUtils from 'utils/NavigateFrontend';

export default class UserSuperadminActionsServiceImpl
  extends ModelActionsService<UserSuperadmin, CreateUserSuperadminRequestDTO, UserSuperadminResponseDTO>
  implements IUserSuperadminActionsService
{
  protected getDefaultSortFilter<T extends LocationSearchObject>(
    filter?: T,
    sortFieldName: keyof UserSuperadminResponseDTO = 'fullName' as keyof UserSuperadminResponseDTO,
    sortOrder: NavigateFrontendUtils.SORT_ORDER = NavigateFrontendUtils.SORT_ORDER.asc
  ): T {
    return super.getDefaultSortFilter(filter, sortFieldName, sortOrder);
  }
  /** @throws `BackendResponseError` */
  getApiUserList(dto: GetSuperAdminApiUserListRequestDTO): Promise<EntityWithTenant[]> {
    const url =
      PATH_BACKEND.superAdmin.root +
      '/' +
      dto.tenantId +
      '/' +
      PATH_BACKEND_PART.superAdmin.settings +
      '/' +
      PATH_BACKEND_PART.superAdmin.api +
      '/users';
    return this.entityApiService.getWithCredentials(url);
  }

  /** @throws `BackendResponseError` */
  resetApiUserKey(dto: ResetSuperAdminUserApiKeyRequestDTO): Promise<string> {
    const url =
      PATH_BACKEND.superAdmin.root +
      '/' +
      dto.tenantId +
      '/' +
      PATH_BACKEND_PART.superAdmin.settings +
      '/' +
      PATH_BACKEND_PART.superAdmin.api +
      '/' +
      'users' +
      '/' +
      dto.userId +
      '/reset-api-key';
    return this.entityApiService.postWithCredentials<string>(url);
  }

  /** @throws `BackendResponseError` */
  createApiUserKey(dto: CreateSuperAdminUserApiKeyRequestDTO): Promise<string> {
    const url =
      PATH_BACKEND.superAdmin.root +
      '/' +
      dto.tenantId +
      '/' +
      PATH_BACKEND_PART.superAdmin.settings +
      '/' +
      PATH_BACKEND_PART.superAdmin.api +
      '/' +
      'users' +
      '/generate-api-key';
    return this.entityApiService.postWithCredentials<string>(url, { technicalName: dto.technicalName });
  }

  /** @throws `BackendResponseError` */
  public loginFromUser = (userId: string): Promise<void> => {
    return this.entityApiService.postWithCredentials(PATH_BACKEND.root + '/auth/update-current-session', userId);
  };
}

import NavigateFrontendUtils from './NavigateFrontend';
import { ORDER_FILTER_SEARCH_FIELDS } from '../typings/models/order/orderFilter.enum';
import { SERVICE_FILTER_SEARCH_FIELDS } from 'typings/models/service.enum';

export default class NavigateBackendUtils {
  public static readonly DEFAULT_PAGE_SIZE = 20;
  public static readonly MAX_PAGE_SIZE = 9999;
  /** Беку нужно, чтобы все названия полей модели окружали спец символом */
  private static readonly MODEL_PROP_SYMBOL = '$';

  // Работа с RequestFilter -------------------------------------------------------------------------

  public static createDBRequestStrWithFilter = (pathname: string, filter?: RequestFilter) => {
    if (!filter) return pathname;
    const filterKeys = Object.keys(filter);
    if (!filterKeys.length) return pathname;

    const filterWithoutFrontendData = { ...filter };
    delete filterWithoutFrontendData.frontendSearchParams;
    const paramsStr = this.createSearchStrFromKeyValueObject(filterWithoutFrontendData);
    const paramsSymbol = pathname.includes('?') ? '&' : '?';
    return `${pathname}${paramsSymbol}${paramsStr}`;
  };

  /**
   * Собирает все location.search из фронтенда и собирает их в несколько параметров запроса, главный из которых filter вида: name~~'*test*' and ... (либо name in ('a', 'b')) (внутри есть ещё примеры)
   * @param options LocationSearchObjectOptions
   * @returns RequestFilter
   */
  public static parseRequestFilterFromLocationParams = (
    options?: LocationSearchObjectOptions
  ): RequestFilter => {
    const locationSearchStr = window.location.search;
    if (!locationSearchStr) return {};
    const paramsObject = NavigateFrontendUtils.getLocationSearchObjectFromCurrentLocation(options);
    return this.locationSearchObjectToRequestFilter(paramsObject);
  };

  public static locationSearchObjectToRequestFilter = (
    urlSearchObject: LocationSearchObject,
  ): RequestFilter => {
    const requestFilter: RequestFilter = {};
    if (!Object.values(urlSearchObject).length) return requestFilter;
    requestFilter.frontendSearchParams = '?' + NavigateFrontendUtils.createSearchStrFromLocationSearchObject(urlSearchObject);

    if (urlSearchObject.page) {
      const pageNumber = +urlSearchObject.page.rawValue;
      if (!Number.isNaN(pageNumber)) {
        requestFilter.page = pageNumber;
        delete urlSearchObject.page;
      }
    }

    if (urlSearchObject.size) {
      const pageSize = +urlSearchObject.size.rawValue;
      if (!Number.isNaN(pageSize)) {
        requestFilter.size = pageSize;
        delete urlSearchObject.size;
      }
    }

    this.applySortQueryParam(urlSearchObject, requestFilter);

    if (urlSearchObject[NavigateFrontendUtils.FORCE_RELOAD]) {
      delete urlSearchObject[NavigateFrontendUtils.FORCE_RELOAD];
    }

    const paramKeys = Object.keys(urlSearchObject).sort();
    const filterParams = [] as string[];
    // старый пример: ?page=1&size=2&sort=createdAt,ASC&search=email:test@gmail.com,banned:false
    // новый пример ?filter=$user.name$~~'*test*' and (value:'test_code' and age:'777') and code in ['a', 'b']
    paramKeys.forEach((key) => {
      const urlSearchParam = urlSearchObject[key];
      if (!urlSearchParam?.isNotEmpty) {
        return;
      }

      const mappedKey = this.wrapModelProp(key);

      if (urlSearchParam.valueType === 'array') {
        const values = urlSearchParam.values.map(NavigateBackendUtils.escapeCharacters);
        switch (urlSearchParam.matchType) {
          // unique case for OR search in orders
          case 'ordrs': {
            const names = NavigateBackendUtils.escapeCharacters(urlSearchParam.values.join(',')).trim().split(' ');
            let orderFilter = '';
            if (names.length === 1) {
              orderFilter = `${ORDER_FILTER_SEARCH_FIELDS.map((field) => {
                return names.map((n) => `${this.wrapModelProp(field)}~~'*${n}*'`).join(' and ');
              }).join(' or ')}`;
            } else {
              orderFilter = `${ORDER_FILTER_SEARCH_FIELDS.map((field) => {
                return `${this.wrapModelProp(field)}~~'*${names.join(' ')}*'`;
              }).join(' or ')}`;
            }
            filterParams.push(`(${orderFilter})`);
            break;
          }
          case 'services': {
            const names = NavigateBackendUtils.escapeCharacters(urlSearchParam.values.join(',')).trim().split(' ');
            let serviceFilter = '';
            if (names.length === 1) {
              serviceFilter = `${SERVICE_FILTER_SEARCH_FIELDS.map((field) => {
                return names.map((n) => `${this.wrapModelProp(field)}~~'*${n}*'`).join(' and ');
              }).join(' or ')}`;
            } else {
              serviceFilter = `${SERVICE_FILTER_SEARCH_FIELDS.map((field) => {
                return `${this.wrapModelProp(field)}~~'*${names.join(' ')}*'`;
              }).join(' or ')}`;
            }
            filterParams.push(`(${serviceFilter})`);
            break;
          }
          // Вроде не работает
          case 'eq': {
            filterParams.push('(' + values.map((v) => `exists(${mappedKey}:'${v}')`).join(' and ') + ')');
            break;
          }
          case 'ni': {
            filterParams.push(`not(${mappedKey} in[${values.map((v) => `'${v}'`).join(',')}])`);
            break;
          }
          case 'nl': {
            filterParams.push(`${mappedKey} is empty`);
            break;
          }
          case 'nn': {
            filterParams.push(`${mappedKey} is not empty`);
            break;
          }
          case 'be': {
            const [v1, v2] = values;
            if (!v1 || !v2) {
              break;
            }
            filterParams.push(`${mappedKey}>:'${v1}'`);
            filterParams.push(`${mappedKey}<:'${v2}'`);
            break;
          }
          case 'in':
          default: {
            filterParams.push(`${mappedKey} in[${values.map((v) => `'${v}'`).join(',')}]`);
            break;
          }
        }
      } else {
        const value = urlSearchParam.value && NavigateBackendUtils.escapeCharacters(urlSearchParam.value);
        switch (urlSearchParam.matchType) {
          case 'como': {
            filterParams.push(`${mappedKey}~~'*${value}*'`);
            break;
          }
          case 'co': {
            filterParams.push(`${mappedKey}~~'*${value}*'`);
            break;
          }
          case 'gt': {
            filterParams.push(`${mappedKey}>'${value}'`);
            break;
          }
          case 'ge': {
            filterParams.push(`${mappedKey}>:'${value}'`);
            break;
          }
          case 'lt': {
            filterParams.push(`${mappedKey}<'${value}'`);
            break;
          }
          case 'le': {
            filterParams.push(`${mappedKey}<:'${value}'`);
            break;
          }
          case 'ne': {
            filterParams.push(`${mappedKey}!'${value}'`);
            break;
          }
          // Сюда больше не попадёт, т.к. single и in не пропустит парсер
          // case 'in': {
          //   acc.push(`${mappedKey} in ('${value}')`);
          //   break;
          // }
          case 'nl': {
            filterParams.push(`${mappedKey} is null`);
            break;
          }
          case 'nn': {
            filterParams.push(`${mappedKey} is not null`);
            break;
          }
          // unique case for OR search in orders
          case 'ordrs': {
            const names = value?.trim().split(' ') || [];

            let orderFilter = '';
            if (names.length === 1) {
              orderFilter = `${ORDER_FILTER_SEARCH_FIELDS.map((field) => {
                return names.map((n) => `${this.wrapModelProp(field)}~~'*${n}*'`).join(' and ');
              }).join(' or ')}`;
            } else {
              orderFilter = `${ORDER_FILTER_SEARCH_FIELDS.map((field) => {
                return `${this.wrapModelProp(field)}~~'*${names.join(' ')}*'`;
              }).join(' or ')}`;
            }
            filterParams.push(`(${orderFilter})`);
            break;
          }
          case 'services': {
            const names = value?.trim().split(' ') || [];

            let serviceFilter = '';
            if (names.length === 1) {
              serviceFilter = `${SERVICE_FILTER_SEARCH_FIELDS.map((field) => {
                return names.map((n) => `${this.wrapModelProp(field)}~~'*${n}*'`).join(' and ');
              }).join(' or ')}`;
            } else {
              serviceFilter = `${SERVICE_FILTER_SEARCH_FIELDS.map((field) => {
                return `${this.wrapModelProp(field)}~~'*${names.join(' ')}*'`;
              }).join(' or ')}`;
            }
            filterParams.push(`(${serviceFilter})`);
            break;
          }
          // unique match case for user full name
          case 'usfn': {
            const names =
              value
                ?.trim()
                .split(' ')
                .map((n) => n.replace(/'/g, '')) || [];

            const variants = [
              '',
              `(${names.map((n) => `${this.wrapModelProp('firstName')}~~'*${n}*'`).join(' or ')}) or (${names
                .map((n) => `${this.wrapModelProp('lastName')}~~'*${n}*'`)
                .join(' or ')})`,
              `(${names.map((n) => `${this.wrapModelProp('firstName')}~~'*${n}*'`).join(' or ')}) and (${names
                .map((n) => `${this.wrapModelProp('lastName')}~~'*${n}*'`)
                .join(' or ')})`,
              `(${names.map((n) => `${this.wrapModelProp('firstName')}~~'*${n}*'`).join(' or ')}) and (${names
                .map((n) => `${this.wrapModelProp('lastName')}~~'*${n}*'`)
                .join(' or ')}) and (${names.map((n) => `${this.wrapModelProp('middleName')}~~'*${n}*'`).join(' or ')})`,
            ];
            const nameSearchParam = variants[names.length]; // сокращенный switch case
            filterParams.push(`(${nameSearchParam})`);
            break;
          }
          case 'eq':
          default: {
            filterParams.push(`${mappedKey}:'${value}'`);
            break;
          }
        }
      }
    });

    requestFilter.filter = filterParams.length > 0 ? filterParams.join(' and ') : undefined;
    return requestFilter;
  };

  /** Мутирует объекты paramsObject и requestFilter, удаляет сорт в первом и добавляет во второй */
  public static applySortQueryParam = (
    urlSearchObject: LocationSearchObject,
    requestFilter: RequestFilter,
  ) => {
    const { sortedBy, sortOrder } = urlSearchObject;
    delete urlSearchObject.sortedBy;
    delete urlSearchObject.sortOrder;

    if (sortedBy) {
      let sortField: string | null;
      if (sortedBy.matchType === 'usfn') {
        // Обрабатываем кейс с полным именем юзера при сортировке по нему
        sortField = sortedBy.rawValue;
      } else {
        const mappedKey = sortedBy.rawValue;
        sortField = this.wrapModelProp(mappedKey);
      }
      requestFilter.sort = sortField;

      if (sortOrder) {
        requestFilter.sort = `${requestFilter.sort},${sortOrder.rawValue}`;
      }
    }
  };

  // Работа напрямую со строкой ---------------------------------------------------------------------

  private static createSearchStrFromKeyValueObject = (paramsObject: Record<string, string | number | boolean>): string => {
    const paramKeys = Object.keys(paramsObject);
    return `${paramKeys
      .sort()
      .reduce((acc, key) => {
        const paramValue = paramsObject[key];
        if (paramValue) acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(`${paramValue}`)}`);
        return acc;
      }, [] as string[])
      .join('&')}`;
  };

  public static addParamsToExistedUrl = (locationStr: string, params: Record<string, string | number | boolean>): string => {
    const paramKeys = Object.keys(params).filter((key) => params[key] !== undefined);
    if (!paramKeys.length) {
      return locationStr;
    }

    const pivotSymbol = locationStr.includes('?') ? '&' : '?';
    const updatedStr =
      locationStr + pivotSymbol + paramKeys.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
    return updatedStr;
  };

  public static addArrayParamsToExistedUrl = (locationStr: string, params: Record<string, string[] | number[]>): string => {
    const paramKeys = Object.keys(params).filter((key) => params[key] !== undefined);
    if (!paramKeys.length) {
      return locationStr;
    }

    const pivotSymbol = locationStr.includes('?') ? '&' : '?';
    const updatedStr =
      locationStr + pivotSymbol + paramKeys.map((key) => params[key].map((p) => `${encodeURIComponent(key)}=${encodeURIComponent(p)}`).join('&'));
    return updatedStr;
  };

  // Utils ----------------------------------------------------------------------------------------

  /** @see MODEL_PROP_SYMBOL */
  private static wrapModelProp = (props: string): string => {
    return this.MODEL_PROP_SYMBOL + props + this.MODEL_PROP_SYMBOL;
  };

  /** Бековый фильтр не переваривает некоторые символы (слеш и кавычку), но если их экранировать, то всё начинает работать */
  private static escapeCharacters = (str: string): string => {
    return str.replace(/['\\]/g, '\\$&');
  };
}

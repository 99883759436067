import useLocales from 'hooks/useLocales';
import { RoutingServiceDashboard } from 'services/routing/RoutingService';
import { PATH_PARAM_DASHBOARD } from 'services/routing/PATH_PARAM';
import { useMemo } from 'react';
import { useSelector } from 'storage';
import { FIELD_PERMISSION } from 'configs/permissions/fieldPermissions';

export default function useSuperAdminTabs() {
  const { permissions } = useSelector((state) => state.auth);
  const { translate } = useLocales();
  return useMemo(() => {
    const tabs: TabData[] = [];
    if (permissions.can(FIELD_PERMISSION.CAN_READ_ENTERPRISE_API_SETTINGS)) {
      tabs.push({
        value: PATH_PARAM_DASHBOARD.superadmin.apiSettings,
        href: RoutingServiceDashboard.superadmin.getApiSettingsInfoPath(),
        label: translate('pages.superadmin.tabs.apiSettings'),
      });
    }

    return tabs;
  }, []);
}

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, ButtonProps } from '@mui/material';
import { FileIcon } from '../icons';
import useLocales from 'hooks/useLocales';

type Props = ButtonProps & {
  copyText: string;
};

export default function CopyToClipboardButton({ copyText, children, ...restProps }: Props) {
  const { translate } = useLocales();

  return (
    <CopyToClipboard text={copyText} onCopy={() => {}}>
      <Button startIcon={<FileIcon />} {...restProps}>
        {children || translate('pages.clientRate.copyText')}
      </Button>
    </CopyToClipboard>
  );
}

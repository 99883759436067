import IServerEventsService from '../../services/serverEvents/IServerEventsService';

/**
 * Свой класс-прослойка, который реализует стандартный EventSource, но под капотом менеджит соединения таким образом,
 * чтобы из всех вкладок с сервером работала только одна, остальные получали все сообщения от неё через BroadcastChannel
 * @version 1.0 При создании инстанса создаётся отложенный коннект к серверу по sse, у какой вкладки он сработает первым, та и станет главной, остальным будет отправлена команда сбросить таймер.
 * Затем главная будет постоянно с интервалом слать команды на сброс, а если с ней что-то случиться, то сработает отложенное подключение на другой вкладке.
 * Интервал ставится максимально небольшой (3 сек), чтобы быстро кому-то подключиться изначально и в случае закрытия какой-то вкладки.
 * Неудобство способа в том, что браузер со временем начинает замораживать вкладки и интервалы не успевают друг друга оповещать, поэтому вкладки время от времени начинают подключиться к серверу, несмотря на то, что есть живой лид.
 * @version 1.1 Изменился алгоритм начального поведения: раньше инстанс в момент создания создавал отложенное подключение (таймаут) к серверу и в течении LEAD_INTERVAL_SEC
 * ждал, не придёт ли ему leadHeartbeat от другой вкладки, и тогда своё отложенное подключение сбрасывал, иначе подключался. Но такой подход плохо работает с большими интервалами, т.к. при первой загрузке вкладка
 * долго будет не синхронизирована. Если уменьшать интервал, то тогда вкладки некорректно работают, когда их морозит браузер. Сейчас каждая новая вкладка сразу делает запрос, есть ли лидер, и если не получает ответа в короткий срок, то сама становится им и уведомляет остальных.
 * Также интервал отправки leadHeartbeat уменьшен вдвое по сравнению с интервалом на переподключение к серверу, это увеличивает вероятность не сбиться всему алгоритму при заморозке вкладок браузером.
 * Появилась проблема, когда закрыли одну вкладку, перешли на другую, что-то сделали там, но sse соединение ещё не успело установиться, поэтому при закрытии вкладки по BroadcastChannel отправляется спец сообщение о необходимости перевыборов (даже если вдруг такое сообщение не дойдёт, то остаётся возможность подключения старым способом по истечении таймаута).
 * Описания полей остались от версии 1.0 и могут не отражать эту новую логику.
 */
export default class EventSourceCustom implements EventSource {
  // SSE managing params -------------------------------------------------------------------------------------------------

  private broadcastChannel: BroadcastChannel;
  private eventSource: EventSource | null = null;
  /** @see reconnectBroadcastTimeout */
  private disconnects = 0;
  /** @see broadcastLeadHeartbeatInterval */
  private readonly LEAD_INTERVAL_SEC = 80;
  /** Если поступает команда найти лидера, это промежуток в рамках которого будет рандомом выбран дилей подключения, у кого меньше тот и станет лидом */
  private readonly INSTANT_CONNECT_DELAY_SEC = 5;
  private subscriptionEntityName: string;

  /** По истечении заданного времени данная вкладка браузера создаст подключение к серверу, если ей никто не пришлёт сообщение, что сделал это первый */
  private createEventSourceLeadTimeout: NodeJS.Timeout | null = null;
  /** С заданным интервалом будет рассылать сообщение всем через BroadcastChannel, что данная вкладка лидер, и она поддерживает соединение с сервером */
  private broadcastLeadHeartbeatInterval: NodeJS.Timeout | null = null;
  /** При дисконнекте SSE (это может быть только со стороны соединения/сервера) мы отключаемся также и от BroadcastChannel (чтобы они по кругу не пытались стать лидами при неработающем соединении/сервере); здесь хранится таймаут восстановления соединения через какое-то время, дальше запускается стандартный цикл установления лида  */
  private reconnectBroadcastTimeout: NodeJS.Timeout | null = null;

  // EventSource params implementation ------------------------------------------------------------------------------------

  public url: string;
  public withCredentials: boolean;
  public connectionType: 'broadcast' | 'sse';
  /** Меняется только на close; можно написать геттер на eventSource.readyState, но пока не требуется */
  public readyState: number;

  // По сути статик данные
  public readonly CLOSED = EventSource.CLOSED;
  public readonly CONNECTING = EventSource.CONNECTING;
  public readonly OPEN = EventSource.OPEN;

  // Constructor ----------------------------------------------------------------------------------------------------------

  constructor(url: string, eventSourceInitDict?: EventSourceInit | undefined) {
    this.url = url;
    this.readyState = EventSource.OPEN;
    this.subscriptionEntityName = this.url.match(/([^/]+)\/subscribe$/)?.[1] || '';
    this.withCredentials = eventSourceInitDict?.withCredentials || false;
    this.connectionType = 'broadcast';
    this.broadcastChannel = this.createBroadcastChannel(this.url);
    this.setCreateEventSourceLeadTimeout(this.INSTANT_CONNECT_DELAY_SEC);
    this.sendBroadcastMessage(IServerEventsService.EVENT_TYPE.searchForLead);
  }

  // SSE managing methods --------------------------------------------------------------------------------------------------

  /** Создаёт подключение к BroadcastChannel */
  private createBroadcastChannel = (url: string): BroadcastChannel => {
    this.closeBroadcastChannel();
    const broadcastChannel = new BroadcastChannel(url);
    broadcastChannel.onmessageerror = () =>
      console.error(`[SSE Manager ${this.subscriptionEntityName} ${new Date().toISOString()}]: Error: message can't be deserialized`);
    broadcastChannel.onmessage = this.broadcastChannelMessageHandler;
    return broadcastChannel;
  };

  private createEventSource = () => {
    console.debug(`[SSE Manager ${this.subscriptionEntityName} ${new Date().toISOString()}]: Current tab is a lead`);
    this.clearAllTimeouts();
    this.closeEventSource();
    this.eventSource = new EventSource(this.url, { withCredentials: this.withCredentials });
    // Уведомляем всех, что мы теперь лид
    this.sendBroadcastMessage(IServerEventsService.EVENT_TYPE.leadHeartbeat);
    this.setBroadcastLeadHeartbeatInterval();
    this.connectionType = 'sse';
    this.readyState = EventSource.OPEN;

    // Передаём данные настоящего eventSource нашем обработчикам
    this.eventSource.onmessage = (event) => {
      this.onmessage(event);
      // Отправляем данные остальным подписчикам
      this.sendBroadcastMessage(IServerEventsService.EVENT_TYPE.message, event.data);
    };
    this.eventSource.onerror = this.onerror;
    this.eventSource.onopen = (event) => {
      this.disconnects = 0;
      this.onopen(event);
    };

    // TODO delete
    this.eventSource.addEventListener(IServerEventsService.EVENT_TYPE.ping, () =>
      console.debug(`[SSE Manager ${this.subscriptionEntityName} ${new Date().toISOString()}]: Ping received`)
    );

    // Закрытие соединения по просьбе сервера
    this.eventSource.addEventListener(IServerEventsService.EVENT_TYPE.close, this.closeEventHandler);

    // Выставляем слушатель на закрытие таба, чтобы уведомить остальных о необходимости переподключения
    window.addEventListener('beforeunload', this.close);
  };

  private closeEventHandler = () => {
    console.debug(`[SSE Manager ${this.subscriptionEntityName} ${new Date().toISOString()}]: Connection closed by server`);
    this.close();
  };

  /** @see createEventSourceLeadTimeout */
  private setCreateEventSourceLeadTimeout = async (exactValueSec?: number) => {
    if (this.createEventSourceLeadTimeout) {
      clearTimeout(this.createEventSourceLeadTimeout);
    }
    // Рандом нужен чтобы обработать ситуацию вызова данного метода на разных вкладках в один момент
    const timeoutTime = exactValueSec || this.LEAD_INTERVAL_SEC + Math.random();
    this.createEventSourceLeadTimeout = setTimeout(this.createEventSource, timeoutTime * 1000);
  };

  /** @see broadcastLeadHeartbeatInterval */
  private setBroadcastLeadHeartbeatInterval = () => {
    if (this.broadcastLeadHeartbeatInterval) {
      clearTimeout(this.broadcastLeadHeartbeatInterval);
    }
    this.broadcastLeadHeartbeatInterval = setInterval(() => {
      this.sendBroadcastMessage(IServerEventsService.EVENT_TYPE.leadHeartbeat);
    }, (this.LEAD_INTERVAL_SEC / 2) * 1000);
  };

  /**
   * Внутреннее название метода, по аналогии с другими -  setReconnectBroadcastTimeout
   * @see reconnectBroadcastTimeout
   */
  public reinitialize = () => {
    // Каждый следующий дисконнект будет увеличивать время на восстановление
    const timeoutTime = Math.pow(2, this.disconnects) * (this.LEAD_INTERVAL_SEC + 1) * 1000; // + 1 - защита от 0
    const approximatelyTime = Math.round(timeoutTime / 60) / 1000;
    console.debug(
      `[SSE Manager ${this.subscriptionEntityName} ${new Date().toISOString()}]: Next reconnect in ${approximatelyTime} minutes`
    );

    if (this.reconnectBroadcastTimeout) {
      clearTimeout(this.reconnectBroadcastTimeout);
    }
    this.reconnectBroadcastTimeout = setTimeout(() => {
      console.debug(`[SSE Manager ${this.subscriptionEntityName} ${new Date().toISOString()}]: Reinitialization started`);
      this.broadcastChannel = this.createBroadcastChannel(this.url);
      this.setCreateEventSourceLeadTimeout(this.INSTANT_CONNECT_DELAY_SEC);
      this.sendBroadcastMessage(IServerEventsService.EVENT_TYPE.searchForLead);
      this.readyState = EventSource.OPEN;
    }, timeoutTime);
  };

  private clearAllTimeouts = () => {
    if (this.createEventSourceLeadTimeout) clearTimeout(this.createEventSourceLeadTimeout);
    if (this.broadcastLeadHeartbeatInterval) clearTimeout(this.broadcastLeadHeartbeatInterval);
    if (this.reconnectBroadcastTimeout) clearTimeout(this.reconnectBroadcastTimeout);
  };

  private closeEventSource = () => {
    if (this.eventSource) {
      console.debug(`[SSE Manager ${this.subscriptionEntityName} ${new Date().toISOString()}]: EventSource disconnected`);
      this.eventSource.close();
      this.eventSource.removeEventListener(IServerEventsService.EVENT_TYPE.close, this.closeEventHandler);
      this.eventSource = null;
      this.disconnects += 1;
      this.connectionType = 'broadcast';
      window.removeEventListener('beforeunload', this.close);
      if (this.broadcastLeadHeartbeatInterval) clearTimeout(this.broadcastLeadHeartbeatInterval);
    }
  };

  // Handle broadcast channel ----------------------------------------------------------------------------------------------

  /**
   * Логику хэндлера проясняет описание IServerEvents.BroadcastMessage
   * @see IServerEvents.BroadcastMessage
   */
  private broadcastChannelMessageHandler = (event: MessageEvent<IServerEventsService.BroadcastMessage>) => {
    const message = event.data;
    switch (message.name) {
      case IServerEventsService.EVENT_TYPE.leadHeartbeat: {
        console.debug(
          `[SSE Manager ${this.subscriptionEntityName} ${new Date().toISOString()}]: BroadcastChannel received a lead heartbeat`
        );
        // Если по какой-то причине нам пришел LEAD_HEARTBEAT от другой вкладки (браузер может заморозить вкладку лида, если мы не на ней, тогда на активной сработает clearLeadTimeouts), при том, что мы lead, то пусть она и остаётся лидом, эту убираем
        this.closeEventSource();
        // Сбрасываем текущие таймауты
        this.clearAllTimeouts();
        // Создаём новые, которые будут сброшены таким же сообщением LEAD_HEARTBEAT, если лид будет жив, если нет, то данная вкладка станет лидом
        this.setCreateEventSourceLeadTimeout();
        break;
      }
      case IServerEventsService.EVENT_TYPE.message: {
        console.debug(`[SSE Manager ${this.subscriptionEntityName} ${new Date().toISOString()}]: BroadcastChannel received a message`);
        // Передаём данные от BroadcastChannel обработчику, как если бы они пришли напрямую от EventSource
        this.onmessage({ data: message.data });
        break;
      }
      case IServerEventsService.EVENT_TYPE.elections: {
        // Пришло сообщение о необходимости перевыборов лида; без него вкладки и сами определят лида, но сделают это с задержкой
        console.debug(
          `[SSE Manager ${this.subscriptionEntityName} ${new Date().toISOString()}]: BroadcastChannel received an elections command`
        );
        // Эти очистки тут по сути не нужны, но теоретически может возникнуть ситуация, когда вкладке лиду придёт сообщение elections
        this.clearAllTimeouts();
        this.closeEventSource();
        this.setCreateEventSourceLeadTimeout(this.INSTANT_CONNECT_DELAY_SEC * Math.random());
        break;
      }
      case IServerEventsService.EVENT_TYPE.searchForLead: {
        // Если получили такое сообщение, значит кто-то ищет, есть ли лид
        console.debug(`[SSE Manager ${this.subscriptionEntityName} ${new Date().toISOString()}]: Somebody is looking for a lead`);
        if (this.connectionType === 'sse') {
          this.sendBroadcastMessage(IServerEventsService.EVENT_TYPE.leadHeartbeat);
        }
        break;
      }
      default: {
        console.error(`[SSE Manager ${this.subscriptionEntityName} ${new Date().toISOString()}]: Unknown broadcast event: ${message}`);
      }
    }
  };

  /** Хоть BroadcastMessage может работать со всеми типами данных, пока передаём сообщение в json строке (для лучшей совместимости с EventSource) */
  private sendBroadcastMessage = (name: IServerEventsService.EVENT_TYPE, data?: string) => {
    const broadcastMessage: IServerEventsService.BroadcastMessage = { name, data };
    if (this.readyState === this.OPEN) {
      this.broadcastChannel.postMessage(broadcastMessage);
    }
  };

  private closeBroadcastChannel = () => {
    // TODO Из-за того, что в процессе закрытия это свойство не зануляется (в отличии от eventSource), это условие сейчас срабатывает всегда
    if (this.broadcastChannel) {
      this.broadcastChannel.close();
      console.debug(`[SSE Manager ${this.subscriptionEntityName} ${new Date().toISOString()}]: BroadcastChannel disconnected`);
    }
  };

  // EventSource methods implementation ------------------------------------------------------------------------------------------------

  public close = () => {
    this.sendBroadcastMessage(IServerEventsService.EVENT_TYPE.elections);
    this.closeBroadcastChannel();
    this.closeEventSource();
    this.readyState = EventSource.CLOSED;
    // Чистим таймауты, т.к. данный метод может вызываться при размонтировании компонента
    this.clearAllTimeouts();
  };

  // Обработчики сообщений и ошибок, которые назначаются из вне (по условиям интерфейса EventSource), здесь просто заглушки
  public onmessage = (_event: IServerEventsService.SSEMessage) => {};
  public onerror: NonNullable<EventSource['onerror']> = () => {};
  public onopen: NonNullable<EventSource['onopen']> = () => {};

  // EventSource deprecated

  /** @deprecated лень имплементировать */
  public addEventListener = () => {};
  /** @deprecated лень имплементировать */
  public dispatchEvent = () => false;
  /** @deprecated лень имплементировать */
  public removeEventListener = () => {};
}

import IHttpService from "services/http/IHttpService";
import IAuthTokensService from "./IAuthTokensService";
import { UpdateTokensResponseDTO } from "typings/dto/auth";
import { PATH_BACKEND } from "configs/routes/pathsBackend";

export default class AuthTokensServiceImpl implements IAuthTokensService {

  private httpService: IHttpService
  public accessToken: string | null;
  private accessTokenExpiresIn: Date | null
  private refreshTokenExpiresIn: Date | null
  private tokenType: string
  /** Подписчики на обновление accessToken */
  private subscribers: ((accessToken: string) => void)[] = [];

  constructor(httpService: IHttpService) {
    this.httpService = httpService
    this.accessToken = localStorage.getItem('accessToken') 
    this.accessTokenExpiresIn = new Date(JSON.parse(localStorage.getItem('accessTokenExpiresIn') ?? '0') ?? 0)
    this.refreshTokenExpiresIn = new Date(JSON.parse(localStorage.getItem('refreshTokenExpiresIn') ?? '0') ?? 0)
    this.tokenType = localStorage.getItem('tokenType') ?? 'Bearer'


    if (!this.accessToken || this.accessTokenExpiresIn.getTime() < Date.now() && this.refreshTokenExpiresIn.getTime() > Date.now()) {
      this.updateAccessToken()
    }
  }

  public setAccessToken(tokensDto: UpdateTokensResponseDTO | null): void {

    if (!tokensDto) {
      this.accessToken = null;
      this.accessTokenExpiresIn = null
      this.refreshTokenExpiresIn = null

      localStorage.removeItem('accessToken')
      localStorage.removeItem('accessTokenExpiresIn')
      localStorage.removeItem('refreshTokenExpiresIn')
      localStorage.removeItem('tokenType')
      return
    }

    const { access_token, expires_in, refresh_expires_in, token_type } = tokensDto
    this.accessToken = tokensDto.access_token;
    this.accessTokenExpiresIn = new Date(Date.now() + expires_in * 1000)
    this.refreshTokenExpiresIn = new Date(Date.now() + refresh_expires_in * 1000)
    this.tokenType = token_type

    localStorage.setItem('accessToken', access_token)
    localStorage.setItem('accessTokenExpiresIn', JSON.stringify(this.accessTokenExpiresIn))
    localStorage.setItem('refreshTokenExpiresIn', JSON.stringify(this.refreshTokenExpiresIn))
    localStorage.setItem('tokenType', token_type)

    // Уведомляем подписчиков, что токен обновился 
    this.subscribers.forEach(subscriber => subscriber(access_token))
  }

  public subscribeOnAccessTokenUpdate = (subscriber: (accessToken: string | null) => void) => {
    this.subscribers.push(subscriber)
  }

  public async getAccessToken(): Promise<string> {
    // Проверка действителен ли токен доступа
    if (!this.accessToken || Number(this.accessTokenExpiresIn?.getTime()) < Date.now()) {
      await this.updateAccessToken()
      return this.accessToken as string
    }
    return this.accessToken;
  }

  public getTokenType = () => this.tokenType 

  public async updateAccessToken() {
    if (Number(this.refreshTokenExpiresIn?.getTime()) < Date.now()) {
      return
    }
    const response = await this.httpService.fetch(PATH_BACKEND.auth.refreshToken, {
      method: 'POST',
      credentials: 'include',
    })
      .then(this.httpService.parseResponseJson)
      .then(res => res.data) as UpdateTokensResponseDTO
    this.setAccessToken(response)
  }
}
import { ENV_VERSION } from 'configs/vars';
import React, { createContext, useState } from 'react';

export const DebugContext = createContext<{ debugMode: boolean }>({ debugMode: false });

/** Должен использоваться исключительно для целей отладки приложения */
export function DebugContextProvider({ children }: { children: React.ReactNode }) {

  const [debugMode] = useState(() => {
    if (localStorage.getItem('debugMode')) {
      return JSON.parse(localStorage.getItem('debugMode') as string)
    }
    const debugMode = ENV_VERSION === 'development'
    localStorage.setItem('debugMode', JSON.stringify(debugMode))
    return debugMode
  })

  return <DebugContext.Provider value={{ debugMode }}>{children}</DebugContext.Provider>;
}
